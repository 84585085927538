// import './reset.css';
import './styles.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { initializeApp } from "firebase/app";

import App from './app';
// import reportWebVitals from './reportWebVitals';

import { FIREBASE_CONFIG_DATA } from './firebase';
import { api_env } from './env';

initializeApp(FIREBASE_CONFIG_DATA);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render( api_env && api_env === 'live' ? <App /> : <React.StrictMode><App /></React.StrictMode>); 

root.render(<App />); 
// root.render(<React.StrictMode><App /></React.StrictMode>); 

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();