import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Group, Text, Button, Space, Avatar } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { useNotifications } from '@mantine/notifications';
import shortid from "shortid";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { ReaderIcon, Cross2Icon } from '@radix-ui/react-icons';

// import { isArrayExists } from '../../helpers/validation';
// import { tiptapWordsCount } from '../../helpers/tiptap';
import { getAPIErrorMessage } from '../../helpers/api';

const AddNewUploadEpub = ({
    formData = {},
    loading = false,
    onUpdate = () => {},
}) => {
    const storage = getStorage();
    const notifications = useNotifications();
    const authData = useSelector(state => state.auth.user);
    const [ uploading, setUploading ] = useState(false);

    const disabled = useMemo(() => {
        return loading ? true : false;
    },[loading]);

    const handleRejected = (files) => {
        notifications.showNotification({
            color: 'red',
            title: 'Please select a valid epub file',
        }) 
    }

    const handleDrop = ( files ) => {
        if ( files && files[0] ) {
            setUploading(true);
            // turn the file name to snake case
            const file_name = files[0].name.toLowerCase().replace(/\s/g, "_");
            // const epub_id = shortid.generate() + '_' + file_name;
            const epub_id = file_name;
            const storageRef = ref(storage, 'users_files/'+authData.uid+'/'+epub_id );

            uploadBytes(storageRef, files[0], {
                contentType: 'application/epub+zip'
            })
            .then((data) => {
                setUploading(false);
                onUpdate({
                    ...formData,
                    epub_id,
                    epub_name: files[0].name || '',
                });
            })
            .catch(error => {
                setUploading(false);
                notifications.showNotification({
                    color: 'red',
                    title: getAPIErrorMessage(error),
                });
            });
        } else {
            notifications.showNotification({
                color: 'red',
                title: 'Invalid file uploaded',
            });
        }
    }

    const dropzoneChildren = (status) => (
        <Group position="center" spacing="xl" style={{ minHeight: 220, pointerEvents: 'none' }}>
          <div>
            <Text size="xl" inline>
              Drag EPUB file here or click to select an EPUB file
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
                Your EPUB file should not exceed 50mb
            </Text>
          </div>
        </Group>
    );

    return formData.epub_id && formData.epub_name ? (
    <Box
        sx={(theme) => ({
            background: theme.colors.gray[1],
            padding: theme.spacing.md,
            border: '2px solid ' + theme.colors.gray[3],
        })}>
        <Group position='right'>
            <Button
                color="red"
                size="sm"
                compact
                leftIcon={<Cross2Icon />}
                onClick={() => {
                    onUpdate({
                        ...formData,
                        epub_id: '',
                        epub_name: '',
                    });
                }}>Remove File</Button>
        </Group>
        <Space h="xl" />
        <Group grow>
            <ReaderIcon width={60} height={60} />
            <Text size="lg" ml={10}>{formData.epub_name || ''}</Text>
        </Group>
    </Box>
    ) : (
    <Box>
        <Dropzone
            loading={uploading}
            onDrop={handleDrop}
            onReject={handleRejected}
            // maxSize={3 * 1024 ** 2}
            accept={'.epub'}
            >
            {(status) => dropzoneChildren(status)}
            </Dropzone>
    </Box>
    )
}

export default AddNewUploadEpub;