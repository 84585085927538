
import { 
    RESET_INSIGHTS
} from './types';

export const resetInsights = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch({
                type: RESET_INSIGHTS,
            });
        },150);
    }
}