import { generateJSON, generateHTML } from '@tiptap/html';
import StarterKit from '@tiptap/starter-kit';
import Highlight from '@tiptap/extension-highlight';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';

import { isArrayExists } from './validation';
import { hexToRgb } from './data';

export const getCustomExtensionsFromDoc = ( doc = {} ) => {
    let extensions = [];
    if ( doc && doc.content_allow_image && doc.content_allow_image === 'yes' ) {
        extensions.push( 'image' );
    }
    if ( doc && doc.content_allow_links && doc.content_allow_links === 'yes' ) {
        extensions.push( 'link' );
    }
    return extensions
}

export const tiptapEditorExtensions = ( custom_extensions = [] ) => {
    let extensions = [
        StarterKit,
        Highlight.configure({ 
            multicolor: true,
            HTMLAttributes: {
                class: 'highlighted',
            }
        })
    ];

    // add custom_extensions
    if ( isArrayExists( custom_extensions ) ) {
        custom_extensions.forEach(item => {
            switch( item ) {
                case 'image':
                    extensions.push( 
                        Image.configure({
                            allowBase64: false,
                        })
                    );
                    break;
                case 'link':
                    extensions.push(
                        Link.configure({
                            linkOnPaste: false,
                            openOnClick: false,
                            validate: href => /^https?:\/\//.test(href)
                        })
                    );
                    break;
            }
        });
    }

    return extensions;
}

// convert tiptap json content to html content
export const tiptapJSONToHtml = ( json = {}, custom_extensions = [] ) => {
    return generateHTML( json, tiptapEditorExtensions( custom_extensions ) );
};

// convert tiptap json content to html content
export const tiptapHtmlToJSON = ( html = '', custom_extensions = [] ) => {
    return generateJSON( html, tiptapEditorExtensions( custom_extensions ) );
};

// filter html content
export const tiptapFilterContent = ( html = '', custom_extensions = [] ) => {
    const jsonData = tiptapHtmlToJSON( html, custom_extensions );
    return tiptapJSONToHtml( jsonData, custom_extensions );
}

// convert tiptap json content to items (in array) (for display)
export const tiptapHTMLToItems = ( html = '', custom_extensions = [] ) => {
    const jsonData = ( html && html !== '' ? tiptapHtmlToJSON( html, custom_extensions ) : null );
    let items = [],
        count = 1;
    if ( jsonData && jsonData.content && isArrayExists( jsonData.content ) ) {
        jsonData.content.forEach( item => {
            items.push({ ...item, id: count.toString() });
            count++;
        });
    }
    return items;
};

export const tiptapBlockContent = ({ id, type = '', content },removeSpace = true) => {
    let text = '';
    if ( content ) {
        switch( type ) {
            case 'paragraph':
                if ( isArrayExists( content ) ) {
                    content.forEach(item => {
                        text += ( item.marks && isArrayExists( item.marks ) && !removeSpace ? ` ${( item.text || '' )} ` : ( item.text || '' ) );
                    });
                }
                break;
            case 'blockquote':
                if ( content[0] && content[0].content && isArrayExists( content[0].content ) ) {
                    content[0].content.forEach(item => {
                        text += ( item.marks && isArrayExists( item.marks ) && !removeSpace ? ` ${( item.text || '' )} ` : ( item.text || '' ) );
                    });
                }
                if ( content[1] && content[1].content && content[1].content[0] && content[1].content[0].text ) {
                    text = `${text} ~ ` + content[1].content[0].text;
                } else {
                    text = `"${text}"`;
                }
                break;
            case 'bulletList':
            case 'orderedList':
                let orderedCount = 1;
                if ( content && isArrayExists( content ) ) {
                    content.forEach((listitem,index) => {
                        if ( listitem.content && isArrayExists( listitem.content ) ) {
                            let sublist_item_text = '';
                            listitem.content.forEach((sublistitem,index) => {
                                if ( sublistitem.content && isArrayExists( sublistitem.content ) ) {
                                    sublistitem.content.forEach((subsublistitem,index) => {
                                        sublist_item_text += ( subsublistitem.marks && isArrayExists( subsublistitem.marks ) ? ` ${( subsublistitem.text || '' )} ` : ( subsublistitem.text || '' ) );   
                                    });
                                }
                            });
                            text += `${( text && text !== '' ? '\n' : '' )}${( type === 'orderedList' ? orderedCount+'.' : '*' )} ${sublist_item_text}`;
                            orderedCount++;
                        }
                    });
                }
                break;
        }
    } // end - content
    return { id, text };
}

export const tiptapHeadingText = ({ content }) => {
    let text = '';
    if ( isArrayExists( content ) ) {
        content.forEach(item => {
            text += ( item.text || '' );
        });
    }
    return text;
}

export const tiptapApplyMarks = ( text = '', item = {} ) => {
    let replace = '';
    if ( item.marks && isArrayExists( item.marks ) ) {
        item.marks.forEach(mark => {
            switch( mark.type ) {
                case 'italic':
                    replace = '<em>'+item.text.trim()+'</em>';
                    break;
                case 'bold':
                    replace = '<strong>'+item.text.trim()+'</strong>';
                    break;
                case 'highlight':
                    let color = ( mark.attrs && mark.attrs.color ? mark.attrs.color : '#ff0000' );
                    replace = `<mark class="highlighted" data-color="${color}" style="background-color: ${hexToRgb(color)};">${item.text.trim()}</mark>`;
                    break;
            }
        });
    }
    if ( replace !== '' ) {
        text = text.replace( item.text, replace );
    }
    return text;
}

export const tiptapReaddMarks = (text = '',{ id, type = '', content }) => {
    if ( content ) {
        switch( type ) {
            case 'paragraph':
                if ( isArrayExists( content ) ) {
                    content.forEach(item => {
                        text = tiptapApplyMarks( text, item );
                    });
                }
                break;
            case 'blockquote':
                if ( content[0] && content[0].content && isArrayExists( content[0].content ) ) {
                    content[0].content.forEach(item => {
                        text = tiptapApplyMarks( text, item );
                    });
                }
                break;
            case 'bulletList':
            case 'orderedList':
                let orderedCount = 1;
                if ( content && isArrayExists( content ) ) {
                    content.forEach((listitem,index) => {
                        if ( listitem.content && isArrayExists( listitem.content ) ) {
                            let sublist_item_text = '';
                            listitem.content.forEach((sublistitem,index) => {
                                if ( sublistitem.content && isArrayExists( sublistitem.content ) ) {
                                    sublistitem.content.forEach((subsublistitem,index) => {
                                        text = tiptapApplyMarks( text, subsublistitem ); 
                                    });
                                }
                            });
                        }
                    });
                }
                break;
        }
    } // end - content
    return text;
}

// convert tiptap html content to Text
export const tiptapHtmlToText = ( html = '', custom_extensions = [] ) => {
    const jsonData = tiptapHtmlToJSON( html, custom_extensions );
    let text = '';
    if ( jsonData && jsonData.content && isArrayExists( jsonData.content ) ) {
        jsonData.content.forEach(item => {
            const block = tiptapBlockContent( item, true );
            text += ( block.text && block.text !== '' ? ( text && text !== '' ? '\n\n' : '' ) + block.text : '' );
        });
    }
    return text;
};

// get words count from tiptap html content
export const tiptapWordsCount = (html,custom_extensions = []) => {
    return tiptapHtmlToText(html,custom_extensions).split(" ").length;
}

// get chars count from tiptap html content
export const tiptapCharsCount = (html,custom_extensions = []) => {
    return tiptapHtmlToText(html,custom_extensions).trim().length;
}