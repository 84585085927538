/* eslint-disable */
import { api_env, USE_EMULATOR } from './env';
import { 
    FIREBASE_ACCOUNT_ID, 
    FIREBASE_REGION,
	FIREBASE_CONFIG_DATA,
    EMULATOR_PORT_CLOUD_FUNCTIONS,
    // EMULATOR_PORT_FIRESTORE,
    EMULATOR_PORT_DATABASE 
} from './firebase';


export const DEV_MODE = ( api_env && api_env === 'live' ? false : true );

// platform related
export const PLATFORM_NAME = 'Sagely AI';
export const PLATFORM_NAME_LONG = 'Sagely AI';
export const PLATFORM_ORG_NAME = 'Smashing Advantage';
export const PLATFORM_COMPANY_NAME = 'Smashing Advantage Enterprise';
export const PLATFORM_ORG_LOGO = '/logo192.png';
export const PLATFORM_URL = DEV_MODE ? 'http://localhost:3000/' : 'https://app.sagelyai.com/';
export const PLATFORM_WEBSITE_URL = 'https://www.sagelyai.com/';
export const PLATFORM_ID = 'sagelyai';
export const PLATFORM_EMAIL = '';
export const PLATFORM_PHONE = '';

export const DEV_USERS = [
	'SC2iiabNbHXgIyjbEuNlXkRWSri2',
	'MJai7uzQgbNLzUfivdnjsZcq6Ch1',
	'uFhOusBaeaYIFCj22bDHjTSkzTv1'
];

// firebase related
// database url
export const FIREBASE_DB_API = ( DEV_MODE ? ( USE_EMULATOR ? 'http://localhost:' + EMULATOR_PORT_DATABASE : FIREBASE_CONFIG_DATA.databaseURL ) : FIREBASE_CONFIG_DATA.databaseURL );
// function url
export const FIREBASE_CLOUD_API = ( DEV_MODE ? 'http://localhost:'+( USE_EMULATOR ? EMULATOR_PORT_CLOUD_FUNCTIONS : '5000' )+'/'+FIREBASE_ACCOUNT_ID+'/'+FIREBASE_REGION+'/' : 'https://'+FIREBASE_REGION+'-'+FIREBASE_ACCOUNT_ID+'.cloudfunctions.net/' );
// firestore url
export const FIREBASE_FIRESTORE_API = '';
// storage url
export const FIREBASE_STORAGE_API = '';
// firestore per page
export const FIRESTORE_DEFAULT_PERPAGE = ( api_env && api_env === 'dev' ? 4 : 20 );

// options
export const ENTRIES_OPTIONS = [
	{ value: 5, label: '5' },
	{ value: 20, label: '20' },
	{ value: 40, label: '40' },	
	{ value: 60, label: '60' },
	{ value: 80, label: '80' },
	{ value: 100, label: '100' },
	{ value: 200, label: '200' },
	{ value: 500, label: '500' }
];