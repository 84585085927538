
import { isArrayExists } from "../../helpers/validation";
import { groupSequentialNumbers } from '../../helpers/number';
import { tiptapBlockContent } from "../../helpers/tiptap";

// get Insight keypoints
export const getInsightKeypoints = (insights,contentItems = []) => {
    let list = [],
        count = 0;
    if ( insights && isArrayExists( insights ) ) {
        insights.forEach( insight => {
            if ( insight.keypoints && isArrayExists( insight.keypoints ) ) {
                ( insight.keypoints.sort((a,b) => parseInt( a.id ) - parseInt( b.id ) ) ).forEach( item => {
                    count++;
                    const prompt = item.prompt && isArrayExists( item.prompt ) ? item.prompt : [];
                    const title = findTitleBeforeParagraph(contentItems, ( prompt[0] || '' ) );
                    list.push({
                        id: count.toString(),
                        prompt,
                        title: title.text,
                        title_level: title.level,
                        text: ( item.unsafe_level && item.unsafe_level === '2' ? "The content provided or insights generated by AI may be toxic or harmful to you. You can either try to regenerate the insights or mark the insights as bad. We'll investigate the issue internally." : ( item.text || '' ).trim() ),
                        log_id: ( item.log_id || false ),
                        rate: ( item.rate && ( item.rate === 'good' || item.rate === 'bad' ) ? item.rate : '' ),
                        unsafe_level: ( item.unsafe_level && ( item.unsafe_level === '1' || item.unsafe_level === '2' ) ? item.unsafe_level : '0' ),
                    });
                });
            }
        }); 
    } // end - insights
    return list;
}

// find title before the paragraph provided
export const findTitleBeforeParagraph = (items,paragraph = '') => {
    let text = '',
        level = 0;
    if ( items && isArrayExists( items ) && paragraph && paragraph !== '' ) {
        const index = items.findIndex( item => {
            let valid = false;
            if ( item && item.type && item.type === 'paragraph' && item.content && item.content[0] && item.content[0].text && item.content[0].text.trim() === paragraph.trim() ) {
                valid = true;
            }
            return valid;
        });
        if ( index > 0 ) {
            const item = items[index-1];
            if ( item && item.type && item.type === 'heading' && item.attrs && item.attrs.level ) {
                text = item.content[0].text;
                level = item.attrs.level;
            }
        }
    }
    return {
        text,
        level,
    };
}

// get selected keypoint
export const getInsightKeypoint = (insights,id) => {
    const keypoints = getInsightKeypoints(insights);
    return keypoints.find( item => item.id === id.toString() );
}

// get selected content blocks by insight
export const getInsightContentBlocks = (items,insight) => {
    let blocks = [];
    if ( items && isArrayExists( items ) ) {
        items.forEach( item => {
            // only selcted type
            if ( item.type ) {
                const selected = tiptapBlockContent(item);
                if ( item.type === 'image' ) {
                    // only add it if it's between content
                    if ( isArrayExists( blocks ) ) {
                        blocks.push(selected.id);
                    }
                } else if ( 
                    item.type === 'paragraph' || 
                    item.type === 'blockquote' || 
                    item.type === 'bulletList' || 
                    item.type === 'orderedList'
                ) {
                    if ( selected && selected.id && selected.text && insight && insight.prompt && isArrayExists( insight.prompt ) && insight.prompt.includes( selected.text ) ) {
                        blocks.push(selected.id);
                    }
                }
            } // end - item.type
        });

        // remove content wasn't in prompt blocks
        if ( blocks && isArrayExists( blocks ) ) {
            const groupedBlocks = groupSequentialNumbers(blocks);
            if ( groupedBlocks && isArrayExists( groupedBlocks ) ) {
                groupedBlocks.forEach( groupedBlock => {
                    if ( groupedBlock.length > 1 ) {
                        blocks = [ ...groupedBlock ];
                    }
                });
            }
        } // end - blocks
        
    }
    return blocks;
}