
import { 
    USER_ONBOARDING_GET,
} from './types';

const initialState = {
    onboarding: null,
    loaded: null
};

export const onboarding = (state = initialState, action) => {
    const { type, payload = null } = action;
    switch (type) {
        case USER_ONBOARDING_GET:
            return {
                ...state,
                onboarding: payload.onboarding || null,
                loaded: true
            };
        default:
            return state;
    }
}