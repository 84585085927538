import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Box, InputWrapper, Group, Text, ScrollArea, Grid, Switch, Space, Input } from '@mantine/core';
import { useEditor, EditorContent, BubbleMenu } from '@tiptap/react'
// import StarterKit from '@tiptap/starter-kit';
// import { useFocusTrap, useMergedRef } from '@mantine/hooks';
import { FontBoldIcon, FontItalicIcon, StrikethroughIcon, Link2Icon, LinkBreak2Icon, CheckIcon, Cross1Icon } from '@radix-ui/react-icons';

// import { isArrayExists } from '../../helpers/validation';
import { isDev } from '../../helpers/auth';
import { tiptapWordsCount, tiptapEditorExtensions, getCustomExtensionsFromDoc, tiptapHTMLToItems, tiptapBlockContent } from '../../helpers/tiptap';
import { isObjectExists } from '../../helpers/validation';

const AddNewEditor = ({
    editorRef = null,
    opened = false,
    predefinedFormData = false,
    formData = {},
    loading = false,
    onUpdate = () => {}
}) => {
    const authData = useSelector(state => state.auth.user);
    const [ openLink, setOpenLink ] = useState(false);
    const [ url, setURL ] = useState('');
    // const focusTrapRef = useFocusTrap(false);
    // const bubbleMenuRef = useMergedRef( focusTrapRef );

    const editor = useEditor({
        content: formData.content || '',
        extensions: tiptapEditorExtensions( getCustomExtensionsFromDoc( formData ) )
    },[ formData.content_allow_links, formData.content_allow_image ]);

    useEffect(() => {
        if ( opened ) {
            if ( editor ) {
                if ( predefinedFormData && isObjectExists( predefinedFormData ) ) {
                    editor.chain().clearContent().setContent( ( predefinedFormData.content || '' ) ).run();
                }
            }
        } else {
            if ( editor ) {
                editor.commands.clearContent();
            }
        }
    },[opened, editor]);

    useEffect(() => {
        if ( openLink ) {
            setURL('');
        }
    },[ openLink ]);

    const disabled = useMemo(() => {
        return loading ? true : false;
    },[loading]);

    const totalWords = useMemo(() => {
        return tiptapWordsCount( formData.content );
    },[ formData ]);

    useEffect(() => {
        if ( editor ) {
            editor.off("update");
            editor.on("update", ({ editor: updatedEditor }) => onUpdate({
                ...formData,
                content: updatedEditor.getHTML()
            }));
        }
    }, [ editor, formData ]);

    if ( editor )
        editorRef.current = editor;

    const handleSetURL = ( link = false ) => () => {
        if ( link ) {
            if ( link === '' ) {
                editor.chain().focus().unsetLink().run();
            } else {
                editor.chain().focus().setLink(link).run();
            }
        } else {
            setOpenLink(false);
        }
    }

    const MenuOptions = () => {
        return openLink ? (
        <Group 
            position='apart'>
            <Input
                placeholder='Ex: https://www.wikipedia.org/'
                value={url}
                onChange={(event) => setURL(event.target.value)}
                onFocus={(e) => {
                    e.stopPropagation();
                }}
                sx={(theme) => ({
                    width: '200px'
                })} />
            <Group
                spacing={"2px"}
                sx={(theme) => ({
                    '& .mantine-Button-root': {
                        padding: '0 6px'
                    }
                })}>
                <Button size="xs" color={"dark"} onClick={handleSetURL(false)}><Cross1Icon /></Button>
                <Button size="xs" color={"indigo"} onClick={handleSetURL(url)}><CheckIcon /></Button>
            </Group>
        </Group>
        ) : (
        <Group
            spacing={"2px"}
            sx={(theme) => ({
                '& .mantine-Button-root': {
                    padding: '0 6px'
                }
            })}>
            <Button variant='filled' color={( editor.isActive("bold") ? "indigo" : 'dark' )} size="xs" onClick={() => editor.chain().focus().toggleBold().run()}><FontBoldIcon /></Button>
            <Button variant='filled' color={( editor.isActive("italic") ? "indigo" : 'dark' )} size="xs" onClick={() => editor.chain().focus().toggleItalic().run()}><FontItalicIcon /></Button>
            <Button variant='filled' color={( editor.isActive("strike") ? "indigo" : 'dark' )} size="xs" onClick={() => editor.chain().focus().toggleStrike().run()}><StrikethroughIcon /></Button>
            {/* <Button variant='filled' color={( editor.isActive("underline") ? "indigo" : 'dark' )} size="xs" onClick={() => editor.chain().focus().run()}><UnderlineIcon /></Button> */}
            <Button variant='filled' color={( editor.isActive('heading', { level: 1 }) ? "indigo" : 'dark' )} size="xs" onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}><span>H1</span></Button>
            <Button variant='filled' color={( editor.isActive('heading', { level: 2 }) ? "indigo" : 'dark' )} size="xs" onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}><span>H2</span></Button>
            <Button variant='filled' color={( editor.isActive('heading', { level: 3 }) ? "indigo" : 'dark' )} size="xs" onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}><span>H3</span></Button>
            <Button variant='filled' color={( editor.isActive('heading', { level: 4 }) ? "indigo" : 'dark' )} size="xs" onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}><span>H4</span></Button>
            <Button variant='filled' color={( editor.isActive("bulletList") ? "indigo" : 'dark' )} size="xs" onClick={() => editor.chain().focus().toggleBulletList().run()}><i className="ri-list-unordered"></i></Button>
            <Button variant='filled' color={( editor.isActive("orderedList") ? "indigo" : 'dark' )} size="xs" onClick={() => editor.chain().focus().toggleOrderedList().run()}><i className="ri-list-ordered"></i></Button>
            {/* { formData && formData.content_allow_links && formData.content_allow_links === 'yes' && (
            <Button title={ editor.isActive('link') ? "Remove Link" : "Set Link" } variant='filled' color={( editor.isActive("link") ? "indigo" : 'dark' )} size="xs" onClick={() => {
                if ( editor.isActive('link') ) {
                    editor.chain().focus().unsetLink().run();
                } else {
                    // setOpenLink(true);
                }
            }}>{( editor.isActive("link") ? <LinkBreak2Icon /> : <Link2Icon /> )}</Button>
            )} */}
        </Group>
        )
    }

    const handleConvertToTextBlocks = () => {
        let blocks = [];
        const contentItems = tiptapHTMLToItems( formData.content || '', getCustomExtensionsFromDoc( formData ) );
        // combine multiple paragraphs into one block that doesn't exceed 1500 characters
        contentItems.forEach( item => {
            if ( item.type === 'paragraph' ) {
                const lastBlock = blocks[blocks.length-1];
                if ( lastBlock && lastBlock.type === 'paragraph' ) {
                    if ( lastBlock.content && lastBlock.content[0] && lastBlock.content[0].text ) {
                        const text = lastBlock.content[0].text + ' ' + item.content[0].text;
                        if ( text.length <= 1500 ) {
                            lastBlock.content[0].text = text;
                        } else {
                            blocks.push(item);
                        }
                    }
                } else {
                    blocks.push(item);
                }
            } else {
                blocks.push(item);
            }
        });
        // update content
        let html = '';
        blocks.forEach( item => {
            // wrap each block with paragraph
            if ( item.type === 'paragraph' ) {
                html += '<p>';
            }
            // add content
            html += tiptapBlockContent(item).text;
            // wrap each block with paragraph
            if ( item.type === 'paragraph' ) {
                html += '</p>';
            }
        });
        editor.chain().clearContent().setContent( html ).run();
        onUpdate({
            ...formData,
            content: html
        });
    }

    return editor && (
    <InputWrapper
        required
        label="Content"
        >
        <Box
            sx={(theme) => ({
                padding: theme.spacing.sm,
                background: theme.colors.gray[1],
                borderTop: '1px solid ' + theme.colors.gray[4],
                borderRight: '1px solid ' + theme.colors.gray[4],
                borderLeft: '1px solid ' + theme.colors.gray[4],
                borderTopLeftRadius: theme.radius.sm,
                borderTopRightRadius: theme.radius.sm,
            })}>
            {MenuOptions()}
        </Box>
        <ScrollArea
            sx={(theme) => ({ 
                padding: theme.spacing.md,
                height: '50vh',
                border: `1px solid ${theme.colors.gray[4]}`,
                borderEndEndRadius: theme.radius.sm,
                borderEndStartRadius: theme.radius.sm,
                cursor: 'text',
                '& .mantine-ScrollArea-viewport > div': {
                    height: '100%'   
                }
            })}>
            <Box
                onClick={() => {
                    editor.commands.focus();
                }}
                sx={(theme) => ({
                    height: '100%',
                    '& div.ProseMirror': {
                        height: '100%',
                        '& h1': { fontSize: '32px' },
                        '& h2': { fontSize: '24px' },
                        '& h3': { fontSize: '18px' },
                        '& h4': { fontSize: '16px' },
                        '& h5': { fontSize: '14px' },
                        '& h6': { fontSize: '12px' },
                        '& h1, & h2, & h3, & h4, & h5, & h6': {
                            margin: "30px 0 15px 0",
                            fontWeight: "bold"
                        },
                        '& p': {
                            marginTop: 0
                        },
                        'p, ul, ol, blockquote': {
                            marginBottom: '15px'
                        },
                        '&.ProseMirror-focused': {
                            outline: 'none',
                            boxShadow: 'none'
                        }
                    }
                })}>
                {/* <BubbleMenu 
                    editor={editor} 
                    tippyOptions={{
                        duration: 100, 
                        // onClickOutside: () => {
                        //     setOpenLink(false);
                        // }
                    }}>
                    <Box
                        sx={(theme) => ({
                            padding: theme.spacing.xs,
                            background: theme.colors.gray[1],
                            boxShadow: '0 0 0 1px rgba(0,0,0,0.1)',
                        })}>
                        {MenuOptions()}
                    </Box>
                </BubbleMenu> */}
                <EditorContent 
                    editor={editor} />
            </Box>
        </ScrollArea>
        <Space h="sm" />
        <Grid gutter={"sm"} align="center">
            { 
            // isDev( authData ) && 
            (
            <Grid.Col sm={12} md={6}>
                <Group 
                    id="addnewdoc-settings"
                    spacing="sm" 
                    position='left'>
                    <Switch
                        // label='Allow Links'
                        size='lg'
                        color={"indigo"}
                        onLabel='Allow Links'
                        offLabel='Disallow Links'
                        checked={formData && formData.content_allow_links && formData.content_allow_links === 'yes' ? true : false }
                        onChange={(event) => {
                            onUpdate({ 
                                ...formData, 
                                content_allow_links: event.currentTarget.checked ? 'yes' : 'no' });
                        }}
                        disabled={disabled}
                        sx={(theme) => ({
                            '& .mantine-Switch-input': {
                                width: '110px',
                                minWidth: '110px',
                                '&:checked:before': {
                                    transform: 'translateX(80px)'
                                },
                                '&:after': {
                                    fontSize: '10px',
                                }
                            }
                        })}
                        />
                    <Switch
                        size='lg'
                        color={"indigo"}
                        onLabel='Allow Images'
                        offLabel='Disallow Images'
                        checked={formData && formData.content_allow_image && formData.content_allow_image === 'yes' ? true : false }
                        onChange={(event) => {
                            onUpdate({ 
                                ...formData, 
                                content_allow_image: event.currentTarget.checked ? 'yes' : 'no' });
                        }}
                        disabled={disabled}
                        sx={(theme) => ({
                            '& .mantine-Switch-input': {
                                width: '120px',
                                minWidth: '120px',
                                '&:checked:before': {
                                    transform: 'translateX(90px)'
                                },
                                '&:after': {
                                    fontSize: '10px',
                                }
                            }
                        })}
                        />
                    <Button color="dark" size="xs" disabled={disabled} onClick={handleConvertToTextBlocks}>Format in Text Blocks</Button>
                </Group>
            </Grid.Col>
            ) }
            <Grid.Col sm={12} md={6}>
                <Group
                    position='right'>
                    <Text id="addnewdoc-wordcount" size="sm" align='left' color={( totalWords > 60000 ? 'red' : 'dimmed' )}>{ formData && formData.content && formData.content !== '' ? totalWords : 0 } / 60000 words</Text>
                </Group>
            </Grid.Col>
        </Grid>
    </InputWrapper> 
    )
}

export default AddNewEditor;