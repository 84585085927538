

import { 
    GET_INSIGHTS,
    RESET_INSIGHTS,
    SELECT_INSIGHT,
    RESET_SELECT_INSIGHT,
    INSIGHT_SELECTED,
    RESET_INSIGHT_SELECTED,
    INSIGHT_CLICKED,
    RESET_INSIGHT_CLICKED
} from './types';

const initialState = {
    insights: null,
    select_insight: false,
    insight_selected: false,
    updated: false,
    rand: false
};

export const insights = (state = initialState, action) => {
    const { type, payload = null } = action;
    switch (type) {
        case GET_INSIGHTS:
            return {
                ...state,
                insights: payload.list || [],
                rand: Math.floor(Math.random() * 1000000)
            };
        case RESET_INSIGHTS:
            return {
                ...state,
                insights: null,
                rand: false
            };
        default:
            return state;
    }
}

export const select_insight = (state = {
    select_insight: null
}, action) => {
    const { type, payload = null } = action;
    switch (type) {
        case SELECT_INSIGHT:
            return {
                ...state,
                select_insight: payload,
            };
        case RESET_SELECT_INSIGHT:
            return {
                ...state,
                select_insight: false
            };
        default:
            return state;
    }
}

export const insight_selected = (state = {
    blocks_selected: null
}, action) => {
    const { type, payload = null } = action;
    switch (type) {
        case INSIGHT_SELECTED:
            return {
                ...state,
                blocks_selected: payload,
            };
        case RESET_INSIGHT_SELECTED:
            return {
                ...state,
                blocks_selected: false
            };
        default:
            return state;
    }
}

export const insight_clicked = (state = {
    insight_clicked: null
}, action) => {
    const { type, payload = null } = action;
    switch (type) {
        case INSIGHT_CLICKED:
            return {
                ...state,
                insight_clicked: payload
            };
        case RESET_INSIGHT_CLICKED:
            return {
                ...state,
                insight_clicked: false
            };
        default:
            return state;
    }
}