import React, { useState, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { Title, Grid, Space, Container, Divider, Alert, Text, Button  } from '@mantine/core';
import { InfoCircledIcon } from '@radix-ui/react-icons';

import WidgetRecentDocs from '../../modules/WidgetRecentDocs';
import WidgetCreditsStats from '../../modules/WidgetCreditsStats';

import { useScreenSize } from '../../redux/global/hooks';

const Dashboard = ({
    authData
}) => {
    const [ screenSize ] = useScreenSize();
    return (
    <Container size={"lg"}>
        <Title order={3}>Welcome back, <em style={{ fontWeight: '300' }}>{authData.name}</em></Title>
        <Space h="md" />
        <Divider />
        <Space h="xl" />
        <Grid>
            <Grid.Col sm={12} md={6}>
                <WidgetRecentDocs />
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
                <WidgetCreditsStats />
                <Space h="lg" />
                <Alert 
                    icon={<InfoCircledIcon />}
                    color="blue"
                    title="Bug Tracking & Improvements">
                    <Text size="sm">If you found any bugs, or if you have any suggestions for the app, please post them on the dedicated <a href="https://trello.com/b/MOR1S8lM/private-beta-testers-bug-tracking-improvements" target="_blank">Trello Board</a>.</Text>
                    <Space h="sm" />
                    <Text size="sm">If you haven't yet accessed the board, please click the button below to join the board.</Text>
                    <Space h="sm" />
                    <Button
                        component='a'
                        href="https://trello.com/invite/b/MOR1S8lM/a6a21d66ffa74641ffb8c046b1f67551/private-beta-testers-bug-tracking-improvements"
                        target={"_blank"}
                        compact>
                        Join Trello Board
                    </Button>
                </Alert>
            </Grid.Col>
        </Grid>
        <Space h="xl" />
        <Space h="xl" />
    </Container>
    )
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
    };
};

export default compose(
    connect(mapStateToProps)
)(Dashboard);