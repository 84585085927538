
import { 
    CREDITS_GET,
} from './types';

const initialState = {
    credits: 0,
    loaded: null
};

export const credits = (state = initialState, action) => {
    const { type, payload = null } = action;
    switch (type) {
        case CREDITS_GET:
            return {
                ...state,
                credits: payload.credits || 0,
                loaded: true
            };
        default:
            return state;
    }
}