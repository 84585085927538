import React, { useMemo, useState } from 'react';
import { compose } from "recompose";
import { connect, useSelector, useDispatch } from "react-redux";
import { Navbar, ScrollArea, UnstyledButton, Group, Avatar, Text, Button, Box, useMantineTheme, Tooltip, Menu, Center, Space, Loader } from '@mantine/core';
import { DashboardIcon, ChevronLeftIcon, ChevronRightIcon, ExitIcon, InfoCircledIcon, BoxModelIcon, UploadIcon } from '@radix-ui/react-icons';
import { useNotifications } from '@mantine/notifications';

import FoldersList from '../FoldersList';
import DocumentAddNew from '../../pages/Documents/addnew';

import ButtonIconCircle from '../../components/ButtonIconCircle';

import { getAPIErrorMessage } from '../../helpers/api';

import { AUTH_LOGOUT } from '../../redux/auth/types';
import { logoutUser } from '../../redux/auth/api';
import { toggleNavbar } from '../../redux/global/actions';
import { useNavigation } from '../../redux/global/hooks';

import { PLATFORM_NAME_LONG, PLATFORM_ORG_LOGO, DEV_MODE } from '../../constants';

export const Brand = ({
    dispatch,
    navbarStatus,
    drawerMode = false,
    onDrawerToggle = () => {}
}) => {
    const theme = useMantineTheme();
    const navigate = useNavigation();

    const handleToggleNavbar = () => {
        if ( drawerMode ) {
            onDrawerToggle();
        } else {
            dispatch(toggleNavbar());
        }
    }

    return (
    <Box
        sx={(theme) => ({
            paddingTop: theme.spacing.sm,
            paddingBottom: theme.spacing.sm,
            paddingLeft: ( navbarStatus || drawerMode ? '15px' : null ),
            marginBottom: theme.spacing.sm,
            borderBottom: `2px solid ${theme.colors.brand[1]}`
        })}>
        <Group position='apart' direction={ navbarStatus || drawerMode ? 'row' : 'column-reverse' }>
            <Group>
                <Avatar
                    component="a"
                    href="/" 
                    src={PLATFORM_ORG_LOGO}
                    color="dark" 
                    radius="sm"
                    sx={() => ({ border: `2px solid ${theme.colors.brand[1]}` })}
                    onClick={(event) => {
                        event.preventDefault();
                        navigate('/');
                        if ( drawerMode )
                            onDrawerToggle();
                    }}>
                    {/* <LightningBoltIcon /> */}
                </Avatar>
                { navbarStatus || drawerMode ? <Text>{PLATFORM_NAME_LONG}</Text> : null }
            </Group>
            <div style={{ textAlign: navbarStatus || drawerMode ? 'right' : 'center' }}>
                <ButtonIconCircle
                    label={ ( navbarStatus || drawerMode ? "Shrink" : "Expand" ) + " Navbar" }
                    icon={ navbarStatus || drawerMode ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    position="right"
                    btnProps={{
                        size: 'xs',
                        variant: "subtle",
                        color: 'gray',
                        padding: 'xs',
                        radius: 'md'
                    }}
                    color={theme.colors.indigo[8]}
                    onClick={handleToggleNavbar} />
            </div>
        </Group>
    </Box>
    )
}

export const User = ({
    authData,
    navbarStatus,
    drawerMode = false,
    onDrawerToggle = () => {}
}) => {
    const { profile_image = null } = useSelector((state) => state.auth);
    const credits = useSelector((state) => state.credits.credits);
    const creditsLoaded = useSelector((state) => state.credits.loaded);
    const navigate = useNavigation();
    const dispatch = useDispatch();
    const notifications = useNotifications();

    const handleLinkClick = (link) => () => {
        navigate(link);
        if ( drawerMode )
            onDrawerToggle();
    }

    const handleLogout = () => {
        logoutUser()
        .then(() => {
            dispatch({ type: AUTH_LOGOUT });
        })
        .catch(error => {
            notifications.showNotification({
                color: 'red',
                title: getAPIErrorMessage(error),
            })
        });
    }

    return (
    <Box
        sx={(theme) => ({
            paddingTop: theme.spacing.xs,
            paddingBottom: theme.spacing.xs,
            borderTop: `2px solid ${theme.colors.brand[1]}`
        })}>
        <Menu
            position={ drawerMode ? "top" : "right" } 
            gutter={20}
            size={250}
            withArrow
            control={(
            <UnstyledButton>
                <Group>
                    <Avatar src={( profile_image && profile_image.length > 0 ? profile_image : null )} size={40} />
                    { navbarStatus || drawerMode ? <div>
                        <Text sx={(theme) => ({
                            color: theme.colors.gray[4],
                        })}>{ authData.name || '' }</Text>
                        <Text size="xs" sx={(theme) => ({
                            color: theme.colors.gray[4],
                        })}>{ authData.email || '' }</Text>
                    </div> : null }
                </Group>
            </UnstyledButton>
            )}>
            <Menu.Label>Quick Links</Menu.Label>
            <Menu.Item
                component='a'
                href='https://trello.com/invite/b/MOR1S8lM/a6a21d66ffa74641ffb8c046b1f67551/private-beta-testers-bug-tracking-improvements'
                target={'_blank'}
                icon={<BoxModelIcon />}
                >Trello Board Invite Link</Menu.Item>
            <Menu.Item
                component='a'
                href='https://www.sagelyai.com/quick-start-guide'
                target={'_blank'}
                icon={<InfoCircledIcon />}
                >Quick Start Guide</Menu.Item>
            <Menu.Item 
                component='div'
                onClick={(e) => {
                    e.preventDefault();
                    navigate('/credits');
                }}
                icon={<i className="ri-copper-coin-line"></i>}
                rightSection={ creditsLoaded ? (
                <Text size='sm' color="indigo">
                    {((credits).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')).toString().replace('.00','')}
                </Text>
                ) : <Loader /> }
                >Tokens:</Menu.Item>
            <Menu.Item 
                component='div'
                onClick={(e) => {
                    e.preventDefault();
                    navigate('/api_tokens');
                }}
                icon={<i className="ri-key-line"></i>}
                >API Tokens</Menu.Item>
            <Menu.Item color="red" icon={<ExitIcon />} onClick={handleLogout}>Sign Out</Menu.Item>
        </Menu>

    </Box>
    )
}

const MainNavBar = ({
    authData,
    dispatch,
    navbarStatus,
    drawerMode = false,
    onDrawerToggle = () => {}
}) => {
    const navigate = useNavigation();

    const baseWidth = useMemo(() => {
        return ( navbarStatus ? 300 : 60 );
    }, [ navbarStatus ]);

    const links = useMemo(() => {
        let links = [
            {
                label: 'Dashboard',
                icon: <DashboardIcon />,
                link: '/dashboard',
                onClick: () => handleLinkClick('/dashboard')
            },
            // {
            //     label: 'My Team',
            //     icon: <MixIcon />,
            //     onClick: () => handleLinkClick('/team')
            // },
        ];
        if ( DEV_MODE ) {
            links.push({
                label: 'Upload EPUB',
                icon: <UploadIcon />,
                link: '/upload_epub',
                onClick: () => handleLinkClick('/upload_epub')
            });
        }
        return links;
    },[]);

    const handleLinkClick = (path) => {
        navigate(path);
    }

    return (
    <Navbar 
        width={{ base: drawerMode ? '100%' : baseWidth }} 
        height={"100vh"} 
        fixed={ drawerMode ? false : true }
        p="xs"
        sx={(theme) => ({
            backgroundColor: theme.colors.brand[0],
            color: theme.colors.gray[4],
        })}>

        <Navbar.Section mt="xs">
            <Brand
                dispatch={dispatch}
                navbarStatus={navbarStatus}
                drawerMode={drawerMode}
                onDrawerToggle={onDrawerToggle} />
        </Navbar.Section>

        <Navbar.Section
            pb={10}
            >
            <Center>
                <DocumentAddNew 
                    mode="navbar"
                    drawerMode={drawerMode}
                    onDrawerToggle={onDrawerToggle} />
            </Center>
            <Space h="sm" />
            { links.map((link, index) => (
                <Tooltip
                    key={index}
                    disabled={ navbarStatus || drawerMode }
                    position="right"
                    label={link.label}
                    withArrow
                    sx={(theme) => ({
                        display: navbarStatus ? 'block' : 'inline-block',
                    })}>
                    <Button 
                        component='a'
                        href={link.link}
                        variant="subtle"
                        color="gray" 
                        radius="xs" 
                        size={ navbarStatus || drawerMode ? "md" : 'sm' }
                        leftIcon={link.icon}
                        fullWidth
                        sx={(theme) => ({
                            height: navbarStatus || drawerMode ? '50px' : '42px',
                            padding: navbarStatus || drawerMode ? '0 22px' : '0 10px',
                            color: theme.colors.gray[5],
                            backgroundColor: 'none',
                            '& .mantine-Button-inner': {
                                justifyContent: 'flex-start',
                            },
                            '&:hover': {
                                backgroundColor: theme.colors.gray[1],
                                color: theme.colors.dark[9]
                            },
                            '& .mantine-Button-leftIcon': {
                                marginRight: navbarStatus || drawerMode ? '10px' : '0px',
                            }
                        })}
                        onClick={(e) => {
                            e.preventDefault();
                            link.onClick();
                            if ( drawerMode )
                                onDrawerToggle();
                        }} >
                        { navbarStatus || drawerMode ? <Text>{ link.label || '' }</Text> : null }
                    </Button>
                </Tooltip>
            )) }
        </Navbar.Section>

        <Navbar.Section
            id="navbar-folderslist"
            grow
            component={ScrollArea}
            sx={(theme) => ({
                borderTop: `2px solid ${theme.colors.brand[1]}`,
                padding: navbarStatus || drawerMode ? '15px 10px 15px 10px' : '15px 0px'
            })}>
            <FoldersList
                drawerMode={drawerMode}
                onDrawerToggle={onDrawerToggle} />
        </Navbar.Section>

        <Navbar.Section>
            { authData ? <User 
                authData={authData}
                navbarStatus={navbarStatus}
                drawerMode={drawerMode}
                onDrawerToggle={onDrawerToggle} /> : null }
        </Navbar.Section>

    </Navbar>
    )
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        navbarStatus: ( state.global && state.global.navbar ) ? state.global.navbar : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(MainNavBar);