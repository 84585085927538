import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react';
import { BrowserRouter, Route, Navigate, Routes, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Space } from '@mantine/core';

import ScreenLoader from './components/ScreenLoader';
import AppShell from './modules/AppShell';

import Dashboard from './pages/Dashboard';
// import Folders from './pages/Folders';
import Document from './pages/Document';
import Documents from './pages/Documents';
import Credits from './pages/Credits';
import APIToken from './pages/APIToken';
import UploadEpub from './pages/UploadEpub';
// import Settings from './pages/Settings';
import Login from './pages/Login';

import { isDev, isValidUser } from './helpers/auth';

import { isUserLoggedIn } from './redux/auth/actions';

import { DEV_MODE } from './constants';

const isActive = (authData) => {
   return ( authData && authData.status && authData.status === 'active' ? true : false );
}

const PublicRoute = (props) => {
    const { component, authData, ...rest } = props;
    if ( isValidUser(authData) && isActive(authData) ) {
        // if is user, redirect to dashboard
        return <Navigate to={'/dashboard'} />
    } else {
        // else, return the current element
        return <props.component 
                authData={authData} 
                {...rest} />;
    } // end - authData
}

const UserRoute = (props) => {
    const { component, authData, fullWidth = false, showFooter = true, backgroundColor = null, ...rest } = props;
    return authData ? ( isValidUser(authData) && isActive(authData) ? (
    <AppShell 
        fullWidth={fullWidth}
        showFooter={showFooter}
        backgroundColor={backgroundColor}>
        <props.component authData={authData} {...rest} />
    </AppShell>
    ) : (
        <Navigate to={'/noaccess'} />
    ) ) : (
        <Navigate to={'/login'} />
    )
}

const DevUserRoute = (props) => {
    const { component, authData, fullWidth = false, showFooter = true, backgroundColor = null, ...rest } = props;
    return authData ? ( isValidUser(authData) && isActive(authData) && isDev(authData) ? (
    <AppShell 
        fullWidth={fullWidth}
        showFooter={showFooter}
        backgroundColor={backgroundColor}>
        <props.component authData={authData} {...rest} />
    </AppShell>
    ) : (
        <Navigate to={'/noaccess'} />
    ) ) : (
        <Navigate to={'/login'} />
    )
}

const StructureWrapper = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
} 

const Structure = (props) => {
    const {
        dispatch,
        fbApp = null,
        authData = null,
        // loader,
        // loader_label
    } = props;
    const [ loaded, setLoaded ] = useState(false);
    const [ authRand, setAuthRand ] = useState( false );

    useEffect(() => {
        dispatch(isUserLoggedIn());
    },[]);

    useEffect(() => {
        if ( props.authRand && !authRand ) {
            setAuthRand( props.authRand );
            setLoaded(true);
        }
    }, [ props.authRand ]);

    const pageProps = useMemo(() => {
        return { authData, fbApp };
    }, [ authData, fbApp ]);
    
    return loaded ? (
    <BrowserRouter>
        <StructureWrapper>
            <Routes>

                <Route exact path="/dashboard" element={<UserRoute component={Dashboard} {...pageProps} />} />

                <Route exact path="/credits" element={<UserRoute component={Credits} {...pageProps} />} />
                <Route exact path="/api_tokens" element={<UserRoute component={APIToken} {...pageProps} />} />
                
                <Route exact path="/folders" element={<Navigate to="/dashboard" />} />
                <Route exact path="/folders/:folder_id" element={<UserRoute component={Documents} {...pageProps} />} />
                <Route exact path="/folders/:folder_id/docs/:doc_id" element={<UserRoute component={Document} fullWidth={true} showFooter={false} {...pageProps} />} />

                {/* <Route exact path="/settings" element={<UserRoute component={Settings} {...pageProps} />} /> */}

                {/* under dev */}
                <Route exact path="/upload_epub" element={<DevUserRoute component={UploadEpub} {...pageProps} />} />

                <Route exact path="/login" element={<PublicRoute component={Login} {...pageProps} />} />
                <Route exact path="/noaccess" element={<PublicRoute component={Login} {...pageProps} />} />

                <Route exact path="/" element={<Navigate to="/dashboard" />} />
                
            </Routes>
        </StructureWrapper>
    </BrowserRouter>
    ) : <ScreenLoader />;
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        authRand: ( state.auth && state.auth.rand ) ? state.auth.rand : null,
        // loader: state.global.loader,
        // loader_label: state.global.loader_label
    };
};

export default connect(mapStateToProps)(Structure);