import React, { useMemo } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { Title, Text } from '@mantine/core';

const InsightsTour = ({
    id = '',
    defaultStyles = {},
    defaultLocale = {},
    defaultButtonStyles = {},
    onSkip = () => {}
}) => {

    const steps = useMemo(() => {
        return [
            {
                target: '#insight-1',
                placement: 'left',
                spotlightClicks: true,
                spotlightPadding: 20,
                content: (
                <>
                    <Text size="sm" align='left' mb={"sm"}><strong>Tip #1:</strong> <u>Hover over</u> the box, and you should see the highlighted text on the left that represents the content used to generate the insight</Text>
                    <Text size="sm" align='left'>If you don't see the highlighted text, you can <strong><u>click on it</u></strong> to automatically scroll to its position.</Text>
                </>
                )
            },
            {
                target: '#insight-1-addnotes',
                placement: 'left',
                spotlightClicks: true,
                z: 100,
                content: (
                <>
                    <Text size="sm" align='left'><strong>Tip #3:</strong> You can add insight into notes</Text>
                </>
                )
            },
            {
                target: '#insight-1-copy',
                placement: 'left',
                spotlightClicks: true,
                content: (
                <>
                    <Text size="sm" align='left'><strong>Tip #4:</strong> You can copy insight into your clipboard.</Text>
                </>
                )
            },
            {
                target: '#insight-1-rate',
                placement: 'left',
                spotlightClicks: true,
                content: (
                <>
                    <Text size="sm" align='left'><strong>Tip #5:</strong> You can rate the insight generated</Text>
                </>
                )
            },
            {
                target: '#insight-1-regenerate',
                placement: 'left',
                spotlightClicks: true,
                content: (
                <>
                    <Text size="sm" align='left'><strong>Tip #6:</strong> You can regenerate the insight if you feel it doesn't match what you want. The total token needed to regenerate this insight will be shown on the popup when you hover over the button.</Text>
                </>
                )
            }
        ]
    },[]);

    const handleCallback = (data) => {
        const { status } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            onSkip(id);
        }
    }
    
    return (
    <>

        <Joyride
            steps={steps}
            run={true}
            disableOverlay={false}
            disableCloseOnEsc
            disableOverlayClose
            hideCloseButton
            showSkipButton
            showProgress
            spotlightPadding={10}
            continuous
            locale={{
                ...defaultLocale,
                skip: 'Skip',
                last: 'Finish'
            }}
            callback={handleCallback}
            styles={{
                options: {
                    ...defaultStyles,
                    width: 400,
                    zIndex: 100
                },
                buttonNext: {
                    ...defaultButtonStyles,
                    padding: '10px 25px',
                    fontWeight: 'bold'
                },
                buttonBack: {
                    ...defaultButtonStyles
                },
                buttonClose: {
                    ...defaultButtonStyles
                },
                buttonSkip: {
                    ...defaultButtonStyles
                },
            }}
            />
    
    </>
    )
}

export default InsightsTour;