

import { 
    GET_DOCUMENTS,
    GET_DOCUMENT,
    UPDATE_DOCUMENT_SETTINGS,
    RESET_DOCUMENTS,
    RESET_DOCUMENT
} from './types';

const initialState = {
    documents: null,
    document: null,
    document_settings: null,
    document_settings_rand: false,
    updated: false,
    rand_single: false,
    rand: false
};

export const documents = (state = initialState, action) => {
    const { type, payload = null } = action;
    switch (type) {
        case GET_DOCUMENTS:
            return {
                ...state,
                documents: payload.list || [],
                rand: Math.floor(Math.random() * 1000000)
            };
        case GET_DOCUMENT:
            return {
                ...state,
                document: payload.document || null,
                rand_single: Math.floor(Math.random() * 1000000)
            };
        case UPDATE_DOCUMENT_SETTINGS:
            return {
                ...state,
                document_settings: payload || null,
                document_settings_rand: Math.floor(Math.random() * 1000000)
            };
        case RESET_DOCUMENTS:
            return {
                ...state,
                documents: null,
                rand: false
            };
        case RESET_DOCUMENT:
            return {
                ...state,
                document: null,
                document_settings: null,
                document_settings_rand: false,
                rand_single: false
            };
        default:
            return state;
    }
}