import React, { useMemo } from 'react';
// import { compose } from "recompose";
// import { connect } from "react-redux";
// import { useSelector } from 'react-redux';
// import { useParams } from "react-router-dom";
import { Text, Group, Alert } from '@mantine/core';
// import { useClipboard } from '@mantine/hooks';
// import { useNotifications } from '@mantine/notifications';
import { InfoCircledIcon } from '@radix-ui/react-icons';


const TokensUsageInsights = ({
    keypoints = []
}) => {

    const totalPrompts = useMemo(() => {
        let tokens = 0;
        if ( keypoints && keypoints.length > 0 ) {
            keypoints.forEach(keypoint => {
                if ( keypoint.usage && keypoint.usage.prompt_tokens && keypoint.usage.prompt_tokens ) {
                    tokens += keypoint.usage.prompt_tokens;
                }
            });
        }
        return tokens;
    },[ keypoints ]);

    const totalCompletions = useMemo(() => {
        let tokens = 0;
        if ( keypoints && keypoints.length > 0 ) {
            keypoints.forEach(keypoint => {
                if ( keypoint.usage && keypoint.usage.completion_tokens && keypoint.usage.completion_tokens ) {
                    tokens += keypoint.usage.completion_tokens;
                }
            });
        }
        return tokens;
    },[ keypoints ]);

    const totalTokens = useMemo(() => {
        let tokens = 0;
        if ( keypoints && keypoints.length > 0 ) {
            keypoints.forEach(keypoint => {
                if ( keypoint.usage && keypoint.usage.total_tokens && keypoint.usage.total_tokens ) {
                    tokens += keypoint.usage.total_tokens;
                }
            });
        }
        return tokens;
    },[ keypoints ]);

    const totalCost = useMemo(() => {
        let cost = ( totalPrompts/1000*0.0015 ) + (totalCompletions/1000*0.002);
        // round up to 3 decimal places
        return Math.round((cost + Number.EPSILON) * 1000) / 1000;
    }, [ totalPrompts, totalCompletions ]);

    return (
    <>

        <Alert
            title="Insights Token(s) Usage"
            variant='filled'
            color="indigo"
            m="sm"
            icon={<InfoCircledIcon />}>
            <Group position='apart' mb="2px">
                <Text size="xs" weight={700} color="#fff">Prompts Token(s):</Text>
                <Text size="sm" color="#fff">{totalPrompts}</Text>
            </Group>
            <Group position='apart' mb="2px">
                <Text size="xs" weight={700} color="#fff">Completions Token(s):</Text>
                <Text size="sm" color="#fff">{totalCompletions}</Text>
            </Group>
            <Group position='apart' mb="2px">
                <Text size="xs" weight={700} color="#fff">Total Token(s):</Text>
                <Text size="sm" color="#fff">{totalTokens}</Text>
            </Group>
            <Group position='apart' mb="2px">
                <Text size="xs" weight={700} color="#fff">Total Cost:</Text>
                <Text size="sm" color="#fff">${totalCost }</Text>
            </Group>
        </Alert>

    </>
    );
}

export default TokensUsageInsights;