import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Group, Text, Button, Space, InputWrapper, Input, LoadingOverlay, Loader, Center, Container, Switch } from '@mantine/core';
import { useNotifications } from '@mantine/notifications';
import { Link2Icon } from '@radix-ui/react-icons';

// import { isArrayExists } from '../../helpers/validation';
import { tiptapFilterContent, getCustomExtensionsFromDoc } from '../../helpers/tiptap';
import { getAPIErrorMessage, callFunctionsAPI } from '../../helpers/api';

import { toggleGlobalDisabled } from '../../redux/global/actions';

const AddNewWebLink = ({
    editorRef = null,
    opened = false,
    formData = {},
    loading = false,
    onUpdate = () => {},
}) => {
    const notifications = useNotifications();
    const dispatch = useDispatch();
    // const authData = useSelector(state => state.auth.user);
    const globalDisabled = useSelector(state => state.global.disabled);
    const [ processing, setProcessing ] = useState(false);
    const [ overwriteTitle, setOverwriteTitle ] = useState(true);

    useEffect(() => {
        if ( !opened ) {
            // reset settings
            setOverwriteTitle(true);
        }
    },[ opened ]);

    const disabled = useMemo(() => {
        return loading || processing || globalDisabled ? true : false;
    },[ loading , processing, globalDisabled ]);

    const isValidURL = useMemo(() => {
        // check if link is a valid url or not
        return ( formData && formData.web_link && formData.web_link !== '' && formData.web_link.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/) ? true : false );
    },[ formData ]);

    const handleWebLinkExtract = () => {
        setProcessing(true);
        callFunctionsAPI({
            url: 'weblink',
            action: 'extract',
            formData: { web_link: formData.web_link }
        })
        .then(data => {
            setProcessing(false);
            if ( data ) {
                const htmlContent = tiptapFilterContent( data.content, getCustomExtensionsFromDoc( formData ) );
                onUpdate({
                    ...formData,
                    // name: ( formData && formData.name !== '' ? formData.name : data.title ),
                    name: ( overwriteTitle ? data.title : ( formData.name || '' ) ),
                    content: htmlContent,
                    content_source: 'editor'
                });
                // update editor
                const editor = editorRef.current;
                if ( editor ) {
                    editor.chain().clearContent().setContent( ( htmlContent ) ).run();
                }
            } else {
                notifications.showNotification({
                    color: 'red',
                    title: 'Error in extracting web link',
                }) 
            } // end data
        })
        .catch(error => {
            setProcessing(false);
            notifications.showNotification({
                color: 'red',
                title: getAPIErrorMessage( error ),
            }) 
        });
    }

    return (
    <Box>
        <LoadingOverlay 
            loader={(
            <>
                <Center>
                    <Loader />
                </Center>
                <Space h="md" />
                <Container size={"sm"}>
                    <Text align='center' size="md" color="dark" weight={700}>This process might takes up to 60 seconds (or more).</Text>
                </Container>
            </>
            )}
            visible={processing} />
        <InputWrapper
            id="weblink"
            required
            label="Web URL"
            size="sm"
            >
            <Input id="weblink" disabled={disabled} placeholder="Ex: https://seths.blog/2011/05/hard-work-vs-long-work/" value={( formData.web_link || '' )} name="web_link" onChange={(event) => onUpdate({
                ...formData,
                web_link: event.target.value
            })} />
        </InputWrapper>
        <Space h="sm" />
        <Switch
            label="Overwrite Document Name"
            checked={overwriteTitle}
            onChange={(event) => setOverwriteTitle(event.target.checked)}
            disabled={disabled}
            />
        <Space h="sm" />
        <Switch
            label="Extract images if available"
            checked={( formData.content_allow_image && formData.content_allow_image === 'yes' ? true : false )}
            onChange={(event) => onUpdate({
                ...formData,
                content_allow_image: event.currentTarget.checked ? 'yes' : 'no'
            })}
            disabled={disabled}
            />
        <Space h="sm" />
        <Switch
            label="Preverse all links in the content"
            checked={( formData.content_allow_links && formData.content_allow_links === 'yes' ? true : false )}
            onChange={(event) => onUpdate({
                ...formData,
                content_allow_links: event.currentTarget.checked ? 'yes' : 'no'
            })}
            disabled={disabled}
            />
        <Group mt="md">
            <Button
                color="blue"
                size='xs'
                radius={"md"}
                compact
                disabled={ !isValidURL || disabled ? true : false }
                leftIcon={ <Link2Icon /> }
                onClick={handleWebLinkExtract}
                >Extract Content from URL</Button>
        </Group>
    </Box>
    )
}

export default AddNewWebLink;