import React, { useState, useMemo, useRef } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { Box, Text, Avatar, Group, Space, ScrollArea } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
// import { useNotifications } from '@mantine/notifications';
import { ReaderIcon } from '@radix-ui/react-icons';

import Insights from '../../modules/Insights';

// import { toggleGlobalDisabled } from '../../redux/global/actions';
import { useScreenSize } from '../../redux/global/hooks';


const PanelInsights = ({
    editorRef = null,
    authData,
    navbarStatus,
    dispatch,
    document,
    onUpdate = () => {}
}) => {
    const [ screenSize ] = useScreenSize();
    const viewport = useRef();
    const { height } = useViewportSize();

    const mobileMode = useMemo(() => {
        return screenSize === 'xs' || screenSize === 'sm' ? true : false;
    },[ screenSize ]);

    return (
    <Box sx={(theme) => ({
        padding: '0px'
    })}>
        <Group position='left' spacing={"xs"}>
            <Avatar size={ mobileMode ?  "sm" : "md" } radius={"md"} sx={(theme) => ({
                '& .mantine-Avatar-placeholder': {
                    backgroundColor: theme.colors.indigo[5],
                }
            })}><ReaderIcon size={ mobileMode ? 16 : 24 } color="#fff" /></Avatar>
            <div>
                <Text size={ mobileMode ?  "sm" : "md" } weight='700'>Insights</Text>
                { mobileMode ? null : <Text size="xs" color="gray">Generate Insights using AI</Text> }
            </div>
        </Group>
        <Space h="xs" />
        <Box sx={(theme) => ({
            height: height - 74,
            paddingBottom: (theme.spacing.lg*2)+'px',
        })}>
            <ScrollArea 
                viewportRef={viewport}
                sx={(theme) => ({
                    paddingRight: theme.spacing.md,
                    height: ( screenSize === 'sm' || screenSize === 'xs' ? 'calc( 50% - 75px )' : 'calc( 100% - 20px )' ),
                })}> 
                <Insights
                    editorRef={editorRef}
                    viewport={viewport}
                    document={document}
                    onUpdate={onUpdate}
                    />
            </ScrollArea>
        </Box>
    </Box>
    );
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        navbarStatus: ( state.global && state.global.navbar ) ? state.global.navbar : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(PanelInsights);