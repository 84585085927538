import React, { useMemo, forwardRef } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { Title, Text } from '@mantine/core';

const AddFirstDocTour = ({
    id = '',
    defaultStyles = {},
    defaultLocale = {},
    defaultButtonStyles = {},
    onSkip = () => {}
}) => {

    const steps = useMemo(() => {
        return [
            {
                target: '#addnewdoc-folder-add',
                placement: 'bottom',
                content: (
                <>
                    <Text size={"sm"} align="left"><strong>Tip #1:</strong> You can easily add a new folder on the fly by clicking on the "Add New Folder" button.</Text>
                </>
                )
            },
            {
                target: '#addnewdoc-content',
                placement: 'left',
                content: (
                <>
                    <Text size={"sm"} align="left"><strong>Tip #2:</strong> Insert the content you want to be summarized into the editor here.</Text>
                </>
                )
            },
            {
                target: '#addnewdoc-wordcount',
                placement: 'left',
                content: (
                <>
                    <Text size={"sm"} align="left"><strong>Tip #3:</strong> There is a limit of 20,000 words per document. You might have to break it into multiple documents if your content exceeds the limit.</Text>
                </>
                )
            },
            {
                target: '#addnewdoc-settings',
                placement: 'left',
                content: (
                <>
                    <Text size={"sm"} align="left"><strong>Tip #4:</strong> By default, the app will automatically inherit links & images from pasted content. You can turn this on or off by clicking on the "Allow Links" / "Allow Images" button at the editor's bottom.</Text>
                </>
                )
            },
        ]
    },[]);

    const handleCallback = (data) => {
        const { status } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            onSkip(id);
        }
    }
    
    return (
    <>

        <Joyride
            steps={steps}
            run={true}
            disableOverlay
            disableCloseOnEsc
            disableOverlayClose
            hideCloseButton
            showSkipButton
            showProgress
            spotlightPadding={0}
            continuous
            locale={{
                ...defaultLocale,
                skip: 'SKIP'
            }}
            callback={handleCallback}
            styles={{
                options: {
                    ...defaultStyles,
                    zIndex: 2000,
                    width: 300
                },
                buttonNext: {
                    ...defaultButtonStyles,
                    padding: '10px 25px',
                    fontWeight: 'bold'
                },
                buttonBack: {
                    ...defaultButtonStyles
                },
                buttonClose: {
                    ...defaultButtonStyles
                },
                buttonSkip: {
                    ...defaultButtonStyles
                },
            }}
            />
    
    </>
    )
}

export default AddFirstDocTour;