import React, { useState, useEffect, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { Button, Modal, Space, MultiSelect, Loader, Center, Box } from '@mantine/core';
import { CheckIcon, PaperPlaneIcon } from '@radix-ui/react-icons';
import { useModals } from '@mantine/modals';
import { useNotifications } from '@mantine/notifications';

import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/api';
import { isArrayExists, validate } from '../../helpers/validation';

const FolderShare = ({
    authData,
    navbarStatus,
    globalDisabled,
    drawerOpened,
    opened = false,
    folder = {},
    dispatch,
    onClose = () => {}
}) => {
    const db = getFirestore();
    const [ shareEmails, setShareEmails ] = useState([]);
    const [ folderData, setFolderData ] = useState({});
    const [ loaded, setLoaded ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const notifications = useNotifications();

    useEffect(() => {
        let unsubscribe;
        if ( opened ) {
            setLoaded(false);
            setFolderData({});
            setShareEmails([]);
            onSnapshot(doc(db, "folders", folder.id), (doc) => {
                const data = ( doc.exists ? doc.data() : {} );
                let emails = [];
                if ( data && data.access && isArrayExists( data.access ) ) {
                    data.access.forEach( (item) => {
                        emails.push( item.id );
                    });
                }
                setFolderData(data);
                setShareEmails( emails );
                setLoaded(true);
            });
        }
        return () => {
            if ( unsubscribe ) {
                unsubscribe();
            }
            setLoaded(false);
            setLoading(false);
            setShareEmails([]);
        }
    }, [ opened, folder ]);

    const handleFolderShare = () => {
        setLoading(true);
        callFunctionsAPI({
            url: 'folder',
            action: 'share',
            formData: {
                id: folder.id,
                target_emails: shareEmails
            }
        })
        .then(() => {
            setLoading(false);
            setFolderData({});
            setShareEmails([]);
            onClose();
            notifications.showNotification({
                color: 'teal',
                icon: <CheckIcon />,
                title: 'Folder Shared',
            })
        })
        .catch(err => {
            setLoading(false);
            notifications.showNotification({
                color: 'red',
                title: getAPIErrorMessage(err),
            })
        })
    }

    return (
    <>
        
        <Modal
            opened={opened}
            onClose={onClose}
            title={'Share Folder: ' + folder.name || ''}
            size="lg"
            centered
            >
            { loaded ? (
            <>
                <MultiSelect
                    required
                    disabled={loading}
                    label="Enter the email you want to share this folder with"
                    placeholder='Enter email'
                    searchable
                    creatable
                    value={shareEmails}
                    data={[]}
                    getCreateLabel={(query) => `+ ${query}`}
                    shouldCreate={(query) => {
                        // make sure the email is valid and was not already added
                        if ( query && validate( query, 'email' ) && !shareEmails.includes( query ) ) {
                            return true;
                        }
                        return false;
                    }}
                    onCreate={(query) => {
                        // add the email to the list
                        setShareEmails( [...shareEmails, query] );
                    }}
                    onChange={(newValue) => {
                        let emails = [ ...newValue ];
                        // if owner wasn't in the list, add it
                        if ( folderData && folderData.owner && !emails.includes( folderData.owner ) ) {
                            emails.push( folderData.owner );
                        }
                        setShareEmails( emails );
                    }}
                    />
                <Space h="xs" />
                <Button
                    size="md"
                    color="indigo"
                    fullWidth
                    loading={loading}
                    rightIcon={<PaperPlaneIcon />}
                    onClick={handleFolderShare}
                    >
                    Submit
                </Button>
            </>
            ) : (
            <Box
                sx={() => ({ height: '200px' })}>
                <Center><Loader variant='bars' /></Center>
            </Box>
            ) }
        </Modal>
    </>
    )
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        globalDisabled: ( state.global && state.global.disabled ) ? state.global.disabled : false,
        navbarStatus: ( state.global && state.global.navbar ) ? state.global.navbar : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(FolderShare);