import React, { useState, useMemo, useEffect } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useMantineTheme, Button } from '@mantine/core';
// import { useModals } from '@mantine/modals';
import { useNotifications } from '@mantine/notifications';
import { PlusIcon, PlusCircledIcon, CheckIcon } from '@radix-ui/react-icons';

import FolderFormModal from '../Folders/modal';
import FormModal from './modal';
import ButtonIconCircle from '../../components/ButtonIconCircle';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/api';
import { tiptapWordsCount } from '../../helpers/tiptap';

import { addActiveModal, removeActiveModal } from '../../redux/modals/actions';

// import { GET_DOCUMENTS } from './redux/types';

const DocumentAddNew = ({
    authData,
    dispatch,
    noButton = false,
    noRedirectAfterCreate = false,
    forceOpen = false,
    predefinedFormData = false,
    foldersList = [],
    globalDisabled,
    navbarStatus = false,
    mode = 'button',
    drawerMode = false,
    onDrawerToggle = () => {},
    onDocAdded = () => {},
    onModalClose = () => {},
}) => {
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const notifications = useNotifications();
    const { folder_id } = useParams();
    const [ opened, setOpened ] = useState(false);
    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ addNewFolderOpened, setAddNewFolderOpened ] = useState(false);

    useEffect(() => {
        if ( forceOpen ) {
            setOpened( true ); 
        } else {
            // reset everything
            setLoading(false);
            setOpened(false);
        }
    }, [ forceOpen ] );

    useEffect(() => {
        if ( opened ) {
            dispatch(addActiveModal('addnewdoc'));
        } else {
            dispatch(removeActiveModal('addnewdoc'));
            if ( onModalClose )
                onModalClose();
        }
    },[ opened ]);

    // const disabled = useMemo(() => {
    //     return loading ? true : false;
    // },[ loading ]);

    useEffect(() => {
        if ( opened ) {
            let newFormData = {
                name: '',
                folder_id: ( folder_id && folder_id !== '' && foldersList && isArrayExists( foldersList ) && foldersList.find( f => f.id === folder_id ) ) ? folder_id : '',
                content_allow_image: 'yes',
                content_allow_links: 'yes'
            };
            if ( predefinedFormData && isObjectExists( predefinedFormData ) ) {
                newFormData = { ...newFormData, ...predefinedFormData };
            }
            setFormData(newFormData);
        } else {
            setFormData({});
        }
    },[ opened, predefinedFormData ]);

    const isNavBarMode = useMemo(() => {
        return ( mode && mode === 'navbar' ? true : false );
    },[ mode ]);

    const doModalClose = () => {
        // reset everything
        setLoading(false);
        setOpened(false);
    }

    const handleAddNew = (submitData) => {
        let error = false;
        // do error check

        // make sure content doesn't exceed 20,000 words
        if ( submitData.content && submitData.content !== '' ) {
            const wordsCount = tiptapWordsCount( submitData.content );
            if ( wordsCount > 60000 ) {
                error = 'Document content cannot exceed 40,000 words.';
            }
        }

        if ( !submitData.folder_id ) {
            error = 'Please select a folder';
        }

        // check if name is empty
        if ( !submitData.name ) {
            error = 'Name is required';
        }
        
        if ( !submitData.content_source || ( submitData.content_source &&  submitData.content_source === 'editor' ) ) {
            // check if name is empty
            if ( !( formData.content && formData.content !== '<p><br /></p>' && formData.content !== '<p></p>' ) ) {
                error = 'Content is required';
            }
        }

        if (  submitData.content_source &&  submitData.content_source === 'epub' ) {
            // check if name is empty
            if ( !( submitData.epub_id && submitData.epub_id !== '' ) ) {
                // error = 'EPUB file is required';
                error = 'Upload EPUB file is not available at the moment';
            }
        }

        if (  submitData.content_source &&  submitData.content_source === 'web_link' ) {
            if ( !( formData.content && formData.content !== '<p><br /></p>' && formData.content !== '<p></p>' ) ) {
                error = 'Content is required';
            }

            if ( !( submitData.web_link && submitData.web_link !== '' ) ) {
                error = 'Web link is required';
            }
        }

        // check if errors are empty
        if ( error ) {
            notifications.showNotification({
                color: 'red',
                title: error,
            })
        } else {
            // no errors, add new document
            setLoading(true);
            callFunctionsAPI({
                url: 'document',
                action: 'add',
                formData: submitData
            })
            .then(({ new_document_id }) => {
                // trigger on doc added if available
                if ( onDocAdded ) {
                    onDocAdded({ 
                        new_document_id, 
                        folder_id: submitData.folder_id,
                        name: submitData.name || '', 
                    });
                }

                // trigger on drawer toggle if available
                if ( isNavBarMode || drawerMode ) {
                    if ( drawerMode ) {
                        onDrawerToggle();
                    }
                }

                // trigger modal close
                doModalClose();

                // redirect to new document
                if ( !noRedirectAfterCreate ) {
                    setTimeout(() => {
                        navigate(`/folders/${submitData.folder_id}/docs/${new_document_id}`);
                    },150);
                } // end - noRedirectAfterCreate

            })
            .catch(error => {
                setLoading(false);
                notifications.showNotification({
                    color: 'red',
                    title: getAPIErrorMessage( error ),
                }) 
            });
        }
    }

    const handleAddNewFolder = (submitData) => {
        let error = false;
        // do error check
        // check if name is empty
        if ( !submitData.name ) {
            error = 'Name is required';
        }

        // check if errors are empty
        if ( error ) {
            notifications.showNotification({
                color: 'red',
                title: error,
            })
        } else {
            // no errors, add new folder
            setLoading(true);
            callFunctionsAPI({
                url: 'folder',
                action: 'add',
                formData: submitData
            })
            .then(({ new_folder_id }) => {
                notifications.showNotification({
                    color: 'teal',
                    icon: <CheckIcon />,
                    title: 'Folder added',
                }) 
                // do reset
                setLoading(false);
                setAddNewFolderOpened(false);
                setFormData({
                    ...formData,
                    folder_id: new_folder_id
                });
            })
            .catch(error => {
                setLoading(false);
                notifications.showNotification({
                    color: 'red',
                    title: getAPIErrorMessage( error ),
                }) 
            });
        }
    }

    return (
    <>

        { !noButton ? ( isNavBarMode && !( navbarStatus || drawerMode ) ? (
        <ButtonIconCircle
            label={"Add New Document"}
            icon={<PlusCircledIcon />}
            position="right"
            btnProps={{
                id: `${( isNavBarMode ? 'navbar' : 'folder' )}-addnewdoc-icon`,
                size: 'xs',
                variant: "filled",
                color: 'indigo',
                padding: 'xs',
                radius: 'sm',
                disabled: globalDisabled
            }}
            btnSx={({
                height: '42px'
            })}
            color={theme.colors.indigo[8]}
            onClick={() => setOpened(true)} />
        ) : (
        <Button
            id={`${( isNavBarMode ? 'navbar' : 'folder' )}-addnewdoc`}
            size='md'
            color={"indigo"}
            leftIcon={<PlusIcon />}
            disabled={globalDisabled}
            onClick={() => setOpened(true)}>
            Add New Document
        </Button>
        ) ) : null }

        <FormModal 
            opened={opened}
            loading={loading}
            formData= {formData}
            folders={foldersList}
            predefinedFormData={predefinedFormData}
            onAddNewFolder={() => setAddNewFolderOpened(true)}
            onUpdate={(newValue) => setFormData(newValue)}
            onClose={doModalClose}
            doAction={handleAddNew} />

        <FolderFormModal 
            opened={addNewFolderOpened}
            loading={loading}
            onClose={() => setAddNewFolderOpened(false)}
            doAction={handleAddNewFolder} />
    
    </>
    )
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        globalDisabled: ( state.global && state.global.disabled ) ? state.global.disabled : false,
        navbarStatus: ( state.global && state.global.navbar ) ? state.global.navbar : false,
        foldersList: ( state.folders && state.folders.folders ) ? state.folders.folders : null,
    };
};

export default compose(
    connect(mapStateToProps)
)(DocumentAddNew);