import { useState, useEffect } from 'react';
// import { useDidUpdate } from '@mantine/hooks';
import { useSelector, useDispatch } from "react-redux";
import { getDatabase, ref, query, onValue, off } from "firebase/database";
// import { getFirestore, doc, onSnapshot } from "firebase/firestore";

import { 
    CREDITS_GET
} from './types';

// import { isArrayExists, isObjectExists } from '../../../helpers/validation';
// import { getUserActiveTeam } from '../../../helpers/auth';

export const useCredits = () => {
    const authData = useSelector(state => state.auth.user);
    const dispatch = useDispatch();
    const db = getDatabase();
    const [ loaded, setLoaded ] = useState(false);
    const [ credits, setCredits ] = useState(0);

    useEffect(() => {
        const uid = authData && authData.uid ? authData.uid : 'nouid';
        const Ref = query( ref(db, 'users/' + uid + '/credits' ) );
        onValue(Ref, (snapshot) => {
            const val = ( snapshot.exists() ? snapshot.val() : 0 );
            setCredits( val );
            setLoaded(true);
            dispatch({ type: CREDITS_GET, payload: { credits: val } });
        });
        return () => {
            setLoaded(false);
            off( Ref );
        };
    },[ authData ]);

    return [ loaded, credits ];
}