import React, { useMemo } from 'react';
import { Box, AppShell, Divider, Skeleton, Navbar, Text, Group, Avatar, Space, Container, Loader, Center, Grid, Card, Header } from '@mantine/core';
import { LightningBoltIcon } from '@radix-ui/react-icons';

import Footer from '../../modules/AppShell/footer';

import { useScreenSize } from '../../redux/global/hooks';

import { PLATFORM_NAME_LONG, PLATFORM_ORG_LOGO } from '../../constants';

const ScreenLoader = () => {
    const [ screenSize ] = useScreenSize();

    const mobileMode = useMemo(() => {
        return screenSize === 'sm' || screenSize === 'xs' ? true : false;
    },[screenSize]);
    
    return (
    <AppShell
        navbar={ !mobileMode && (
        <Navbar 
            width={{ base: 300 }} 
            height={"100vh"} 
            fixed={true}
            p="xs"
            sx={(theme) => ({
                backgroundColor: theme.colors.brand[0],
                color: theme.colors.gray[4],
            })}>
            <Group position='apart' p={"md"}>
                <Group>
                    <Avatar
                        src={PLATFORM_ORG_LOGO}
                        color="dark" 
                        radius="sm"
                        sx={(theme) => ({ border: `2px solid ${theme.colors.brand[1]}` })}>
                        {/* <LightningBoltIcon /> */}
                    </Avatar>
                    <Text>{PLATFORM_NAME_LONG}</Text>
                </Group>
            </Group>
            <Divider color="dark" />
            <Space h="xl" />
            <Center>
                <Loader variant='bars' />
            </Center>
        </Navbar>
        )}
        header={ mobileMode && (
        <Header
            height={60} 
            p="xs"
            pr="md"
            sx={(theme) => ({
                backgroundColor: theme.colors.brand[0],
            })}
            >
            <Group position='right'>
                <Text weight={700} sx={() => ({ color: '#fff', textTransform: 'uppercase' })}>{PLATFORM_NAME_LONG}</Text>
                <Avatar
                    color="dark" 
                    radius="sm"
                    onClick={(event) => {
                        event.preventDefault();
                        // navigate('/');
                    }}>
                    <LightningBoltIcon />
                </Avatar>
            </Group>
        </Header>
        )}
        styles={(theme) => ({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
            main: {
                marginLeft: mobileMode ? '0px' : '300px', 
            },
        })}>
        <Box sx={(theme) => ({
            padding: theme.spacing.md,
            minHeight: '100vh'
        })}>
            <Container>
                <Box>
                    <Skeleton width="35%" height={20} />
                    <Space h="md" />
                    <Divider />
                    <Space h="xl" />
                    <Grid spacing="md">
                        <Grid.Col sm={12} md={6}>
                            <Card
                                p="xl"
                                shadow={"sm"}
                                withBorder>
                                <Skeleton width="40%" height={12} mt={6} />
                                <Space h="lg" />
                                <Skeleton width="80%" height={12} mt={6} />
                                <Skeleton width="60%" height={12} mt={6} />
                                <Skeleton width="50%" height={12} mt={6} />
                            </Card>
                        </Grid.Col>
                        <Grid.Col sm={12} md={6}>
                            <Card
                                p="xl"
                                shadow={"sm"}
                                withBorder>
                                <Skeleton width="40%" height={12} mt={6} />
                                <Space h="lg" />
                                <Skeleton width="80%" height={12} mt={6} />
                                <Skeleton width="60%" height={12} mt={6} />
                                <Skeleton width="90%" height={12} mt={6} />
                            </Card>
                        </Grid.Col>
                    </Grid>
                </Box>
            </Container>
        </Box>
        <Divider />
        <Footer />
    </AppShell>
    )
    // return (
    // <Box sx={(theme) => ({
    //     height: '100%',
    //     minHeight: '100vh',
    //     backgroundColor: theme.colors.brand[0],
    // })}>
    //     <Center style={{ width: '100%', height: '100%', minHeight: '100vh' }}>
    //         <Box sx={(theme) => ({ textAlign: 'center' })}>
    //             <Avatar 
    //                 color="gray" radius="6px"
    //                 sx={(theme) => ({
    //                     width: '60px',
    //                     minWidth: '60px',
    //                     height: '60px',
    //                     margin: '0 auto',
    //                 })}
    //                 ><LightningBoltIcon style={{ width: 30, height: 30 }} /></Avatar>
    //             <Box sx={(theme) => ({
    //                 paddingTop: theme.spacing.md,
    //                 paddingBottom: '45px',
    //             })}>
    //                 <Title variant="h1" sx={(theme) => ({ color: theme.colors.gray[0] })}>{PLATFORM_NAME}</Title>
    //             </Box>
    //             <Loader 
    //                 size="xl" 
    //                 color="indigo"
    //                 variant="bars"
    //                 {...loaderProps} />
    //         </Box>
    //     </Center>
    // </Box>
    // )
}

export default ScreenLoader;