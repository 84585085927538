import React, { useMemo } from "react";
// import { compose } from "recompose";
// import { connect } from "react-redux";
// import { useSelector } from 'react-redux';
// import { useParams } from "react-router-dom";
import { Text, Group, Alert } from "@mantine/core";
// import { useClipboard } from '@mantine/hooks';
// import { useNotifications } from '@mantine/notifications';
import { InfoCircledIcon } from "@radix-ui/react-icons";

const TokensUsageExectiveSummaryGPT4o = ({ documentData }) => {
    const modelUsed = useMemo(() => {
        return documentData && documentData.exec_summary_model && documentData.exec_summary_model !== ""
            ? documentData.exec_summary_model
            : "gpt-3.5-turbo";
    }, [documentData]);

    const modelPrice = useMemo(() => {
        let price = {
            prompt: 0.03,
            completion: 0.06,
        };
        switch (modelUsed) {
            case "gpt-4o":
                price = {
                    prompt: 0.005,
                    completion: 0.015,
                };
                break;
            case "gpt-3.5-turbo-16k":
                price = {
                    prompt: 0.003,
                    completion: 0.004,
                };
                break;
            case "gpt-3.5-turbo":
                price = {
                    prompt: 0.0015,
                    completion: 0.002,
                };
                break;
            case "gpt-4-1106-preview":
                price = {
                    prompt: 0.01,
                    completion: 0.03,
                };
                break;
            case "ft:gpt-3.5-turbo-1106:smashing-advantage::8IA6IKSW":
                price = {
                    prompt: 0.003,
                    completion: 0.006,
                };
                break;
        }
        return price;
    }, [documentData, modelUsed]);

    const totalPrompts = useMemo(() => {
        let total = 0;
        if (documentData.exec_summary_usage && documentData.exec_summary_usage.prompt_tokens) {
            total += documentData.exec_summary_usage.prompt_tokens;
        }
        return total;
    }, [documentData]);

    const totalCompletions = useMemo(() => {
        let total = 0;
        if (documentData.exec_summary_usage && documentData.exec_summary_usage.completion_tokens) {
            total += documentData.exec_summary_usage.completion_tokens;
        }
        return total;
    }, [documentData]);

    const totalTokens = useMemo(() => {
        let total = 0;
        if (documentData.exec_summary_usage && documentData.exec_summary_usage.total_tokens) {
            total += documentData.exec_summary_usage.total_tokens;
        }
        return total;
    }, [documentData]);

    const totalCost = useMemo(() => {
        let cost = (totalPrompts / 1000) * modelPrice.prompt + (totalCompletions / 1000) * modelPrice.completion;
        // round up to 3 decimal places
        return Math.round((cost + Number.EPSILON) * 1000) / 1000;
    }, [totalPrompts, totalCompletions, modelPrice]);

    return (
        <>
            <Alert title="Executive Summary Token(s) Usage" variant="filled" color="indigo" m="sm" icon={<InfoCircledIcon />}>
                <Group position="apart" mb="2px">
                    <Text size="xs" weight={700} color="#fff">
                        Prompts Token(s):
                    </Text>
                    <Text size="sm" color="#fff">
                        {totalPrompts}
                    </Text>
                </Group>
                <Group position="apart" mb="2px">
                    <Text size="xs" weight={700} color="#fff">
                        Completions Token(s):
                    </Text>
                    <Text size="sm" color="#fff">
                        {totalCompletions}
                    </Text>
                </Group>
                <Group position="apart" mb="2px">
                    <Text size="xs" weight={700} color="#fff">
                        Total Token(s):
                    </Text>
                    <Text size="sm" color="#fff">
                        {totalTokens}
                    </Text>
                </Group>
                <Group position="apart" mb="2px">
                    <Text size="xs" weight={700} color="#fff">
                        Total Cost ({modelUsed}):
                    </Text>
                    <Text size="sm" color="#fff">
                        ${totalCost}
                    </Text>
                </Group>
            </Alert>
        </>
    );
};

export default TokensUsageExectiveSummaryGPT4o;
