import React, { useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { Box, Button, Group, useMantineTheme } from '@mantine/core';
import { useEditor, EditorContent, BubbleMenu } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit';
import Highlight from '@tiptap/extension-highlight';
// import { useWindowScroll } from '@mantine/hooks';
// import { PlusIcon } from '@radix-ui/react-icons';

import { isArrayExists } from '../../helpers/validation';

const Editor = ({
    editorRef = null,
    authData,
    editorDisabled = false,
    navbarStatus,
    dispatch,
    documentData,
    updating = false,
    onUpdate = () => {}
}) => {
    const theme = useMantineTheme();

    const extensions = [ 
        StarterKit,
        Highlight.configure({ 
            multicolor: true,
            HTMLAttributes: {
                class: 'highlighted',
            }
        }) 
    ];

    const editor = useEditor({
        content: ( documentData.content || '' ),
        extensions,
        // onUpdate: ({ editor }) => {
        //     onUpdate({ ...documentData, content: editor.getHTML() });
        // }
    },[]);

    if ( !editor )
        return null;

    editorRef.current = editor;

    return null;

    // return (
    // <Box
    //     className='main-editor-wrapper' 
    //     sx={(theme) => ({
    //         overflow: 'hidden',
    //         paddingTop: theme.spacing.xl,
    //         paddingBottom: theme.spacing.xl,
    //         paddingLeft: '50px',
    //         paddingRight: '50px',
    //         minHeight: '100vh',
    //         '& div.ProseMirror': {
    //             '& h1': { fontSize: '32px' },
    //             '& h2': { fontSize: '24px' },
    //             '& h3': { fontSize: '18px' },
    //             '& h4': { fontSize: '16px' },
    //             '& h5': { fontSize: '14px' },
    //             '& h6': { fontSize: '12px' },
    //             '& h1, & h2, & h3, & h4, & h5, & h6': {
    //                 margin: "30px 0 15px 0",
    //                 fontWeight: "bold"
    //             },
    //             'p, ul, ol, blockquote': {
    //                 marginBottom: '15px'
    //             },
    //             '&.ProseMirror-focused': {
    //                 outline: 'none',
    //                 boxShadow: 'none'
    //             }
    //         }
    //     })}>
    //     <BubbleMenu 
    //         editor={editor}>
    //         <Box sx={() => ({ 
    //                 backgroundColor: theme.colors.dark[5], 
    //                 padding: theme.spacing.sm,
    //                 borderRadius: theme.radius.xl,
    //                 '& button': {
    //                     padding: '0',
    //                     width: '16px',
    //                     height: '16px',
    //                 }
    //             })}>
    //             <Group spacing={"md"}>
    //                 <Button 
    //                     radius="xl" 
    //                     size="xs"
    //                     sx={() => ({
    //                         backgroundColor: theme.colors.yellow[3],
    //                         '&:hover': {
    //                             backgroundColor: theme.colors.yellow[3],
    //                             boxShadow: '0 0 0 4px ' + theme.colors.gray[1],
    //                         }
    //                     })}
    //                     onClick={() => editor.chain().focus().toggleHighlight({ color: theme.colors.yellow[3] }).run()} />
    //                 <Button 
    //                     radius="xl" 
    //                     size="xs"
    //                     sx={() => ({
    //                         backgroundColor: theme.colors.red[3],
    //                         '&:hover': {
    //                             backgroundColor: theme.colors.red[3],
    //                             boxShadow: '0 0 0 4px ' + theme.colors.gray[1],
    //                         }
    //                     })}
    //                     onClick={() => editor.chain().focus().toggleHighlight({ color: theme.colors.red[3] }).run()} />
    //                 <Button 
    //                     radius="xl" 
    //                     size="xs"
    //                     sx={() => ({
    //                         backgroundColor: theme.colors.green[3],
    //                         '&:hover': {
    //                             backgroundColor: theme.colors.green[3],
    //                             boxShadow: '0 0 0 4px ' + theme.colors.gray[1],
    //                         }
    //                     })}
    //                     onClick={() => editor.chain().focus().toggleHighlight({ color: theme.colors.green[3] }).run()} />
    //                 <Button 
    //                     radius="xl" 
    //                     size="xs"
    //                     sx={() => ({
    //                         backgroundColor: theme.colors.blue[3],
    //                         '&:hover': {
    //                             backgroundColor: theme.colors.blue[3],
    //                             boxShadow: '0 0 0 4px ' + theme.colors.gray[1],
    //                         }
    //                     })}
    //                     onClick={() => editor.chain().focus().toggleHighlight({ color: theme.colors.blue[3] }).run()} />
    //             </Group>
    //         </Box>
    //     </BubbleMenu>
    //     <EditorContent 
    //         editor={editor} />
    // </Box>
    // );
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        editorDisabled: ( state.global && state.global.editor_disabled ) ? state.global.editor_disabled : false,
        navbarStatus: ( state.global && state.global.navbar ) ? state.global.navbar : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(Editor);