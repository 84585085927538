import React, { useMemo, useRef, useState } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
import { Box, Text, Avatar, Group, Space, Divider, Button, ScrollArea, Select } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { useNotifications } from "@mantine/notifications";
import { ReaderIcon, TextAlignLeftIcon } from "@radix-ui/react-icons";
import TurndownService from "turndown";

// import AIModelSelector from '../../modules/AIModelSelector';
import TokensUsageInsights from "./components/tokens_usage_insights";
import TokensUsageExectiveSummary from "./components/tokens_usage_exec_summary";
import TokensUsageExectiveSummaryGPT4o from "./components/tokens_usage_exec_summary_gpt4o";
import DocumentSummaryEmbedding from "./components/embedding";

import { tiptapHTMLToItems, getCustomExtensionsFromDoc } from "../../helpers/tiptap";
import { findTitleBeforeParagraph } from "../../modules/Insights/helpers";

// import { toggleGlobalDisabled } from '../../redux/global/actions';
import { useScreenSize } from "../../redux/global/hooks";
import { isArrayExists } from "../../helpers/validation";

import { FIREBASE_CLOUD_API } from "../../constants";

const turndown = new TurndownService();

const saveAs = (blob, filename) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
};

const PanelDevTools = ({ editorRef = null, authData, navbarStatus, dispatch, document, onUpdate = () => {} }) => {
    const { insights } = useSelector((state) => state.insights);
    const [screenSize] = useScreenSize();
    const [exportType, setExportType] = useState("document");
    const notifications = useNotifications();
    const viewport = useRef();
    const clipboard = useClipboard({ timeout: 500 });

    const mobileMode = useMemo(() => {
        return screenSize === "xs" || screenSize === "sm" ? true : false;
    }, [screenSize]);

    const keypoints = useMemo(() => {
        let list = [];
        if (insights && insights.length > 0) {
            insights.forEach((insight) => {
                if (insight.keypoints && insight.keypoints.length > 0) {
                    insight.keypoints.forEach((keypoint) => {
                        list.push(keypoint);
                    });
                }
            });
        }
        return list;
    }, [insights]);

    const handleExportInsights = (type) => {
        switch (type) {
            case "clipboard":
            case "text":
                const contentItems = tiptapHTMLToItems(document.content || "", getCustomExtensionsFromDoc(document));
                let text = "";
                if (keypoints && keypoints.length > 0) {
                    keypoints.forEach((keypoint) => {
                        // add break line
                        text += text !== "" ? "\n\n" : "";
                        // add heading if available
                        const prompt = keypoint.prompt && isArrayExists(keypoint.prompt) ? keypoint.prompt : [];
                        const { text: title = "", level: title_level = 0 } = findTitleBeforeParagraph(contentItems, prompt[0] || "");
                        if (title && title !== "") {
                            for (let i = 0; i < title_level; i++) {
                                text += "#";
                            }
                            text += " " + title + "\n\n";
                        }
                        // add summarized text
                        text += keypoint.text;
                    });
                }
                if (type === "clipboard") {
                    clipboard.copy(text);
                    notifications.showNotification({
                        title: "Copied to clipboard",
                    });
                } else {
                    let blob = new Blob([text], { type: "text/plain;charset=utf-8" });
                    saveAs(blob, "insights.txt");
                }
                break;
        }
    };

    const handleExportDocuments = (type) => {
        const documentText = turndown.turndown(document.content || "");
        switch (type) {
            case "clipboard":
            case "text":
                if (type === "clipboard") {
                    clipboard.copy(documentText);
                    notifications.showNotification({
                        title: "Copied to clipboard",
                    });
                } else {
                    let blob = new Blob([documentText], { type: "text/plain;charset=utf-8" });
                    saveAs(blob, "document.txt");
                }
                break;
            case "clipboard_with_prompt":
                const documentTextWithPrompt = documentText + "\n\n";
                clipboard.copy(documentTextWithPrompt);
                notifications.showNotification({
                    title: "Copied to clipboard",
                });
                break;
        }
    };

    return (
        <Box
            sx={(theme) => ({
                padding: "0px",
            })}
        >
            <Group position="left" spacing={"xs"}>
                <Avatar
                    size={mobileMode ? "sm" : "md"}
                    radius={"md"}
                    sx={(theme) => ({
                        "& .mantine-Avatar-placeholder": {
                            backgroundColor: theme.colors.indigo[5],
                        },
                    })}
                >
                    <ReaderIcon size={mobileMode ? 16 : 24} color="#fff" />
                </Avatar>
                <Group>
                    <div>
                        <Text size={mobileMode ? "sm" : "md"} weight="700">
                            Dev Tools
                        </Text>
                        {mobileMode ? null : (
                            <Text size="xs" color="gray">
                                Various settings & tools for development purpose
                            </Text>
                        )}
                    </div>
                </Group>
            </Group>

            {/* <Space h="sm" />

        <InputWrapper
            label="Engine"
            required
            sx={() => ({
                '& .mantine-InputWrapper-label': {
                    paddingLeft: '10px'
                }
            })}
            >
            <div>
                <SegmentedControl
                    value={ ( document && document.engine ) || 'openai'}
                    data={[
                        { value: 'openai', label: 'OpenAI' },
                        // { value: 'openai_davinci', label: 'OpenAI (Davinci)' },
                        { value: 'ai21', label: 'AI21' },
                        { value: 'forefront', label: 'Forefront AI' },
                    ]}
                    onChange={(newValue) => {
                        // onUpdate({
                        //     ...document,
                        //     engine: newValue
                        // })
                    }} />
            </div>
        </InputWrapper> */}

            <Space h="sm" />

            {/* <Text weight={700} size="sm">
                Export Insights To:
            </Text> */}

            <Select
                value={exportType}
                data={[
                    { value: "document", label: "Export Document To:" },
                    { value: "insights", label: "Export Insights To:" },
                ]}
                placeholder="Select an option"
                onChange={(newValue) => {
                    setExportType(newValue);
                }}
            />

            <Space h="xs" />
            <Group spacing={"5px"}>
                <Button
                    size="sm"
                    compact
                    color="dark"
                    variant="filled"
                    leftIcon={<TextAlignLeftIcon />}
                    onClick={() => {
                        if (exportType === "document") handleExportDocuments("clipboard");
                        if (exportType === "insights") handleExportInsights("clipboard");
                    }}
                >
                    Clipboard
                </Button>
                <Button
                    size="sm"
                    compact
                    color="dark"
                    variant="filled"
                    leftIcon={<TextAlignLeftIcon />}
                    onClick={() => {
                        if (exportType === "document") handleExportDocuments("text");
                        if (exportType === "insights") handleExportInsights("text");
                    }}
                >
                    Text
                </Button>
                {exportType && exportType === "insights" && (
                    <Button
                        size="sm"
                        compact
                        color="dark"
                        variant="filled"
                        leftIcon={<TextAlignLeftIcon />}
                        onClick={() => {
                            clipboard.copy(FIREBASE_CLOUD_API + "webView?action=read_insights&document_id=" + document.id);
                            notifications.showNotification({
                                title: "Link copied to clipboard",
                            });
                        }}
                    >
                        Web Link
                    </Button>
                )}
            </Group>

            <Space h="md" />
            <Divider />
            <Space h="xs" />

            <DocumentSummaryEmbedding document={document} />

            <ScrollArea
                viewportRef={viewport}
                sx={(theme) => ({
                    paddingRight: theme.spacing.md,
                    height: screenSize === "sm" || screenSize === "xs" ? "20vh" : "calc( 100% - 20px )",
                })}
            >
                <TokensUsageInsights keypoints={keypoints} />

                {document && document.exec_summary && document.exec_summary !== "" ? (
                    <TokensUsageExectiveSummaryGPT4o documentData={document} />
                ) : (
                    <TokensUsageExectiveSummary insights={insights} />
                )}
            </ScrollArea>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        navbarStatus: state.global && state.global.navbar ? state.global.navbar : false,
    };
};

export default compose(connect(mapStateToProps))(PanelDevTools);
