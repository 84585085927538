import React, { useMemo, useState, useEffect } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { useMantineTheme, Box } from '@mantine/core';
// import { useWindowScroll } from '@mantine/hooks';
import { ReaderIcon, LightningBoltIcon, CodeIcon, Pencil2Icon } from '@radix-ui/react-icons';

import PanelNotes from './panel_notes';
// import PanelHighlights from './panel_highlights';
import PanelInsights from './panel_insights';
import PanelExecSummary from './panel_exec_summary';
import PanelDevTools from './panel_devtools';

import ButtonIconCircle from '../../components/ButtonIconCircle';

import { isDev } from '../../helpers/auth';
// import { isObjectExists } from '../../helpers/validation';
import { useScreenSize } from '../../redux/global/hooks';

import { DEV_USERS } from '../../constants';

const SidePanel = ({
    editorRef = null,
    authData,
    navbarStatus,
    dispatch,
    document,
    onUpdate = () => {}
}) => {
    const theme = useMantineTheme();
    const [ screenSize ] = useScreenSize();
    const [ activeTab, setActiveTab ] = useState('insights');

    useEffect(() => {
        if ( screenSize === 'xs' || screenSize === 'sm' ) {
            setActiveTab('');
        } else {
            setActiveTab('insights');
        }
    }, [ screenSize ] );

    const mobileMode = useMemo(() => {
        return screenSize === 'xs' || screenSize === 'sm' ? true : false;
    },[ screenSize ]);

    const links = useMemo(() => {
        let list = [
            {
                id: 'insights',
                label: 'Insights',
                icon: <LightningBoltIcon />
            },
            // {
            //     id: 'highlights',
            //     label: 'Highlights',
            //     icon: <BlendingModeIcon />
            // },
            {
                id: 'exec_summary',
                label: 'Executive Summary',
                icon: <Pencil2Icon />
            },
            {
                id: 'notes',
                label: 'Notes',
                icon: <ReaderIcon />
            },
        ];
        if ( isDev( authData ) ) {
            list.push({
                id: 'devtools',
                label: 'Dev Tools',
                icon: <CodeIcon />
            });
        }
        return list;
    }, [ authData ] );

    return (
    <Box 
    sx={(theme) => ({
        position: 'relative',
        padding: '0px',
        height: '100%',
        borderTop: ( mobileMode && activeTab !== '' ? '1px' : '0px' ) + ' solid ' +theme.colors.gray[3]
    })}>
        <Box
            sx={(theme) => ({
                height: '100%',
                padding: theme.spacing.md,
                marginRight: ( mobileMode ? '0px' : '51px' ),
                marginBottom: ( mobileMode ? '70px' : '0px' ),
                background: theme.colors.gray[0],
                display: mobileMode ? ( activeTab !== '' ? 'block' : 'none' ) : 'block',
            })}>
            <Box sx={() => ({ display: ( activeTab === 'insights' ? 'block' : 'none' ) })}>
                <PanelInsights
                    editorRef={editorRef}
                    document={document}
                    onUpdate={onUpdate}
                    />
            </Box>
            {/* <Box sx={() => ({ display: ( activeTab === 'highlights' ? 'block' : 'none' ) })}>
                <PanelHighlights
                    editorRef={editorRef}
                    document={document}
                    />
            </Box> */}
            <Box sx={() => ({ display: ( activeTab === 'exec_summary' ? 'block' : 'none' ) })}>
                <PanelExecSummary
                    editorRef={editorRef}
                    document={document}
                    onUpdate={onUpdate} />
            </Box>
            <Box sx={() => ({ display: ( activeTab === 'notes' ? 'block' : 'none' ) })}>
                <PanelNotes
                    editorRef={editorRef}
                    document={document}
                    onUpdate={onUpdate} />
            </Box>
            { isDev( authData ) && (
            <Box sx={() => ({ display: ( activeTab === 'devtools' ? 'block' : 'none' ) })}>
                <PanelDevTools
                    editorRef={editorRef}
                    document={document}
                    onUpdate={onUpdate} />
            </Box>
            ) }
        </Box>
        <Box
            sx={(theme) => ({
                position: 'absolute',
                top: ( mobileMode ? 'auto' : 0 ),
                right: ( mobileMode ? 'auto' : '1px' ),
                bottom: ( mobileMode ? 0 : 'auto' ),
                zIndex: 5,
                width: ( mobileMode ? '100%' : '50px' ),
                paddingBottom: ( mobileMode ? '20px' : '0px' ),
                background: theme.colors.gray[2],
                borderTop: ( mobileMode ? '1px' : '0px' ) + ' solid ' +theme.colors.gray[3],
                textAlign: 'center'
            })}>
                {links.map(link => (
                    <ButtonIconCircle
                        key={link.id}
                        label={link.label}
                        icon={link.icon}
                        position="left"
                        btnProps={{
                            variant: "subtle",
                            color: 'dark',
                            radius: '0px',
                            fullWidth: true
                        }}
                        btnSx={{
                            width: ( mobileMode ? '50px' : '100%' ),
                            padding: '0px',
                            textAlign: 'center',
                            background: ( link.id === activeTab ) ? theme.colors.gray[0] : 'transparent',
                            '&:hover': {
                                background: ( link.id === activeTab ) ? theme.colors.gray[0] : theme.colors.indigo[1],
                            }
                        }}
                        toolTipSx={{
                            display: ( mobileMode ? 'inline-block' : 'block' )
                        }}
                        color={( link.id === activeTab ) ? theme.colors.gray[0] : theme.colors.indigo[1]}
                        onClick={() => {
                            if ( mobileMode ) {
                                if ( link.id === activeTab ) {
                                    setActiveTab('');
                                } else {
                                    setActiveTab(link.id);
                                }
                            } else {
                                setActiveTab(link.id);
                            }
                        }} />
                ))}
        </Box>
    </Box>
    );
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        navbarStatus: ( state.global && state.global.navbar ) ? state.global.navbar : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(SidePanel);