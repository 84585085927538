import { useState, useEffect } from 'react';
// import { useDidUpdate } from '@mantine/hooks';
import { useSelector, useDispatch } from "react-redux";
import { getDatabase, ref, onValue, off } from "firebase/database";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";

import { 
    GET_FOLDERS,
    GET_FOLDER
} from './types';

import { resetFolders, resetFolder } from './actions';
import { isArrayExists, isObjectExists } from '../../../helpers/validation';
// import { getUserActiveTeam } from '../../../helpers/auth';

export const useFolders = () => {
    const db = getDatabase();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [ loaded, setLoaded ] = useState(false);
    const [ list, setList ] = useState([]);

    useEffect(() => {
        let Ref;
        setLoaded(false);
        setList([]);
        if ( user && user.uid ) {
            Ref = ref(db, 'folders/' + user.uid );
            onValue(Ref, (snapshot) => {
                let list = [];
                if ( snapshot.exists() && snapshot.hasChildren() ) {
                    snapshot.forEach((childSnapshot) => {
                        let item = childSnapshot.val();
                        item.id = childSnapshot.key;
                        list.push(item);
                    });
                }
                // sort list by name
                if ( isArrayExists( list ) ) {
                    list = list.sort((a, b) => {
                        const aname = ( a.name ? (a.name.toLowerCase()).trim() : '' );
                        const bname = ( b.name ? (b.name.toLowerCase()).trim() : '' );
                        return (aname).localeCompare(bname);
                    })
                } // end - list
    
                dispatch({
                    type: GET_FOLDERS,
                    payload: { list }
                });

                setList(list);
                setLoaded(true);
            });
        }
        return () => {
            off(Ref);
            dispatch(resetFolders());
        }
    },[ user ]);

    return [ loaded, list ];
}

export const useFolder = ( folder_id = '' ) => {
    const db = getFirestore();
    const dispatch = useDispatch();
    const [ loaded, setLoaded ] = useState(false);
    const [ item, setItem ] = useState({});

    useEffect(() => {
        let unsubscribe;
        setLoaded(false);
        setItem({});
        onSnapshot(doc(db, "folders", folder_id), (doc) => {
            const folder = ( doc.exists ? doc.data() : {} );
            dispatch({
                type: GET_FOLDER,
                payload: { folder }
            });
            setItem(folder);
            setLoaded(true);
        });
        return () => {
            if ( unsubscribe ) {
                unsubscribe();
            }
            dispatch(resetFolder());
        }
    },[ folder_id ]);

    return [ loaded, item ];
}