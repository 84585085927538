import React, { useMemo, useEffect } from 'react';
import { connect } from "react-redux";
import { AppShell, Box, Divider } from '@mantine/core';

import NavBar from './navbar';
import Header from './header';
import Footer from './footer';

import UserOnboarding from '../UserOnboarding';

import { useFolders } from '../../pages/Folders/redux/hooks';
import { useCredits } from '../../pages/Credits/redux/hooks';
import { useUserOnboarding } from '../UserOnboarding/redux/hooks';
import { useScreenSize } from '../../redux/global/hooks';
import { shrinkNavbar } from '../../redux/global/actions';

const MainAppShell = ({
    authData,
    dispatch,
    fullWidth = false,
    navbarStatus,
    showNavBar = true,
    showHeader = false,
    showFooter = true,
    backgroundColor = null,
    children = null,
}) => {
    const [ screenSize ] = useScreenSize();

    // run core hooks
    useFolders();
    useCredits();
    useUserOnboarding();

    const mobileMode = useMemo(() => {
        return screenSize === 'sm' || screenSize === 'xs' ? true : false;
    },[screenSize]);

    useEffect(() => {
        if ( mobileMode ) {
            // hide navbar
            if ( navbarStatus ) {
                dispatch(shrinkNavbar());
            }
        }
    },[ mobileMode ]);

    const navbarBaseWidth = useMemo(() => {
        return mobileMode ? 0 : ( navbarStatus ? 300 : 60 );
    }, [ navbarStatus, mobileMode ]);

    return (
    <AppShell
        // p={ fullWidth ? 'md' : "xl" }
        navbar={ !mobileMode && <NavBar />}
        header={ mobileMode ? <Header /> : null }
        // zIndex={300}
        styles={(theme) => ({
            main: {
                marginLeft: navbarBaseWidth+'px', 
            },
            body: {
                background: backgroundColor ? backgroundColor : ( theme.colorScheme === 'dark' ? theme.colors.dark[8] : '#fff' ),
            }
        })}>
        <Box sx={(theme) => ({
            padding: fullWidth ? '0px' : theme.spacing.md,
            minHeight: '100vh'
        })}>
            { children }
        </Box>
        { showFooter ? (
        <>
            <Divider />
            <Footer authData={authData} />
        </>
        ) : null }
        <UserOnboarding />
    </AppShell>
    )
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        navbarStatus: ( state.global && state.global.navbar ) ? state.global.navbar : false,
    };
};

export default connect(mapStateToProps)(MainAppShell);