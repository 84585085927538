import { useState, useEffect } from 'react';
// import { useDidUpdate } from '@mantine/hooks';
import { useSelector, useDispatch } from "react-redux";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, query, onValue, off } from "firebase/database";
// import { getFirestore, doc, onSnapshot } from "firebase/firestore";

import { 
    USER_ONBOARDING_GET
} from './types';

// import { callFunctionsAPI } from '../../../helpers/api';
// import { isArrayExists, isObjectExists } from '../../../helpers/validation';
// import { getUserActiveTeam } from '../../../helpers/auth';

export const useUserOnboarding = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const dispatch = useDispatch();
    const db = getDatabase();
    const [ loaded, setLoaded ] = useState(false);
    const [ onboarding, setOnboarding ] = useState({});

    useEffect(() => {
        let Ref;
        if ( user ) {
            const uid = user && user.uid ? user.uid : 'nouid';
            Ref = query( ref(db, 'users/' + uid + '/onboarding' ) );
            onValue(Ref, (snapshot) => {
                const val = ( snapshot.exists() ? snapshot.val() : {} );
                setOnboarding( val );
                setLoaded(true);
                dispatch({ type: USER_ONBOARDING_GET, payload: { onboarding: val } });
            });
        }
        return () => {
            setLoaded(false);
            if ( Ref )
                off( Ref );
        };
    },[ user ]);

    return [ loaded, onboarding ];
}