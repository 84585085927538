import { useState, useEffect } from 'react';
// import { useDidUpdate } from '@mantine/hooks';
import { useSelector, useDispatch } from "react-redux";
import { getDatabase, ref, onValue, off, orderByChild, equalTo, query } from "firebase/database";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";

import { 
    GET_DOCUMENTS,
    GET_DOCUMENT,
} from './types';

import { resetDocuments, resetDocument } from './actions';
import { isArrayExists } from '../../../helpers/validation';
// import { getUserActiveTeam } from '../../../helpers/auth';

export const useDocuments = ( folder_id = 'nofolderid' ) => {
    const db = getDatabase();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [ loaded, setLoaded ] = useState(false);
    const [ list, setList ] = useState([]);

    useEffect(() => {
        const Ref = query( ref(db, 'documents/' + user.uid ), orderByChild("folder_id"), equalTo(folder_id) );
        onValue(Ref, (snapshot) => {
            let list = [];
            if ( snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach((childSnapshot) => {
                    let item = childSnapshot.val();
                    item.id = childSnapshot.key;
                    list.push(item);
                });
            }
            
            dispatch({
                type: GET_DOCUMENTS,
                payload: { list }
            });
            setList(list);
            setLoaded(true);
        });
        return () => {
            setLoaded(false);
            setList([]);
            off(Ref);
            dispatch(resetDocuments());
        }
    },[ user, folder_id ]);

    return [ loaded, list ];
}

export const useDocument = (id = 'noid') => {
    const db = getFirestore();
    const dispatch = useDispatch();
    const [ loaded, setLoaded ] = useState(false);
    const [ item, setItem ] = useState({});

    useEffect(() => {
        let unsubscribe;
        setLoaded(false);
        setItem({});
        unsubscribe = onSnapshot(doc(db, "documents", id), (docData) => {
            const document = ( docData.exists ? docData.data() : {} );
            dispatch({
                type: GET_DOCUMENT,
                payload: { document }
            });
            setItem(document);
            setLoaded(true);
        });
        return () => {
            if ( unsubscribe ) {
                unsubscribe();
            }
            dispatch(resetDocument());
        }
    },[ id ]);

    return [ loaded, item ];
}