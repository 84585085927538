import React, { useMemo } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { Text } from '@mantine/core';

const DocumentTour = ({
    id = '',
    defaultStyles = {},
    defaultLocale = {},
    defaultButtonStyles = {},
    onSkip = () => {}
}) => {

    const steps = useMemo(() => {
        return [
            {
                target: '#document-generate-insights',
                // target: '#document-name',
                placement: 'bottom',
                content: (
                <>
                    <Text size="sm" align='left' mb={"0px"} pb="0px">Click on the <strong>"Generate"</strong> button to generate insights for this document. The total tokens you need to generate insights will be displayed beneath the button.</Text>
                </>
                )
            }
        ]
    },[]);

    const handleCallback = (data) => {
        const { status } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            onSkip(id);
        }
    }
    
    return (
    <>

        <Joyride
            steps={steps}
            run={true}
            disableOverlay={false}
            disableCloseOnEsc
            disableOverlayClose={false}
            hideCloseButton={false}
            showSkipButton={false}
            showProgress={false}
            spotlightPadding={20}
            continuous
            locale={{
                ...defaultLocale,
                skip: 'Skip',
                last: 'Close'
            }}
            callback={handleCallback}
            styles={{
                options: {
                    ...defaultStyles,
                    width: 400,
                    zIndex: 100000
                },
                buttonNext: {
                    ...defaultButtonStyles,
                    padding: '10px 25px',
                    fontWeight: 'bold'
                },
                buttonBack: {
                    ...defaultButtonStyles
                },
                buttonClose: {
                    ...defaultButtonStyles
                },
                buttonSkip: {
                    ...defaultButtonStyles
                },
            }}
            />
    
    </>
    )
}

export default DocumentTour;