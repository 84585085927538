import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
// import { useNavigate } from "react-router-dom";
import { Container, Card, Skeleton, Title, Space, Box, Text, Group, Button, Tooltip, LoadingOverlay, TextInput } from '@mantine/core';
import { ExclamationTriangleIcon, CopyIcon, PlusCircledIcon } from '@radix-ui/react-icons';
import { useClipboard } from '@mantine/hooks';
import { useNotifications } from '@mantine/notifications';

import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/api';


const Credits = () => {
    const notifications = useNotifications();
    const authData = useSelector( state => state.auth.user );
    const [ token, setToken ] = useState('');
    const [ expireDate, setExpireDate ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const clipboard = useClipboard({ timeout: 500 });

    const handleTokenGeneration = async () => {
        try {
            setLoading(true);
            const data = await callFunctionsAPI({
                url: 'api',
                action: 'get_token'
            });
            setLoading(false);
            setToken(data.token || '');
            setExpireDate(data.expiresIn || '');
        } catch(error) {
            setLoading(false);
            notifications.showNotification({
                title: 'Error',
                message: getAPIErrorMessage(error),
                color: 'red',
                icon: <ExclamationTriangleIcon />
            });
        }
    }

    return (
    <Container size="xs">
        <Space h="xl" />
        <Card
            p="md"
            withBorder
            shadow={"lg"}
            sx={(theme) => ({
                // background: theme.colors.indigo[5]
                border: '2px solid ' + theme.colors.gray[8]
            })}
            >
            <LoadingOverlay visible={loading} />
            <Title align='center' order={2} sx={(theme) => ({
                // color: theme.colors.gray[0]
            })}>API Token</Title>
            <Space h="md" />
            <TextInput 
                label={ expireDate && expireDate !== '' ? `Expired on: ${expireDate}` : '' }
                type='password'
                value={token}
                disabled={true}
                rightSection={
                    <Button
                        variant='light'
                        color="gray"
                        onClick={() => {
                            clipboard.copy(token);
                            notifications.showNotification({
                                title: 'Copied',
                                message: 'API Token has been copied to clipboard.',
                                color: 'teal',
                                icon: <PlusCircledIcon />
                            });
                        }}
                        >
                        <CopyIcon />
                    </Button>
                }
                />
            <Space h="md" />
            <Group position='center'>
                <Button
                    variant='light'
                    color="indigo"
                    onClick={handleTokenGeneration}>
                    Generate Token
                </Button>
            </Group>

        </Card>
        <Space h="xl" />
        <Space h="xl" />
    </Container>
    )
}

export default Credits;