import { getAuth } from "firebase/auth";

import { doPromise } from '../../helpers/api';

// get current user
export const getCurrentUser = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    return ( user ? user : false );
}

// get current user ID token
export const getIDToken = function(authHeader) {
    return new Promise((resolve,reject) => {

        const user = getCurrentUser();

        if ( user ) {
            user.getIdToken(true)
            .then(function(idToken) {
                resolve(( authHeader ? 'Bearer ' + idToken : idToken ));
            })
            .catch(function(error) {
                reject(error);
            });
        } else {
            reject({ code: 'user_not_found', message: 'User Not Found.' })
        }

    });
}

// get user data via onAuth
export const onAuth = () => {
    return new Promise((resolve, reject) => {
        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'onauth',
                params: [{ key: 'action', value: 'get' }]
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

// perform logout
export const logoutUser = (ignoreError = true) => {
    return new Promise((resolve,reject) => {

        const auth = getAuth();
        auth.signOut().then(function() {
            resolve('logged_out');
        }).catch(function(error) {
            if ( ignoreError ) {
                resolve(true);
            } else {
                reject(error);
            }
        });
          
    });
}