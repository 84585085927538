import { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    useMantineTheme,
    Box,
    Text,
    Button,
    LoadingOverlay,
    Alert,
    Card,
    Skeleton,
    Group,
    Space,
    Badge,
    Loader,
    Tooltip,
    Avatar,
    Title,
} from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { ReloadIcon, LightningBoltIcon, CopyIcon, CheckIcon, PlusCircledIcon, ExclamationTriangleIcon, ResetIcon } from "@radix-ui/react-icons";
import { useNotifications } from "@mantine/notifications";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import TurndownService from "turndown";
import { encode } from "gpt-tokenizer";

import LoaderComp from "../../components/Loader";
import ButtonIconCircle from "../../components/ButtonIconCircle";

import { isArrayExists } from "../../helpers/validation";
import { getFormattedCredits } from "../../helpers/number";
import { getAPIErrorMessage, callFunctionsAPI } from "../../helpers/api";
import { getKeypointsFromInsights } from "../../helpers/insights";

const turndownService = new TurndownService();

const ExecutiveSummary = ({ authData, viewport = null, documentData }) => {
    const { insights, rand: insightsRand } = useSelector((state) => state.insights);
    const dispatch = useDispatch();
    const theme = useMantineTheme();
    const notifications = useNotifications();
    const clipboard = useClipboard({ timeout: 500 });
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (insightsRand) {
            setLoaded(true);
        }
    }, [insightsRand]);

    // const canGenerateSummary = useMemo(() => {
    //     return insights && insights.length > 0 ? true : false;
    // }, [insights]);

    const processing = useMemo(() => {
        return false;
    }, []);

    const summaries = useMemo(() => {
        let summaries = false;
        if (documentData && documentData.exec_summary && documentData.exec_summary !== "") {
            summaries = documentData.exec_summary;
        } else if (insights && insights.length > 0) {
            summaries = insights
                .map((insight) => {
                    return insight.summary || "";
                })
                .join("\n\n");
        }
        return summaries;
    }, [insights, documentData]);

    // const keypoints = useMemo(() => {
    //     return getKeypointsFromInsights({
    //         insights: insights && insights.length > 0 ? insights[0] : {},
    //         document: documentData,
    //         format: "text",
    //     });
    // }, [insights, documentData]);

    const contents = useMemo(() => {
        return documentData && documentData.content ? turndownService.turndown(documentData.content) : "";
    }, [documentData]);

    // based on keypoints
    // const estimatedCosts = useMemo(() => {
    //     const tokens = encode(keypoints).length + 74;
    //     return {
    //         tokens: tokens + 4000,
    //         costs: (tokens / 1000) * 0.005 + 0.015 * 4,
    //     };
    // }, [keypoints]);

    // based on contents
    const estimatedCosts = useMemo(() => {
        const tokens = encode(contents).length + 400;
        return {
            tokens: tokens + 4000,
            costs: (tokens / 1000) * 0.005 + 0.015 * 4,
        };
    }, [contents]);

    const handleGenerate = () => {
        setLoading(true);
        callFunctionsAPI({
            url: "executiveSummary",
            action: "generate",
            formData: {
                id: documentData.id,
            },
        })
            .then(() => {
                setLoading(false);
                notifications.showNotification({
                    title: "Summary Generated",
                    message: "Summary has been generated successfully.",
                    color: "teal",
                    icon: <CheckIcon />,
                });
            })
            .catch((error) => {
                setLoading(false);
                notifications.showNotification({
                    title: "Error",
                    message: getAPIErrorMessage(error),
                    color: "red",
                    icon: <ExclamationTriangleIcon />,
                });
            });
    };

    return loaded ? (
        <Box
            sx={(theme) => ({
                position: "relative",
                paddingTop: "0px",
            })}
        >
            <LoadingOverlay
                visible={loading}
                sx={() => ({
                    alignItems: "flex-start",
                    paddingTop: "60px",
                })}
            />

            {summaries ? (
                <Box
                    sx={(theme) => ({
                        marginTop: "0px",
                        "& > .mantine-Card-root": {
                            marginBottom: "10px",
                            "& .mantine-Text-root": {
                                marginBottom: "5px",
                                "&:last-child": {
                                    marginBottom: "0px",
                                },
                            },
                            "&:last-child": {
                                marginBottom: "0px",
                            },
                        },
                    })}
                >
                    <Group my="sm" position="apart">
                        <Group position="left">
                            <ButtonIconCircle
                                icon={<ReloadIcon />}
                                label="Re-generate"
                                color={theme.colors.indigo[5]}
                                btnProps={{
                                    variant: "light",
                                    color: "indigo",
                                    radius: "md",
                                    size: "xs",
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleGenerate();
                                }}
                            />
                        </Group>
                        <Group position="right">
                            <ButtonIconCircle
                                icon={<CopyIcon />}
                                label={clipboard.copied ? "Summary Copied" : "Copy Summary"}
                                useClipboard
                                color={clipboard.copied ? theme.colors.indigo[9] : theme.colors.indigo[5]}
                                tooltipProps={{
                                    id: `summary-copy`,
                                }}
                                btnProps={{
                                    variant: "light",
                                    color: "indigo",
                                    radius: "md",
                                    size: "xs",
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    clipboard.copy(summaries);
                                }}
                            />
                        </Group>
                    </Group>
                    <Card withBorder p="md">
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>{summaries}</ReactMarkdown>
                    </Card>
                </Box>
            ) : (
                <Text size="md" align="center" p="md">
                    No executive summary generated yet.
                </Text>
            )}

            <Box
                id="document-generate-insights"
                sx={(theme) => ({
                    paddingTop: "20px",
                    paddingBottom: "40px",
                })}
            >
                <Button
                    fullWidth
                    color="indigo"
                    size="md"
                    radius="xl"
                    leftIcon={summaries ? <ReloadIcon /> : <LightningBoltIcon />}
                    disabled={processing ? true : false}
                    loading={processing}
                    onClick={handleGenerate}
                >
                    {processing ? "Processing" : summaries ? "Re-generate All" : "Generate"}
                </Button>
                <Alert title="Estimate Costs & Tokens" color="blue" shadow="sm" radius="sm" mt="sm">
                    <Text size="sm">
                        Tokens: {estimatedCosts.tokens} ({Math.round((estimatedCosts.costs + Number.EPSILON) * 1000) / 1000})
                    </Text>
                </Alert>
            </Box>
        </Box>
    ) : (
        <LoaderComp />
    );
};

export default ExecutiveSummary;
