import shortid from "shortid";
import { getStorage, ref, uploadBytes } from "firebase/storage";

import { isArrayExists } from '../../helpers/validation';
import { callFunctionsAPI } from '../../helpers/api';

export const extractFile = (file,authData) => {
    return new Promise((resolve, reject) => {

        const storage = getStorage();
        // turn the file name to snake case
        const file_name = file.name.toLowerCase().replace(/\s/g, "_");
        // re-enable this when ready
        // const epub_id = shortid.generate() + '_' + file_name;
        const file_id = file_name;
        const storageRef = ref(storage, 'users_files/'+authData.uid+'/'+file_id );

        uploadBytes( storageRef, file )
        .then(snapshot => {
            return callFunctionsAPI({
                url: 'files',
                // action: 'extract_' + ( file.type === 'application/epub+zip' || ( file.name && file_name.endsWith('.epub') ) ? 'epub' : 'pdf' ),
                action: 'extract_epub',
                formData: { id: file_id }
            }) 
        })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    });    
}