import React, { useState, useMemo } from 'react';
import { Button, Tooltip } from '@mantine/core';

const ButtonIconCircle = ({
    label = '',
    btnProps = {},
    btnSx = {},
    toolTipSx = {},
    tooltipProps = {},
    icon = null,
    position = 'top',
    color = false,
    useClipboard = false,
    closeDelay = 0,
    onClick = () => {}
}) => {
    const [ opened, setOpened ] = useState(false);

    const iconBtnProps = useMemo(() => {
        return {
            variant: 'outline',
            color: "dark",
            radius: "100%",
            size: "sm",
            ...btnProps
        };
    }, [ btnProps ]);

    const handleClick = (e) => {
        if ( !useClipboard )
            setOpened(false);
        
        onClick(e);
    }

    const handleMouseLeave = () => {
        if ( closeDelay ) {
            setTimeout(() => {
                setOpened(false);
            }, closeDelay);
        } else {
            setOpened(false);
        }   
    }

    return (
    <Tooltip
        opened={opened}
        position={position}
        label={label}
        withArrow
        zIndex={200}
        closeDelay={closeDelay}
        sx={() => ({
            ...toolTipSx
        })}
        {...tooltipProps}
        >
        <Button
            aria-describedby="tooltip-id"
            // onFocus={() => setOpened(true)}
            onMouseEnter={() => setOpened(true)}
            // onBlur={() => setOpened(false)}
            onMouseLeave={handleMouseLeave}
            {...iconBtnProps}
            sx={(theme) => ({
                padding: '0px',
                width: '36px',
                '&:hover': {
                    backgroundColor: color || theme.colors.brand[0],
                    color: theme.colors.gray[0],
                    border: 'none'
                },
                ...btnSx
            })}
            onClick={handleClick}>
            {icon}
        </Button>
    </Tooltip>
    )
}

export default ButtonIconCircle;