import React, { useState, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { Box, Textarea, Text, Avatar, Group, Space, Divider, Button, SegmentedControl } from '@mantine/core';
// import { useHotkeys } from '@mantine/hooks';
// import { useNotifications } from '@mantine/notifications';
import { ReaderIcon } from '@radix-ui/react-icons';

// import AIModelSelector from '../../modules/AIModelSelector';

// import { toggleGlobalDisabled } from '../../redux/global/actions';
import { useScreenSize } from '../../redux/global/hooks';


const PanelNotes = ({
    editorRef = null,
    authData,
    navbarStatus,
    dispatch,
    document,
    onUpdate = () => {}
}) => {
    const [ screenSize ] = useScreenSize();

    const mobileMode = useMemo(() => {
        return screenSize === 'xs' || screenSize === 'sm' ? true : false;
    },[ screenSize ]);

    return (
    <Box sx={(theme) => ({
        padding: '0px'
    })}>
        <Group position='left' spacing={"xs"}>
            <Avatar size={ mobileMode ?  "sm" : "md" } radius={"md"} sx={(theme) => ({
                '& .mantine-Avatar-placeholder': {
                    backgroundColor: theme.colors.indigo[5],
                }
            })}><ReaderIcon size={mobileMode ? 16 : 24} color="#fff" /></Avatar>
            <Group>
                <div>
                    <Text size={ mobileMode ?  "sm" : "md" } weight='700'>Notes</Text>
                    { mobileMode ? null : <Text size="xs" color="gray">For capturing insights, quick thought, or ideas.</Text> }
                </div>
            </Group>
        </Group>
        <Space h="xs" />
        <Textarea
            value={( document && document.notes || '' )}
            // minRows={35}
            // maxRows={35}
            sx={(theme) => ({
                '& .mantine-Textarea-input': {
                    height: ( screenSize === 'sm' || screenSize === 'xs' ? 'calc( 50vh - 100px )' : 'calc( 100vh - 120px )' )
                }
            })}
            onChange={(e) => {
                onUpdate({ ...document, notes: e.target.value });
            }}
            />
    </Box>
    );
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        navbarStatus: ( state.global && state.global.navbar ) ? state.global.navbar : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(PanelNotes);