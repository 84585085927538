
import { 
    IS_LOGGED_IN,
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_GET_PROFILE_IMAGE,
	AUTH_GET_PROFILE,
	// AUTH_EDIT_PROFILE
} from './types';

const initialState = {
    user: null,
    team: null,
    profile_image: null,
    profile: null,
    // updated: false,
    // rand: false
};

export const auth = (state = initialState, action) => {
    const { type, payload = null } = action;
    switch (type) {
        case IS_LOGGED_IN:
            return {
                ...state,
                user: payload.user,
                team: payload.team || false,
                rand: Math.floor(Math.random() * 1000000)
            };
        case AUTH_LOGIN:
                return {
                    ...state,
                    user: payload.user,
                    team: payload.team || false,
                    rand: Math.floor(Math.random() * 1000000)
                };
        case AUTH_LOGOUT:
            return {
                ...state,
                user: null,
                team: null,
                rand: Math.floor(Math.random() * 1000000)
            };
        case AUTH_GET_PROFILE_IMAGE:
            return {
                ...state,
                profile_image: ( payload || false )
            };
        case AUTH_GET_PROFILE:
            return {
                ...state,
                profile: ( payload.profile || false ),
                // rand: Math.floor(Math.random() * 1000000)
            };
        // case AUTH_EDIT_PROFILE:
        //     var randNum = Math.floor(Math.random() * 1000000);
        //     return {
        //         ...state,
        //         updated: randNum,
        //         rand: randNum
        //     };
        default:
            return state;
    }
}