import { useState, useEffect } from 'react';
// import { useDidUpdate } from '@mantine/hooks';
import { useSelector, useDispatch } from "react-redux";
import { getFirestore, onSnapshot, query, where, orderBy, collection, limit } from "firebase/firestore";

import { getInsightKeypoints, getInsightContentBlocks } from '../helpers';
import { isArrayExists } from '../../../helpers/validation';
import { tiptapHTMLToItems, getCustomExtensionsFromDoc } from '../../../helpers/tiptap';

import { 
    GET_INSIGHTS,
    SELECT_INSIGHT,
    RESET_SELECT_INSIGHT,
    INSIGHT_SELECTED,
    RESET_INSIGHT_SELECTED,
    INSIGHT_CLICKED,
    RESET_INSIGHT_CLICKED
} from './types';

import { resetInsights } from './actions';
// import { isArrayExists } from '../../../helpers/validation';

export const useInsights = ( document_id = '' ) => {
    const db = getFirestore();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [ loaded, setLoaded ] = useState(false);
    const [ list, setList ] = useState([]);

    useEffect(() => {
        let unsubscribe;
        const q = query( 
                collection(db, "documents", document_id, "insights"), 
                // where( 'access_ids', 'array-contains-any', [ user.email ] ), 
                orderBy('modified_on','desc'), 
                limit(1)
            );
        unsubscribe = onSnapshot(q, (snapshot) => {
            let list = [];
            if ( snapshot ) {
                snapshot.forEach((doc) => {
                    list.push(doc.data());
                });
            }
            dispatch({
                type: GET_INSIGHTS,
                payload: { list }
            });
            setList(list);
            setLoaded(true);
        });
        return () => {
            setLoaded(false);
            setList([]);

            if ( unsubscribe )
                unsubscribe();

            dispatch(resetInsights());
        }
    },[ user, document_id ]);

    return [ loaded, list ];
}

export const useInsightSelected = () => {
    const dispatch = useDispatch();
    const { blocks_selected } = useSelector((state) => state.insight_selected);

    const doInsightSelected = (selected) => {
        dispatch({
            type: INSIGHT_SELECTED,
            payload: selected
        });
    }
    const resetInsightSelected = () => {
        dispatch({
            type: RESET_INSIGHT_SELECTED
        });
    }

    return [ blocks_selected, doInsightSelected, resetInsightSelected ];
}

export const useInsightClicked = () => {
    const dispatch = useDispatch();
    const { insight_clicked } = useSelector((state) => state.insight_clicked);

    const doInsightClicked = (selected) => {
        dispatch({
            type: INSIGHT_CLICKED,
            payload: selected
        });
    }

    const resetInsightClicked = () => {
        dispatch({
            type: RESET_INSIGHT_CLICKED
        });
    }

    return [ insight_clicked, doInsightClicked, resetInsightClicked ];
}

export const useSelectInsight = () => {
    const dispatch = useDispatch();
    const { select_insight } = useSelector((state) => state.select_insight);

    const doSelectInsight = (selected) => {
        dispatch({
            type: SELECT_INSIGHT,
            payload: selected
        });
    }

    const resetSelectInsight = () => {
        dispatch({
            type: RESET_SELECT_INSIGHT
        });
    }

    return [ select_insight, doSelectInsight, resetSelectInsight ];
}

export const useInsightsData = () => {
    const { insights } = useSelector((state) => state.insights);
    const { document } = useSelector((state) => state.documents);
    const [ list, setList ] = useState([]);

    useEffect(() => {
        if ( insights && document ) {
            const keypoints = getInsightKeypoints(insights);
            const contents = tiptapHTMLToItems( document.content || '', getCustomExtensionsFromDoc( document ) );
            let insightsData = [];
            if ( keypoints && isArrayExists( keypoints ) ) {
                keypoints.forEach((keypoint) => {
                    insightsData.push({
                        ...keypoint,
                        id: keypoint.id,
                        content_blocks: getInsightContentBlocks( contents, keypoint )
                    });
                });
            }
            setList(insightsData);
        }
    },[ insights, document ]);
    return list;
}