import React, { useState, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { useMantineTheme, Container, Title, Space, Group, Text, Badge } from '@mantine/core';
import { CheckIcon } from '@radix-ui/react-icons';
import { Dropzone, DropzoneStatus } from '@mantine/dropzone';
import { useNotifications } from '@mantine/notifications';

import { extractFile } from './api';
import EpubContent from './contents';

import { isArrayExists } from '../../helpers/validation';
import { getAPIErrorMessage } from '../../helpers/api';

import { shrinkNavbar, expandNavbar, appChangesMade, appChangesReset } from '../../redux/global/actions';

import { PLATFORM_URL } from '../../constants';

const UploadEpub = ({
    authData,
    navbarStatus,
    dispatch,
    changesMade
}) => {
    const theme = useMantineTheme();
    const notifications = useNotifications();
    const [ contents, setContents ] = useState([]);
    const [ uploading, setUploading ] = useState(false);

    const handleDocCreated = ({ new_document_id, folder_id, name }) => {
        notifications.clean();
        notifications.showNotification({
            color: 'teal',
            icon: <CheckIcon />,
            autoClose: 4500,
            title: (
            <>
                New document created: <a href={`${PLATFORM_URL}folders/${folder_id}/docs/${new_document_id}`} target="_blank"><strong><u>{name || ''}</u></strong></a>
            </>
            ),
        }) 
    }

    const handleReset = () => {
        setContents([])
        dispatch(appChangesReset());
        if ( !navbarStatus ) {
            dispatch(expandNavbar());
        }
    }

    const handleRejected = (files) => {
        notifications.showNotification({
            color: 'red',
            title: 'Please select a valid epub file',
        }) 
    }

    const handleDrop = ( files ) => {
        if ( files && files[0] ) {
            setUploading(true);
            extractFile( files[0], authData )
            .then((data) => {
                setUploading(false);
                setContents(data);
                // console.log(data);
                dispatch(appChangesMade());
                // shrink navbar if needed
                if ( navbarStatus ) {
                    dispatch(shrinkNavbar());
                }
            })
            .catch(error => {
                setUploading(false);
                notifications.showNotification({
                    color: 'red',
                    title: getAPIErrorMessage(error),
                });
            });
        } else {
            notifications.showNotification({
                color: 'red',
                title: 'Invalid file uploaded',
            });
        }
    }

    const dropzoneChildren = (status, theme) => (
        <Group position="center" spacing="xl" style={{ minHeight: 220, pointerEvents: 'none' }}>
          <div>
            <Text size="xl" inline>
              Drag EPUB file here or click to select an EPUB file
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
                Your EPUB file should not exceed 50mb
            </Text>
          </div>
        </Group>
    );

    return contents && isArrayExists(contents) ? (
        <EpubContent
            contents={contents}
            onUpdate={(newValue) => setContents(newValue)}
            onReset={handleReset}
            onDocCreated={handleDocCreated} />
        ) : (
        <Container
            size={"md"}
            >
            <Title order={2}>Upload EPUB <Badge color="orange" style={{ verticalAlign: 'text-top' }}>ALPHA</Badge></Title>
            <Space h="xl" />
            <Dropzone
                loading={uploading}
                onDrop={handleDrop}
                onReject={handleRejected}
                // maxSize={3 * 1024 ** 2}
                // accept={'.epub,.pdf'}
                accept={'.epub'}
                >
                {(status) => dropzoneChildren(status, theme)}
                </Dropzone>
        </Container>
        );
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        navbarStatus: ( state.global && state.global.navbar ) ? state.global.navbar : false,
        changesMade: ( state.global && state.global.changes_made ) ? state.global.changes_made : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(UploadEpub);