import React, { useEffect, useState, useRef, useMemo } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, Space, Grid, Box, Tabs } from "@mantine/core";
import { useDidUpdate, useDebouncedValue, useWindowScroll, useResizeObserver, useViewportSize } from "@mantine/hooks";
import { useNotifications } from "@mantine/notifications";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
// import { CheckIcon } from '@radix-ui/react-icons';

import TopBar from "./topbar";
import TitleBar from "./title";
import Editor from "./editor";
import Contents from "./contents";
// import ContentsEpub from './contents_epub';
import SidePanel from "./panel";

import Loader from "../../components/Loader";
import ItemNotFound from "../../components/ItemNotFound";

import { isObjectExists } from "../../helpers/validation";

import { useDocument } from "../Documents/redux/hooks";
import { useFolder } from "../Folders/redux/hooks";
import { useInsights } from "../../modules/Insights/redux/hooks";
import { shrinkNavbar, expandNavbar, appChangesMade, appChangesReset } from "../../redux/global/actions";
import { useScreenSize } from "../../redux/global/hooks";

import { callFunctionsAPI, getAPIErrorMessage } from "../../helpers/api";

const Document = ({ authData, dispatch, changesMade }) => {
    const editorRef = useRef(null);
    const [screenSize] = useScreenSize();
    const { folder_id, doc_id = "emptyid" } = useParams();
    const [folderLoaded, folder] = useFolder(folder_id);
    const [documentLoaded, document] = useDocument(doc_id);
    const [loaded, setLoaded] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [formData, setFormData] = useState({});
    const [tab, setTab] = useState("content");
    const [debouncedFormData] = useDebouncedValue(formData, 1500);
    const [scroll] = useWindowScroll();
    const [sidePanelRef, sidePanelRect] = useResizeObserver();
    const { height } = useViewportSize();
    const notifications = useNotifications();
    // trigger hook(s)
    useInsights(doc_id);

    useEffect(() => {
        dispatch(shrinkNavbar());
        return () => {
            if (screenSize !== "sm" && screenSize !== "xs") {
                dispatch(expandNavbar());
            }
        };
    }, []);

    useEffect(() => {
        if (folderLoaded && documentLoaded && !loaded) {
            setFormData({ ...document });
            setLoaded(true);
        }
        return () => {
            setLoaded(false);
            setFormData({});
        };
    }, [folderLoaded, documentLoaded]);

    useDidUpdate(() => {
        // only do update if changes made
        if (changesMade && loaded) {
            doUpdate(debouncedFormData);
        }
    }, [debouncedFormData]);

    const mobileMode = useMemo(() => {
        return screenSize === "xs" || screenSize === "sm" ? true : false;
    }, [screenSize]);

    const sidePanelScrollStyles = useMemo(() => {
        return mobileMode
            ? {
                  position: "fixed",
                  top: "auto",
                  bottom: "0px",
                  right: "0px",
                  width: "100%",
                  height: (height * 55) / 100,
                  paddingLeft: "0px",
                  boxShadow: "none",
              }
            : scroll.y > 160
            ? {
                  position: "fixed",
                  top: "0px",
                  bottom: "auto",
                  right: "17px",
                  width: sidePanelRect.width,
                  height: height - 18,
              }
            : {};
    }, [scroll, sidePanelRect, mobileMode]);

    const contentSource = useMemo(() => {
        return document.content_source &&
            (document.content_source === "epub" || document.content_source === "youtube" || document.content_source === "editor")
            ? document.content_source
            : "editor";
    }, [document]);

    const doUpdate = (debouncedFormData) => {
        setUpdating(true);
        callFunctionsAPI({
            url: "document",
            action: "update",
            formData: debouncedFormData,
        })
            .then((results) => {
                setUpdating(false);
                dispatch(appChangesReset());
            })
            .catch((err) => {
                setUpdating(false);
                notifications.showNotification({
                    color: "red",
                    title: getAPIErrorMessage(err),
                });
            });
    };

    const handleSaveChanges = () => {
        doUpdate(formData);
    };

    const handleFormUpdate = (newValue) => {
        setFormData({ ...newValue });
        dispatch(appChangesMade());
    };

    return loaded ? (
        document && isObjectExists(document) ? (
            <>
                <TopBar folder={folder} document={formData} updating={updating} onSave={handleSaveChanges} />

                <Space h="md" />

                <Card
                    p="lg"
                    shadow={"sm"}
                    radius="md"
                    sx={(theme) => ({
                        height: "100%",
                        border: "1px solid " + theme.colors.gray[3],
                        minHeight: "95vh",
                    })}
                >
                    <Card.Section>
                        <TitleBar editorRef={editorRef} document={formData} updating={updating} onUpdate={handleFormUpdate} />
                    </Card.Section>

                    <Card.Section>
                        <Grid gutter="0px">
                            {/* <Grid.Col md={12}>
                    { contentSource && contentSource === 'epub' && (
                        <>
                            <ContentsEpub
                                documentData={formData}
                                onUpdate={handleFormUpdate} />
                        </>
                        ) }
                    </Grid.Col> */}
                            <Grid.Col
                                sm={12}
                                md={8}
                                sx={(theme) => ({
                                    paddingTop: theme.spacing.lg,
                                    paddingBottom: mobileMode ? "100px" : theme.spacing.lg,
                                    paddingLeft: theme.spacing.lg,
                                    paddingRight: theme.spacing.lg,
                                })}
                            >
                                {/* { contentSource && contentSource === 'epub' && (
                        <>
                            <ContentsEpub
                                documentData={formData}
                                onUpdate={handleFormUpdate} />
                        </>
                        ) } */}

                                <Tabs>
                                    <Tabs.Tab label="content">
                                        {contentSource && contentSource === "editor" && (
                                            <>
                                                <Editor
                                                    editorRef={editorRef}
                                                    documentData={formData}
                                                    // updating={updating}
                                                    // onUpdate={handleFormUpdate}
                                                />
                                                <Contents documentData={formData} onUpdate={handleFormUpdate} />
                                            </>
                                        )}
                                    </Tabs.Tab>
                                    <Tabs.Tab label="notes">
                                        <Card withBorder p="md">
                                            <ReactMarkdown remarkPlugins={[remarkGfm]}>{document.notes || ""}</ReactMarkdown>
                                        </Card>
                                    </Tabs.Tab>
                                    <Tabs.Tab label="Executive Summary">
                                        <ReactMarkdown remarkPlugins={[remarkGfm]}>{document.exec_summary || ""}</ReactMarkdown>
                                    </Tabs.Tab>
                                </Tabs>
                            </Grid.Col>
                            <Grid.Col sm={12} md={4}>
                                <Box
                                    ref={sidePanelRef}
                                    sx={(theme) => ({
                                        height: mobileMode ? "100%" : "100%",
                                        maxHeight: mobileMode ? "100%" : "100vh",
                                        background: mobileMode ? "none" : theme.colors.gray[2],
                                        boxShadow: "inset 0 0 0 1px rgba(0,0,0,0.1)",
                                        paddingLeft: "1px",
                                        ...sidePanelScrollStyles,
                                    })}
                                >
                                    <SidePanel editorRef={editorRef} document={formData} onUpdate={handleFormUpdate} />
                                </Box>
                            </Grid.Col>
                        </Grid>
                    </Card.Section>
                </Card>
            </>
        ) : (
            <ItemNotFound label="Sorry. This document is either doesn't exist, or you don't have access to it." goBack="/folders" />
        )
    ) : (
        <Loader height="70vh" />
    );
};

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        changesMade: state.global && state.global.changes_made ? state.global.changes_made : false,
    };
};

export default compose(connect(mapStateToProps))(Document);
