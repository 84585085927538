import React, { useMemo, useContext, createContext, useState, memo } from 'react';
import { compose } from "recompose";
import { connect, useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
import { useMantineTheme, Box, Title, Text, Blockquote, List, Popper, Image, Button } from '@mantine/core';
import { useScrollIntoView, useDidUpdate, useClickOutside, useMergedRef } from '@mantine/hooks';
import { PlusCircledIcon, CheckIcon } from '@radix-ui/react-icons';
import { useNotifications } from '@mantine/notifications';

import { useInsightClicked, useSelectInsight, useInsightsData } from '../../modules/Insights/redux/hooks';
// import { getInsightKeypoint, getInsightContentBlocks } from '../../modules/Insights/helpers';

import ButtonIconCircle from '../../components/ButtonIconCircle';

import { isDev } from '../../helpers/auth';
import { isArrayExists, validate } from '../../helpers/validation';
import { hexToRgb } from '../../helpers/data';
import { tiptapHTMLToItems , tiptapBlockContent, tiptapReaddMarks, getCustomExtensionsFromDoc, tiptapHeadingText } from '../../helpers/tiptap';

import { useScreenSize } from '../../redux/global/hooks';

import { DEV_MODE } from '../../constants';

const ContentContext = createContext({
    blocks_selected: false,
});

const Content = ({
    type = '',
    text = '',
    marks = []
}) => {
    const theme = useMantineTheme();
    if ( type === 'text' || type === 'listItem' ) {
        // const markType = marks && isArrayExists( marks ) && marks[0] && marks[0].type ? marks[0].type : '';
        let obj = text;
        if ( marks && isArrayExists( marks ) ) {
            marks.forEach( mark => {
                if ( mark.type && mark.type !== '' ) {
                    switch ( mark.type ) {
                        case 'link':
                            const url = mark.attrs && mark.attrs.href && validate( mark.attrs.href, 'url' ) ? mark.attrs.href : '';
                            obj = url && url !== '' ? <a href={url} target="_blank" rel="noopener noreferrer nofollow">{text}</a> : text ;
                            break;
                        case 'italic':
                            obj = <em>{obj}</em>;
                            break;
                        case 'bold':
                            obj = <strong>{obj}</strong>;
                            break;
                        case 'highlight':
                            let color = ( marks[0] && marks[0].attrs && marks[0].attrs.color ) || theme.colors.yellow[2];
                            obj = <mark className='highlighted' data-color={color} style={{ backgroundColor: color }}>{obj}</mark>
                            break;
                    }
                } // end - mark.type
            });
        } // end - marks
        return obj;
    } else {
        return null;
    }
}

const Item = memo(({
    authData = null,
    id = '',
    type = '',
    content,
    attrs = {},
    onTextSelection = () => {},
    onSetContentElement = () => {},
    onAddHeadingToNotes = () => {}
}) => {
    // const theme = useMantineTheme();
    const { blocks_selected } = useContext(ContentContext);
    const [ screenSize ] = useScreenSize();
    const [ insight_clicked, doInsightClicked, resetInsightClicked ] = useInsightClicked();
    const [ select_insight, doSelectInsight ] = useSelectInsight();
    const { scrollIntoView, targetRef } = useScrollIntoView({
        offset: 30 + ( screenSize === 'xs' || screenSize === 'sm' ? 60 : 0 )
    });
    const clickOutsideRef = useClickOutside(() => {
        onTextSelection(false);
    });
    const [ contentElement, setContentElement ] = useState(null);
    const mergedRef = useMergedRef(targetRef, clickOutsideRef);

    useDidUpdate(() => {
        if ( insight_clicked && insight_clicked.includes(id) ) {
            scrollIntoView();
            // reset insight_clicked
            setTimeout(() => {
                resetInsightClicked();
            },250);
        }
    }, [ insight_clicked ] );

    if ( !content && type !== 'image' )
        return null;

    const handleContentClicked = (selected) => () => {
        doSelectInsight(selected);
    }

    const handleTextSelection = (e) => {
        // stop propagation
        e.stopPropagation();
        // get text selection
        const selection = window.getSelection();
        onTextSelection(selection);
        onSetContentElement(contentElement);
    }

    const props = {
        ref: setContentElement,
        id: '#content-item-' + id,
        className: 'content-item',
        onMouseUp: handleTextSelection
    };

    const component = () => {
        switch ( type ) {
            case 'heading':
                return (
                    <Title
                        {...props}
                        order={attrs.level || 2} 
                        align={attrs.textAlign || 'left'}
                        pt="20px"
                        mb="20px"
                        sx={ isDev(authData) ? () => ({
                            position: 'relative',
                            paddingRight: '40px',
                        }) : null}>
                        {content.map((item,index) => (
                            <Content key={index} {...item} />
                        ))}
                        { isDev(authData) && (
                        <>
                            <ButtonIconCircle
                                label='Add To Notes'
                                icon={<PlusCircledIcon width={24} height={24} />}
                                btnProps={{
                                    variant: 'light',
                                    color: 'gray',
                                    radius: "100%",
                                }}
                                tooltipProps={{
                                    sx: (theme) => ({
                                        position: 'absolute',
                                        top: '50%',
                                        right: '0',
                                        transform: 'translateY(-25%)',
                                    })
                                }}
                                btnSx={(theme) => ({
                                    paddingLeft: '0',
                                    paddingRight: '0',
                                })}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onAddHeadingToNotes(tiptapHeadingText({ content }));
                                }} />
                        </>
                        ) }
                    </Title>
                );
            case 'image':
                return attrs && attrs.src && attrs.src !== '' && validate( attrs.src, 'url' ) ? (
                <Box
                    sx={() => ({
                        padding: '5px 0',
                        textAlign: 'center'
                    })}>
                    <Image
                        {...props}
                        radius="md"
                        fit="contain"
                        width={"70%"}
                        src={attrs.src}
                        alt={ attrs.alt && attrs.alt !== '' && validate( attrs.alt, 'string_name' ) ? attrs.alt : '' } />
                </Box>
                ) : null ;
            case 'paragraph':
                return (           
                <Text
                    {...props}
                    mb="sm"
                    >
                    {content.map((item,index) => (
                        <Content key={index} {...item} />
                    ))}
                </Text>
                );
            case 'blockquote':
                return (
                <Blockquote
                    {...props}
                    mb="sm"
                    cite={( content[1] && content[1].content && content[1].content[0] && content[1].content[0].text ? "~ " + content[1].content[0].text : "" )}>
                    { content[0] && content[0].content && isArrayExists( content[0].content ) && content[0].content.map((item,index) => (
                        <Content key={index} {...item} />
                    )) }
                </Blockquote>
                );
            case 'bulletList':
            case 'orderedList':
                return (
                <List
                    {...props}
                    mb="md"
                    withPadding={( type === 'orderedList' ? false : true )}
                    type={( type === 'orderedList' ? 'ordered' : 'unordered' )}>
                    {content.map((item,index) => (
                        <List.Item key={index}>
                            {item.content && isArrayExists( item.content ) && item.content.map((listitem,index) => listitem.content && isArrayExists( listitem.content ) && listitem.content.map((sublistitem,index) => (
                                <Content key={index} {...sublistitem} />
                            )))}
                        </List.Item>
                    ))}
                </List>
                );
            default:
                return null;
        }
    }

    return (
    <Box
        id={"content-"+id}
        ref={mergedRef}
        onClick={handleContentClicked(id)}
        sx={(theme) => ({
            backgroundColor: ( blocks_selected && blocks_selected.includes(id) ) ? theme.colors.indigo[0] : 'inherit',
        })}>
        {component()}
    </Box>
    )
});

const Contents = ({
    authData,
    dispatch,
    insightsList = [],
    documentData = {},
    onUpdate = () => {}
}) => {
    const theme = useMantineTheme();
    const notifications = useNotifications();
    const { blocks_selected } = useSelector((state) => state.insight_selected);
    const { select_insight } = useSelector((state) => state.select_insight);
    const insightsData = useInsightsData();
    const [ contentElement, setContentElement ] = useState(null);
    const [ textSelection, setTextSelection ] = useState(false);

    const contents = useMemo(() => {
        // console.log( tiptapHTMLToItems( documentData.content || '', getCustomExtensionsFromDoc(documentData) ) );
        return tiptapHTMLToItems( documentData.content || '', getCustomExtensionsFromDoc(documentData) );
    }, [ documentData ]);

    const blocksSelected = useMemo(() => {
        if ( blocks_selected && isArrayExists( blocks_selected ) ) {
            return [ ...blocks_selected ];
        } 
        else if ( select_insight && insightsData ) {
            let selectedBlocks = [];
            if ( insightsData && isArrayExists( insightsData ) ) {
                insightsData.forEach((insight) => {
                    if ( insight.content_blocks && insight.content_blocks.includes(select_insight) ) {
                        selectedBlocks = [ ...insight.content_blocks ];
                    } 
                });
            }
            return selectedBlocks;
        }
        return null;
    },[ blocks_selected, select_insight, insightsData ]);

    const handleAddHeadingTextToNotes = (text = '') => {
        let notes = documentData.notes || '';
        if ( text && text !== '' ) {
            notes = notes + '\n\n' + text;
        }
        
        notifications.showNotification({
            color: 'teal',
            icon: <CheckIcon />,
            title: 'Text added to notes',
        });

        // update notes
        onUpdate({ ...documentData, notes });
    }

    const handleAddTextToNotes = (e) => {
        e.stopPropagation();
        const startNode = window.getSelection().anchorNode;
        const endNode = window.getSelection().focusNode;
        const selectedText = window.getSelection().toString();
        const startNodeID = startNode.parentElement.closest(".content-item").id.replace('#content-item-','');
        const endNodeID = endNode.parentElement.closest(".content-item").id.replace('#content-item-','');
        let notes = documentData.notes || '';

        if ( startNodeID !== endNodeID ) {
            let splitContents = selectedText.split('\n');
            if ( splitContents && isArrayExists( splitContents ) ) {
                splitContents.forEach(string => {
                    notes += '\n\n' + string.trim();
                });
            }
        } else {
            notes += '\n\n'+selectedText;
        }

        notifications.showNotification({
            color: 'teal',
            icon: <CheckIcon />,
            title: 'Selected text added to notes',
        });

        // update notes
        onUpdate({ ...documentData, notes });

        // reset selection
        window.getSelection().removeAllRanges();
    }

    const handleHighlight = (color = theme.colors.yellow[2]) => (e) => {
        e.stopPropagation();
        const startNode = window.getSelection().anchorNode;
        const endNode = window.getSelection().focusNode;
        const selectedText = window.getSelection().toString();
        const startNodeID = startNode.parentElement.closest(".content-item").id.replace('#content-item-','');
        const endNodeID = endNode.parentElement.closest(".content-item").id.replace('#content-item-','');
        let content = documentData.content || '';

        // console.log( 'before', content );

        // if covers multiple blocks
        if ( startNodeID !== endNodeID ) {
            let splitContents = selectedText.split('\n');
            
        } else {
            // console.log( 'selectedHTML', endNode.parentElement.innerHTML );
            // if ( content.indexOf(endNode.parentElement.innerHTML) >= 0 ) {
            //     const highlightedText = endNode.parentElement.innerText.replace(selectedText, `<mark class="highlighted" data-color="${color}" style="background-color: ${hexToRgb(color)};">${selectedText}</mark>`);
            //     content = content.replace(endNode.parentElement.innerHTML, highlightedText);
            // }
            const block = contents.find((item) => item.id === endNodeID);
            if ( block ) {
                let blockContent = tiptapBlockContent(block,true),
                    blockContentHTML = blockContent.text || '';
                // const highlightedText = endNode.parentElement.innerText.replace(selectedText, `<mark class="highlighted" data-color="${color}" style="background-color: ${hexToRgb(color)};">${selectedText}</mark>`);
                blockContentHTML = blockContentHTML.replace(selectedText, `<mark class="highlighted" data-color="${color}" style="background-color: ${hexToRgb(color)};">${selectedText}</mark>`);
                // re-add in the other marks
                blockContentHTML = tiptapReaddMarks( blockContentHTML, block );
                console.log( blockContentHTML );
                content = content.replace(endNode.parentElement.innerHTML, blockContentHTML);
            } // end - block
        } // end startNodeID

        console.log( 'after' ,content );
        // console.log( tiptapHTMLToItems(content) );
        
        // onUpdate({ ...documentData, content });

        // reset selection
        window.getSelection().removeAllRanges();
    }

    return (
    <Box 
        id="doc-content-area"
        sx={(theme) => ({
            width: '100%',
            maxWidth: '600px',
            margin: '0 auto',
        })}>
        <ContentContext.Provider value={{
            blocks_selected: blocksSelected,
        }}>
            {contents.map(item => (
                <Item 
                    key={item.id}
                    authData={authData} 
                    {...item}
                    onTextSelection={(val) => setTextSelection(val)}
                    onSetContentElement={(val) => setContentElement(val)} 
                    onAddHeadingToNotes={handleAddHeadingTextToNotes}
                    />  
            ))}
        </ContentContext.Provider>

        { (
        <Popper
            position="left"
            placement="center"
            gutter={5}
            arrowSize={5}
            withArrow
            withinPortal
            mounted={textSelection && textSelection.toString() !== '' ? true : false}
            referenceElement={contentElement}
            transition="pop-top-left"
            transitionDuration={200}
            zIndex={101}
            arrowStyle={{
                backgroundColor: theme.colors.indigo[5]
            }}
        >
            <Box
                sx={(theme) => ({
                    pointerEvents: "all",
                    backgroundColor: theme.colors.indigo[5],
                    boxShadow: theme.shadows.lg,
                    padding: theme.spacing.sm,
                    borderRadius: theme.radius.md,
                    '& .mantine-Button-root': {
                        width: '24px',
                        height: '24px',
                        cursor: 'pointer',
                    }
                })}>
                {/* <Button size="xs" p="sm" radius="100%" variant="filled" sx={() => ({
                    backgroundColor: theme.colors.yellow[2],
                    '&:hover': {
                        backgroundColor: theme.colors.yellow[5]
                    }
                })} onClick={handleHighlight(theme.colors.yellow[2])}></Button>
                <Space h="xs" /> */}

                <ButtonIconCircle
                    label="Add selected text to notes"
                    position='right'
                    icon={<PlusCircledIcon />}
                    btnProps={{
                        variant: "filled",
                        color: 'dark',
                        radius: 'sm',
                        size: 'xs',
                    }}
                    color={theme.colors.dark[8]}
                    onClick={handleAddTextToNotes} />


                {/* <Button size="xs" p="sm" radius="100%" variant="filled"  sx={() => ({
                    backgroundColor: theme.colors.green[2],
                    '&:hover': {
                        backgroundColor: theme.colors.green[5]
                    }
                })} onClick={handleHighlight(theme.colors.green[2])}></Button> */}
                {/* <Space h="xs" /> */}
                {/* <Button size="xs" p="sm" radius="100%" variant="filled"  sx={() => ({
                    backgroundColor: theme.colors.red[2],
                    '&:hover': {
                        backgroundColor: theme.colors.red[5]
                    }
                })} onClick={handleHighlight(theme.colors.red[2])}></Button> */}
                {/* <Space h="xs" /> */}
                {/* <Button size="xs" p="sm" radius="100%" variant="filled"  sx={() => ({
                    backgroundColor: theme.colors.blue[2],
                    '&:hover': {
                        backgroundColor: theme.colors.blue[5]
                    }
                })} onClick={handleHighlight(theme.colors.blue[2])}></Button> */}
            </Box>
        </Popper>
        ) }

    </Box>
    );
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        insightsList: ( state.insights && state.insights.insights ) ? state.insights.insights : []
    };
};

export default compose(
    connect(mapStateToProps)
)(Contents);