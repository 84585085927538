import React, { useState, useEffect, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { getFirestore, query, where, onSnapshot, collection, limit, orderBy } from "firebase/firestore";
import { useDidUpdate } from '@mantine/hooks';
import { useMantineTheme, Group, Text, Space, Box, Button, Drawer, ScrollArea } from '@mantine/core';
import { useNotifications } from '@mantine/notifications';
import { PlusCircledIcon, ArchiveIcon, CheckIcon } from '@radix-ui/react-icons';

import Folder from './folder';
import FolderFormModal from '../../pages/Folders/modal';

import Loader from '../../components/Loader';
import ButtonIconCircle from '../../components/ButtonIconCircle';

import { isArrayExists } from '../../helpers/validation';

import { useNavigation } from '../../redux/global/hooks';

import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/api';

const MAX_PROJECTS_SHOW = 1000;

const FoldersList = ({
    authData,
    navbarStatus,
    drawerMode = false,
    globalDisabled,
    changesMade,
    foldersList,
    foldersRand,
    dispatch,
    onDrawerToggle = () => {}
}) => {
    // const db = getFirestore();
    const notifications = useNotifications();
    const theme = useMantineTheme();
    const navigate = useNavigation();
    const [ loaded, setLoaded ] = useState(false);
    const [ drawerOpened, setDrawerOpened ] = useState(false);
    const [ addNewOpened, setAddNewOpened ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        if ( foldersRand ) {
            setLoaded(true);
        }
    },[foldersRand]);

    useDidUpdate(() => {
        if ( drawerOpened && ( navbarStatus || drawerMode ) ) {
            setDrawerOpened(false);
        }
    },[ drawerOpened, navbarStatus, drawerMode ]);

    const disabled = useMemo(() => {
        return globalDisabled || !loaded ? true : false;
    }, [ globalDisabled, loaded ]);

    const folders = useMemo(() => {
        let list = [];
        if ( foldersList && isArrayExists( foldersList ) ) {
            // sort by modified_on
            list = foldersList.sort((a, b) => {
                return b.modified_on - a.modified_on;
            });
            // slice to show only MAX_PROJECTS_SHOW
            list = foldersList.slice(0,MAX_PROJECTS_SHOW);
        }
        return list;
    },[ foldersList ]);

    const handleAddNewFolder = (formData) => {
        let error = false;
        // do error check
        // check if name is empty
        if ( !formData.name ) {
            error = 'Name is required';
        }

        // check if errors are empty
        if ( error ) {
            notifications.showNotification({
                color: 'red',
                title: error,
            })
        } else {
            // no errors, add new folder
            setLoading(true);
            callFunctionsAPI({
                url: 'folder',
                action: 'add',
                formData: formData
            })
            .then(list => {
                notifications.showNotification({
                    color: 'teal',
                    icon: <CheckIcon />,
                    title: 'Folder added',
                }) 
                // do reset
                doModalClose();
            })
            .catch(error => {
                setLoading(false);
                notifications.showNotification({
                    color: 'red',
                    title: getAPIErrorMessage( error ),
                }) 
            });
        }
    }

    const doModalClose = () => {
        // reset everything
        setLoading(false);
        setAddNewOpened(false);
    }

    const handleNavigation = (link) => {
        navigate(link);
        setDrawerOpened(false);
        if ( drawerMode )
            onDrawerToggle();
    }

    const Title = () => {
        return (
        <Group 
            pl="12px"
            position="apart">
            <Group position='left'>
                <ArchiveIcon size={16} />
                <Text 
                    size="md" 
                    weight={500}
                    sx={(theme) => ({
                        color: ( drawerOpened ? theme.colors.dark[8] : theme.colors.gray[5] )
                    })}>
                    Folders
                </Text>
            </Group>
            <ButtonIconCircle
                label={"Add New Folder"}
                icon={<PlusCircledIcon />}
                position="right"
                btnProps={{
                    id: "folderslist-addnew",
                    size: 'xs',
                    variant: "filled",
                    color: ( drawerOpened ? 'dark' : 'gray' ),
                    radius: 'md',
                    disabled
                }}
                color={theme.colors.indigo[8]}
                onClick={() => setAddNewOpened(true)} />
        </Group>
        )
    }

    const List = () => {
        return (
            <>
            <Space h="sm" />
            { loaded ? (
            <ScrollArea
                style={{
                    height: 'calc(100% - 38px)',
                }}>
                { folders && isArrayExists( folders ) ? (
                <Box
                    sx={(theme) => ({
                        paddingRight: ( drawerOpened ? '5px' : null ),
                        paddingBottom: ( drawerOpened ? '10px' : null ),
                        '& > div': {
                            marginBottom: theme.spacing.xs,
                            '&:last-child': {
                                marginBottom: 0
                            }
                        }
                    })}>
                    { folders.slice(0,MAX_PROJECTS_SHOW).map((folder, index) => (
                    <Folder
                        key={folder.id}
                        drawerOpened={drawerOpened}
                        folder={folder}
                        onNavigate={handleNavigation} />
                    ))}
                </Box>
                ) : (
                <Group position="center">
                    <Text size="md" weight={500}>
                        No folder added yet.
                    </Text>
                </Group>
                ) }
                { folders && isArrayExists( folders ) && folders.length >= MAX_PROJECTS_SHOW && (
                <>
                    <Space h="xs" />
                    <Button
                        color={ drawerOpened ? "dark" : "gray"}
                        variant='filled'
                        size="xs"
                        fullWidth
                        radius={"sm"}
                        onClick={() => {
                            handleNavigation('/folders');
                        }}
                        sx={(theme) => ({
                            '&:hover': {
                                background: theme.colors.indigo[8]
                            }
                        })}>
                        View All Folders
                    </Button>
                    <Space h="sm" />
                </>
                ) }
            </ScrollArea>
            ) : <Loader height="60px" loaderProps={{ variant: 'dots' }} />  }
        </>
        )
    }

    return (
    <>
        { navbarStatus || drawerMode ? (
        <Title />
        ) : (
        <ButtonIconCircle
            label={"Folders"}
            icon={<ArchiveIcon />}
            position="right"
            btnProps={{
                size: 'xs',
                variant: ( drawerOpened ? "filled" : "light" ),
                color: 'gray',
                radius: 'md',
            }}
            btnSx={{
                height: '42px',
                background: ( drawerOpened ? theme.colors.indigo[8] : 'none' ),
                '&:hover': {
                    backgroundColor: theme.colors.gray[1],
                    color: theme.colors.dark[9]
                },
            }}
            color={theme.colors.indigo[8]}
            onClick={() => setDrawerOpened(!drawerOpened)} />
        )}

        { ( navbarStatus || drawerMode ) && (
        <List />
        )} 

        <FolderFormModal 
            opened={addNewOpened}
            loading={loading}
            onClose={doModalClose}
            doAction={handleAddNewFolder} />

        <Drawer
            opened={drawerOpened}
            onClose={() => setDrawerOpened(false)}
            title={<Title />}
            padding="md"
            size={300}
            zIndex={99}
            shadow={"lg"}
            withOverlay={false}
            sx={(theme) => ({
                '& .mantine-Drawer-drawer': {
                    left: '60px',
                    boxShadow: 'inset -2px 0px 0px 0px ' + theme.colors.gray[1],
                    backgroundColor: theme.colors.gray[0]
                }
            })}
        >
            <List />
        </Drawer>
        
    </>
    )
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        globalDisabled: ( state.global && state.global.disabled ) ? state.global.disabled : false,
        navbarStatus: ( state.global && state.global.navbar ) ? state.global.navbar : false,
        foldersList: ( state.folders && state.folders.folders ) ? state.folders.folders : null,
        foldersRand: ( state.folders && state.folders.rand ) ? state.folders.rand : null,
        changesMade: ( state.global && state.global.changes_made ) ? state.global.changes_made : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(FoldersList);