import React, { useMemo, useState } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { Title, Text } from '@mantine/core';

const WelcomeTour = ({
    id = '',
    defaultStyles = {},
    defaultLocale = {},
    defaultButtonStyles = {},
    onSkip = () => {}
}) => {
    const [ currentStep, setCurrentStep ] = useState(0);

    const steps = useMemo(() => {
        return [
            {
                placement: 'center',
                target: 'body',
                content: (
                <>
                    <Title order={3}>Welcome to Sagely AI</Title>
                    <Text mt="md">Sagely AI is an AI-powered long-form content summarizer that extracts main points from enormous blocks of text and presents them in a short and easy-to-digest format so that you can spend less time reading and more time learning.</Text>
                </>
                )
            },
            {
                target: '#navbar-addnewdoc',
                placement: 'right',
                content: (
                <>
                    <Title order={4}>How To Add a Document</Title>
                    <Text mt="md" size="sm" align='left'>The first step is to add a document. Here you'll need to insert the content you want to be summarized.</Text>
                </>
                )
            },
            {
                target: '#navbar-folderslist',
                placement: 'right',
                content: (
                <>
                    <Title order={4}>Organize Your Documents By Folders.</Title>
                    <Text mt="md" size="sm" align='left'>You can also organize your documents in folders. This will make it easier to find the document you're looking for.</Text>
                    <Text mt="sm" size="sm" align='left'>Here is the list of folders that are available in your account. Simply click the name of one of those folders to enter it.</Text>
                </>
                )
            },
            {
                target: '#folderslist-addnew',
                placement: 'right',
                content: (
                <>
                    <Title order={4}>Add New Folder</Title>
                    <Text mt="md" size="sm" align='left'>You can add a new folder by clicking the '+' icon.</Text>
                </>
                )
            },
            {
                target: '#dashboard-widget-credits',
                placement: 'left',
                content: (
                <>
                    <Title order={4}>Your Token Balance</Title>
                    <Text mt="md" size="sm" align='left'>Here's the balance of the total tokens in your account.</Text>
                    <Text mt="sm" size="sm" align='left'>Sagely AI uses tokens as its currency. Every time you use the app to summarize a document, we'll deduct tokens from your account based on the number of words in that document.</Text>
                    <Text mt="sm" size="sm" align='left'>For example, if you summarize a document with 500 words, we'll deduct 500 tokens from your account.</Text>
                </>
                )
            },
            {
                target: '#dashboard-widget-recentdocs',
                placement: 'right',
                content: (
                <>
                    <Title order={4}>Your Most Recent Document(s):</Title>
                    <Text mt="md" size="sm" align='left'>All your most recent documents are listed here. This list shows the last 10 documents that you created or updated. You can click on the "open" button to open the document in the app.</Text>
                </>
                )
            },
            {
                placement: 'center',
                target: 'body',
                content: (
                <>
                    <Title order={3}>Let's Get Started!</Title>
                    <Text mt="md" size="md" align='left'>Sagely AI is still essentially an MVP (Minimum Viable Product). We're still in the very early stages of development, so things may not be as polished or feature-complete as they appear here.</Text>
                    <Text mt="sm" size="md" align='left'>It's easy to get caught up in the details. The most important thing here is helping you turn cluttered information into valuable knowledge, and that's why we keep the design simple, and the feature set small.</Text>
                    <Text mt="sm" size="md" align='left'>We hope you enjoy what we have right now and look forward to your feedback.</Text>
                    {/* <Text mt="sm" size="md" align='left'>So, let's get started!</Text> */}
                </>
                )
            },
        ]
    },[]);

    const handleCallback = (data) => {
        const { status, index } = data;
        // update current step
        setCurrentStep(index);
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            onSkip(id);
        }
    }
    
    return (
    <>

        <Joyride
            steps={steps}
            run={true}
            disableScrolling
            disableCloseOnEsc
            disableOverlayClose
            hideCloseButton
            showSkipButton
            showProgress
            continuous
            locale={{
                ...defaultLocale,
                last: "let's get started!"
            }}
            callback={handleCallback}
            styles={{
                options: {
                    ...defaultStyles,
                    width: ( currentStep === 0 || currentStep === steps.length - 1 ) ? '650px' : '400px'
                },
                buttonNext: {
                    ...defaultButtonStyles,
                    padding: '8px 25px',
                    fontWeight: 'bold'
                },
                buttonBack: {
                    ...defaultButtonStyles
                },
                buttonClose: {
                    ...defaultButtonStyles
                },
                buttonSkip: {
                    ...defaultButtonStyles
                },
            }}
            />
    
    </>
    )
}

export default WelcomeTour;