

import { 
    GET_FOLDERS,
    GET_FOLDER,
    DELETING_FOLDER,
    RESET_FOLDERS,
    RESET_FOLDER,
} from './types';

import { callFunctionsAPI, getAPIErrorMessage } from '../../../helpers/api';

export const getFolders = () => {
	return dispatch => {

        callFunctionsAPI({
            url: 'folder',
            action: 'get_folders',
        })
        .then(list => {
            dispatch({
                type: GET_FOLDERS,
                payload: {
                    list,
                }
            });
        })
        .catch(error => {
            alert(getAPIErrorMessage(error));
            dispatch({
                type: GET_FOLDERS,
                payload: {
                    list: [],
                }
            });
        });

    }
};

export const getFolder = (id) => {
	return dispatch => {

        callFunctionsAPI({
            url: 'folder',
            action: 'get_folder',
            formData: { id }
        })
        .then(folder => {
            dispatch({
                type: GET_FOLDER,
                payload: {
                    folder,
                }
            });
        })
        .catch(error => {
            dispatch({
                type: GET_FOLDER,
                payload: {
                    folder: {},
                }
            });
        });

    }
};

export const deletingFolder = (id) => {
    return dispatch => {
        setTimeout(() => {
            dispatch({
                type: DELETING_FOLDER,
                payload: { id }
            });
        },50);
    }
}

export const resetFolders = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch({
                type: RESET_FOLDERS,
            });
        },150);
    }
}

export const resetFolder = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch({
                type: RESET_FOLDER,
            });
        },150);
    }
}