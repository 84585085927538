import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMantineTheme, } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';

import { appChangesReset } from './actions';

export const useNavigation = () => {
    const { changes_made = null } = useSelector((state) => state.global);
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const doNavigation = ( path = '/' ) => {
        if ( changes_made ) {
            const answer = window.confirm("You have unsaved changes. Are you sure you want to leave this page?");
            if ( answer ) {
                navigate(path);
                dispatch( appChangesReset() );
            }
        } else {
            navigate(path);
        }
    }

    return doNavigation;
}

export const useScreenSize = () => {
    const theme = useMantineTheme();
    const { width } = useViewportSize();
    const [ screenSize, setScreenSize ] = useState('xl');

    useEffect(() => {
        if ( width < theme.breakpoints.xs ) {
            setScreenSize('xs');
        } else if ( width < theme.breakpoints.sm ) {
            setScreenSize('sm');
        } else if ( width < theme.breakpoints.md ) {
            setScreenSize('md');
        } else if ( width < theme.breakpoints.lg ) {
            setScreenSize('lg');
        } else {
            setScreenSize('xl');
        }
    },[ width ]);

    return [ screenSize ];
}

export const useSortBy = () => {
    const { 
        sort_by = 'modified_on-desc',  
        sort_by_folder = ''
    } = useSelector((state) => state.global);
    const dispatch = useDispatch();
    const [ sort, setSort ] = useState( sort_by );
    const [ folder, setFolder ] = useState( sort_by_folder );

    useEffect(() => {
        dispatch({
            type: 'documents_sort',
            payload: {
                sort_by: sort,
                folder_id: folder
            }
        });
    } ,[ sort, folder ]);

    const handleSort = ( newSort, newFolder ) => {
        setSort( newSort );
        setFolder( newFolder );
    }

    return [ sort, folder, handleSort ];
}