
export const FIREBASE_ACCOUNT_ID = 'sagelyai';
export const FIREBASE_REGION = 'us-central1';

// emulator port - make sure it's same as firebase.json
export const EMULATOR_PORT_CLOUD_FUNCTIONS = 6100;
export const EMULATOR_PORT_FIRESTORE = 6200;
export const EMULATOR_PORT_DATABASE = 6300;
export const EMULATOR_PORT_HOSTING = 6400;
export const EMULATOR_PORT_PUBSUB = 6500;
export const EMULATOR_PORT_STORAGE = 6600;
export const EMULATOR_PORT_UI = 6900;

export const FIREBASE_CONFIG_DATA = {
    apiKey: "AIzaSyAm_rnf8CBGO1EPnxGGK1LOD6kH4frfWAQ",
    authDomain: "sagelyai.firebaseapp.com",
    databaseURL: "https://sagelyai-default-rtdb.firebaseio.com",
    projectId: "sagelyai",
    storageBucket: "sagelyai.appspot.com",
    messagingSenderId: "367425576932",
    appId: "1:367425576932:web:2ff6ed4ae87a62bd7231f1"
};