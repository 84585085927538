import React, { useMemo } from 'react';
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { useMantineTheme } from '@mantine/core';

import WelcomeTour from './tour_welcome';
import AddFirstDoc from './tour_addfirstdoc';
import DocumentTour from './tour_document';
import InsightsTour from './tour_insights';

import { useUserOnboarding } from './redux/hooks';

import { isArrayExists } from '../../helpers/validation';
import { callFunctionsAPI } from '../../helpers/api';

const UserOnboarding = ({
    show = [ 'welcome', 'add_first_document' ]
}) => {
    const globalDisabled = useSelector(state => state.global.disabled);
    const activeModals = useSelector(state => state.modals.active);
    const loaded = useSelector(state => state.onboarding.loaded);
    const onboarding = useSelector(state => state.onboarding.onboarding);
    const theme = useMantineTheme();
    const location = useLocation();

    const isWelcomeTour = useMemo(() => {
        // if welcome message is not shown
        if ( loaded && location && location.pathname && location.pathname === '/dashboard' && !( onboarding && onboarding.welcome && onboarding.welcome === 'done' ) ) {
            return 'welcome';
        }
        return false;
    }, [ loaded, onboarding, location ] );

    const isAddNewDocTour = useMemo(() => {
        // if is add new document modal
        if ( loaded && activeModals && isArrayExists( activeModals ) && activeModals.includes( 'addnewdoc' ) && !( onboarding && onboarding.add_first_document && onboarding.add_first_document === 'done' ) ) {
            return 'add_first_document';
        }
        return false;
    }, [ loaded, onboarding, activeModals ] );

    const isDocumentTour = useMemo(() => {
        // first doc
        if ( loaded && location && location.pathname && location.pathname.indexOf("/folders/") >= 0 && location.pathname.indexOf("/docs/") >= 0 && !( onboarding && onboarding.document && onboarding.document === 'done' ) ) {
            return 'document';
        }
        return false;
    }, [ loaded, onboarding, location ] );

    const isFirstInsightTour = useMemo(() => {
        if ( loaded && location && location.pathname && location.pathname.indexOf("/folders/") >= 0 && location.pathname.indexOf("/docs/") >= 0 && !( onboarding && onboarding.insights && onboarding.insights === 'done' ) ) {
            return 'insights';
        }
        return false;
    }, [ loaded, onboarding, location ] );

    const defaultStyles = useMemo(() => {
        return {
            arrowColor: '#fff',
            backgroundColor: '#fff',
            beaconSize: 36,
            overlayColor: 'rgba(0, 0, 0, 0.5)',
            primaryColor: theme.colors.indigo[7],
            spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
            textColor: theme.colors.brand[0],
            width: '400px',
            zIndex: 1000000
        };
    },[ theme ]);

    const defaultButtonStyles = useMemo(() => {
        return {
            fontSize: '13px',
        };
    },[ theme ]);

    const defaultLocale = useMemo(() => {
        return {
            back: 'BACK',
            close: 'CLOSE',
            last: 'FINISH',
            next: 'NEXT',
            skip: 'SKIP TUTORIAL',
        }
    },[]);

    const handleSkip = (currentTour) => {
        callFunctionsAPI({
            url: 'user',
            action: 'update_user_onboarding',
            formData: { tour: currentTour }
        })
        .then(() => {
            
        })
        .catch(err => {
            
        })
    }

    return (
    <>
    
        { isWelcomeTour && show.includes('welcome') && !globalDisabled && (
            <WelcomeTour
                id="welcome"
                defaultStyles={defaultStyles}
                defaultLocale={defaultLocale} 
                defaultButtonStyles={defaultButtonStyles}
                onSkip={handleSkip} />
        ) }

        { isAddNewDocTour && show.includes('add_first_document') && !globalDisabled && (
            <AddFirstDoc
                id="add_first_document"
                defaultStyles={defaultStyles}
                defaultLocale={defaultLocale}
                defaultButtonStyles={defaultButtonStyles} 
                onSkip={handleSkip} />
        )}

        { isDocumentTour && show.includes('document') && !globalDisabled && (
            <DocumentTour
                id="document"
                defaultStyles={defaultStyles}
                defaultLocale={defaultLocale}
                defaultButtonStyles={defaultButtonStyles} 
                onSkip={handleSkip} />
        ) }

        { isFirstInsightTour && show.includes('insights') && !globalDisabled && (
            <InsightsTour
                id="insights"
                defaultStyles={defaultStyles}
                defaultLocale={defaultLocale}
                defaultButtonStyles={defaultButtonStyles}
                onSkip={handleSkip} />
        ) }
    
    </>
    )
}

export default UserOnboarding;