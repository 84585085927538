import React, { useState, useMemo, useEffect } from 'react';
import { useMantineTheme, Container, Title, Space, Group, Text, Grid, Card, Spoiler, Box, TypographyStylesProvider, Button, Checkbox, Affix, Center, Badge } from '@mantine/core';
import { useNotifications } from '@mantine/notifications';
import { useModals } from '@mantine/modals';
import { TrashIcon, MagnifyingGlassIcon, Cross2Icon, PlusCircledIcon, ResetIcon } from '@radix-ui/react-icons';

import DocumentAddNew from '../Documents/addnew';

import ButtonIconCircle from '../../components/ButtonIconCircle';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { getAPIErrorMessage } from '../../helpers/api';
import { tiptapFilterContent } from '../../helpers/tiptap';

import { useScreenSize } from '../../redux/global/hooks';


const ContentCard = ({
    id = '',
    content = '',
    selected = [],
    used = [],
    onSelect = () => {},
    onUnselect = () => {},
    onCardRemove = () => {}
}) => {
    const theme = useMantineTheme();
    const modals = useModals();

    const isSelected = useMemo(() => {
        return selected.includes(id);
    }, [ selected ]);

    const isUsed = useMemo(() => {
        return used.includes(id);
    }, [ used ]);

    const htmlContent = useMemo(() => {
        return tiptapFilterContent( content || '' );
    },[ content ]);

    const openDeleteModal = () => modals.openConfirmModal({
        title: 'Delete Content Card',
        centered: true,
        zIndex: 600,
        children: (
        <>
            <Text size="sm">
                Are you sure you want to delete this content card? 
            </Text>
            <Text size="sm" weight={"700"} color="red">
                This action cannot be undone.
            </Text>
        </>
        ),
        labels: { confirm: 'Delete', cancel: "No don't delete it" },
        confirmProps: { color: 'red' },
        onConfirm: () => onCardRemove(id),
    });

    const openPreviewModal = () => modals.openModal({
        title: '',
        centered: true,
        zIndex: 600,
        closeOnClickOutside: true,
        closeOnEscape: true,
        children: (
        <>
            <TypographyStylesProvider>
                <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            </TypographyStylesProvider>
        </>
        )
    });

    return (
    <Card
        p="md"
        shadow={"sm"}
        mb="xs"
        withBorder
        sx={(theme) => ({
            background: isSelected ? theme.colors.indigo[0] : ( isUsed ? theme.colors.gray[1] : null ),
            borderColor: isSelected ? theme.colors.indigo[5] : ( isUsed ? theme.colors.gray[3] : '#fff' ),
            borderWidth: '2px',
            opacity: isUsed ? ( isSelected ? 1 : 0.65 ) : 1,
        })}>
        <Group
            pb="md" 
            position='apart'>
            <ButtonIconCircle
                label="Remove"
                icon={<TrashIcon />}
                btnProps={{
                    variant: "light",
                    color: 'red',
                    disabled: false
                }}
                color={theme.colors.red[8]}
                onClick={openDeleteModal} />
            <Checkbox
                checked={isSelected}
                onChange={(e) => {
                    if ( e.target.checked ) {
                        onSelect(id);
                    } else {
                        onUnselect(id);
                    }
                }}
                sx={(theme) => ({
                    '& .mantine-Checkbox-input': {
                        cursor: 'pointer',
                        '&:not(:checked)': {
                            background: theme.colors.indigo[0],
                            borderColor: theme.colors.indigo[3],
                        }
                    }
                })} />
        </Group>
        <Box
            sx={() => ({
                minHeight: 150,
            })}>
            <Spoiler 
                    maxHeight={150} 
                    showLabel={<div></div>} 
                    hideLabel={<div></div>}>
                    <Box
                        sx={(theme) => ({
                            '& *': {
                                fontSize: theme.fontSizes.sm,
                            },
                            '& h1, & h2, & h3, & h4, & h5, & h6': {
                                fontSize: theme.fontSizes.md,
                                marginTop: '0px',
                                marginBottom: '7px',
                            },
                            '& p, & ul, & ol, & blockquote': {
                                marginBottom: '7px'
                            },
                        })}>
                        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                    </Box>
            </Spoiler>
       </Box>
       <Group 
            pt="md"
            position='center'>
            <Button
                variant="filled"
                color="indigo"
                radius={"md"}
                leftIcon={<MagnifyingGlassIcon />}
                onClick={openPreviewModal}>
                View Content
            </Button>
       </Group>
    </Card>
    );
}

const EpubContent = ({
    contents,
    onDocCreated = () => {},
    onUpdate = () => {},
    onReset = () => {}
}) => {
    const theme = useMantineTheme();
    const notifications = useNotifications();
    const [ screenSize ] = useScreenSize();
    const [ selected, setSelected ] = useState([]);
    const [ used, setUsed ] = useState([]);
    const [ predefinedFormData, setPredefinedFormData ] = useState(false);

    const handleDocumentCreated = (data) => {
        onDocCreated(data);
        setUsed([ ...used, ...selected ]);
        setPredefinedFormData(false);
        setSelected([]);
    }

    const handleDocumentCreation = () => {
        let content = '';
        if ( contents && isArrayExists(contents) ) {
            contents.forEach(card => {
                if ( selected.find( id => id === card.id ) ) {
                    content += ( card.content || '' );
                }
            });
        }
        setPredefinedFormData({
            content,
            content_source: 'editor'
        });
    }

    const handleReset = () => {
        onReset();
        setSelected([]);
        setUsed([]);
        setPredefinedFormData(false);
    }

    const handleCardRemoved = (id) => {
        onUpdate(contents.filter(item => item.id !== id));
    }

    const handleUnselect = (id) => {
        // make sure the id is in the selected array
        if ( selected.includes(id) ) {
            setSelected(selected.filter(item => item !== id));
        }
    }

    const handleSelect = (id) => {
        // make sure that the id is not already selected
        if ( !selected.includes(id) ) {
            setSelected([ ...selected, id ]);
        }
    }

    return (
    <Container 
        size={"lg"}>
        <Group position='right'>
            <Button
                variant="filled"
                color="dark"
                radius={"md"}
                leftIcon={<ResetIcon />}
                onClick={handleReset}>
                Reset
            </Button>
        </Group>
        <Space h="md" />
        <Box
            sx={(theme) => ({
                backgroundColor: theme.colors.gray[1],
                padding: theme.spacing.md,
                borderRadius: theme.radius.sm,
                border: '2px solid ' + theme.colors.gray[2],
            })}>
            <Grid spacing="md">
                {contents.map((content, index) => (
                <Grid.Col 
                    key={content.id} 
                    xs={12}
                    sm={6} 
                    md={3}>
                    <ContentCard 
                        {...content} 
                        selected={selected}
                        used={used}
                        onSelect={handleSelect}
                        onUnselect={handleUnselect}
                        onCardRemove={handleCardRemoved} />
                </Grid.Col>
                ))}
            </Grid>
        </Box>
        <Space h="xl" />
        <Space h="lg" />
        <Space h="lg" />

        { selected && isArrayExists( selected ) && (
        <Affix 
            zIndex={500}
            position={{ bottom: 40 }}>
            <Box sx={() => ({ width: '100vw' })}>
                <Center>
                    <Card
                        p="lg"
                        withBorder
                        sx={(theme) => ({
                            width: '600px',
                            borderColor: theme.colors.dark[8],
                            background: theme.colors.dark[4],
                            color: theme.colors.gray[0],
                            boxShadow: '0px 0px 18px rgba(0,0,0,0.25)',
                        })}>
                        <Group position='apart'>
                            <Group position='left'>
                                <ButtonIconCircle
                                    label="Unselect All"
                                    icon={<Cross2Icon />}
                                    tooltipProps={{
                                        zIndex: 501
                                    }}
                                    btnProps={{
                                        variant: "light",
                                        color: 'red',
                                        disabled: false
                                    }}
                                    color={theme.colors.red[8]}
                                    onClick={() => setSelected([])} />
                                <Badge pt="md" pb="md" radius={"md"} color="gray" sx={(theme) => ({
                                    color: theme.colors.dark[5],
                                })}>{selected.length} Card Selected</Badge>
                            </Group>
                            <Group position='right'>
                                <Button
                                    variant="filled"
                                    color="green"
                                    radius={"md"}
                                    leftIcon={<PlusCircledIcon />}
                                    onClick={handleDocumentCreation}>
                                    Create New Document
                                </Button>
                            </Group>
                        </Group>
                    </Card>
                </Center>
            </Box>
        </Affix>
        ) }

        <DocumentAddNew
            noButton={true}
            noRedirectAfterCreate={true}
            forceOpen={ predefinedFormData && isObjectExists( predefinedFormData ) ? true : false }
            predefinedFormData={predefinedFormData}
            onModalClose={() => setPredefinedFormData(false)}
            onDocAdded={handleDocumentCreated}
            />

    </Container>
    );
}

export default EpubContent;