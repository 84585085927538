import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from "react-redux";
import { Card, Skeleton, Title, Space, Box, Text, Group } from '@mantine/core';
// import { getDatabase, ref, query, onValue, off } from "firebase/database";

import { getFormattedCredits } from '../../helpers/number';


const WidgetCreditStats = () => {
    const credits = useSelector(state => state.credits.credits);
    const loaded = useSelector(state => state.credits.loaded);
    // const db = getDatabase();
    // const [ loaded, setLoaded ] = useState(false);
    // const [ credits, setCredits ] = useState(0);

    // useEffect(() => {
    //     const uid = authData && authData.uid ? authData.uid : 'nouid';
    //     const Ref = query( ref(db, 'users/' + uid + '/credits' ) );
    //     onValue(Ref, (snapshot) => {
    //         setCredits(( snapshot.exists() ? snapshot.val() : 0 ));
    //         setLoaded(true);
    //     });
    //     return () => {
    //         setLoaded(false);
    //         off( Ref );
    //     };
    // },[ authData ]);

    const formattedCredits = useMemo(() => {
        // format credits number
        return getFormattedCredits(credits);
    },[ credits ]);

    return (
    <Card
        id="dashboard-widget-credits"
        p="md"
        withBorder
        shadow={"sm"}>
        <Title order={4}>My Token Balance:</Title>
        <Space h="sm" />
        { loaded ? (
        <Group>
            <i className="ri-copper-coin-line"></i>
            <Text size="lg" weight={700} color="indigo">{formattedCredits}</Text>
        </Group>   
        ) : (
        <Box>
            <Skeleton width="100%" height={8} />
            <Skeleton width="80%" height={8} mt={6} />
            <Skeleton width="60%" height={8} mt={6} />
        </Box>
        ) }
    </Card>
    )
}

export default WidgetCreditStats;