import React, { useState, useMemo, useEffect } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Title, Grid, Group, Select, Button } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { useNotifications } from '@mantine/notifications';
import { TextAlignLeftIcon } from '@radix-ui/react-icons';

import DocumentAddNew from './addnew';
import DocumentsList from './list';

import Loader from '../../components/Loader';
import ItemNotFound from '../../components/ItemNotFound';

import { isObjectExists, isArrayExists } from '../../helpers/validation';

import { useFolder } from '../Folders/redux/hooks';
import { useScreenSize, useSortBy } from '../../redux/global/hooks';
import { useDocuments } from './redux/hooks';

import { FIREBASE_CLOUD_API } from '../../constants';

const Documents = ({
    authData,
    dispatch,
    documents,
}) => {
    const [ screenSize ] = useScreenSize();
    const { folder_id } = useParams();
    const [ sort_by, sort_by_folder = '', handleSort ] = useSortBy();
    const [ sort, setSort ] = useState('modified_on-desc');
    const [ reloading, setReloading ] = useState(false);
    const [ docsLoaded ] = useDocuments( folder_id );
    const [ folderLoaded, folder ] = useFolder( folder_id, sort );
    const clipboard = useClipboard({ timeout: 500 });
    const notifications = useNotifications();

    useEffect(() => {
        if ( sort_by ) {
            setSort( sort_by );
        } else {
            setSort('modified_on-desc');
        }
    } , [ sort_by, sort_by_folder, folder_id ]);

    const loaded = useMemo(() => {
        return docsLoaded && folderLoaded ? true : false;
    }, [ docsLoaded, folderLoaded ] );

    const sortedDocuments = useMemo(() => {
        let list = documents ? [ ...documents ] : [];
        // sort list by modified_on
        if ( isArrayExists( list ) ) {
            switch( sort ) {
                case 'modified_on-desc':
                case 'modified_on-asc':
                    list = list.sort((a, b) => {
                        return ( a.modified_on && a.modified_on._seconds ? a.modified_on._seconds : 0 ) - ( b.modified_on && b.modified_on._seconds ? b.modified_on._seconds : 0 );
                    })
                    // reverse the list
                    if ( sort === 'modified_on-desc' ) {
                        list = list.reverse();
                    }
                    break;
                case 'name-asc':
                    list = list.sort((a, b) => {
                        return ( a.name && b.name ? a.name.localeCompare(b.name) : 0 );
                    })
                    break;
                case 'name-desc':
                    list = list.sort((a, b) => {
                        return ( a.name && b.name ? b.name.localeCompare(a.name) : 0 );
                    })
                    break;
            }
        } // end - list
        return list;
    }, [ documents, sort ]);

    return loaded && !reloading ? ( folder && isObjectExists( folder ) ? (
    <>
        <Grid justify={"center"}>
            <Grid.Col sm={12} lg={6}>
                <Title order={2} align={ screenSize === 'sm' || screenSize === 'xs' ? 'center' : 'left' }>{( folder && folder.name || '' )}</Title>
            </Grid.Col>
            <Grid.Col sm={12} lg={6}>
                <Group position={ screenSize === 'sm' || screenSize === 'xs' ? 'center' : 'right' }>
                    {/* <DocumentAddNew
                        folder={folder} /> */}
                    <Button
                        size="sm"
                        compact
                        color="dark"
                        variant="filled"
                        leftIcon={<TextAlignLeftIcon />}
                        onClick={() => {
                            clipboard.copy( FIREBASE_CLOUD_API + "webView?action=read_folder_notes&folder_id=" + folder.id );
                            notifications.showNotification({
                                title: 'Link copied to clipboard',
                            });
                        }}
                        >
                        Folder Notes
                    </Button>
                    <Button
                        size="sm"
                        compact
                        color="dark"
                        variant="filled"
                        leftIcon={<TextAlignLeftIcon />}
                        onClick={() => {
                            clipboard.copy( FIREBASE_CLOUD_API + "webView?action=read_folder_summary&folder_id=" + folder.id );
                            notifications.showNotification({
                                title: 'Link copied to clipboard',
                            });
                        }}
                        >
                        Folder Summary
                    </Button>
                    <Select
                        data={[
                            { value: 'modified_on-desc', label: 'Last Modified First' },
                            { value: 'modified_on-asc', label: 'Oldest First' },
                            { value: 'name-asc', label: 'Name (A-Z)' },
                            { value: 'name-desc', label: 'Name (Z-A)' },
                        ]}
                        value={sort}
                        onChange={(value) => {    
                            handleSort( value, folder_id );
                        }}
                        />
                </Group>
            </Grid.Col>
        </Grid>

        <DocumentsList
            folder={folder}
            documents={sortedDocuments} />

    </>
    ) : <ItemNotFound 
            label="Sorry. This folder is either doesn't exist, or you don't have access to it."
            goBack="/folders" /> ) : <Loader height='70vh' /> ;
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        documents: ( state.documents && state.documents.documents ) ? state.documents.documents : null,
    };
};

export default compose(
    connect(mapStateToProps)
)(Documents);