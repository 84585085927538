import React, { useState, useMemo, useEffect, useRef } from 'react';
import { compose } from "recompose";
import { connect, useDispatch } from "react-redux";
// import { useParams } from "react-router-dom";
import { useMantineTheme, Box, Text, Button, LoadingOverlay, Card, Skeleton, Group, Space, Badge, Loader, Tooltip, Avatar, Title } from '@mantine/core';
import { useDidUpdate, useClipboard } from '@mantine/hooks';
import { ReloadIcon, LightningBoltIcon, CopyIcon, CheckIcon, PlusCircledIcon } from '@radix-ui/react-icons';
import { useNotifications } from '@mantine/notifications';

import { useInsightSelected, useInsightClicked, useSelectInsight } from './redux/hooks';

import UserOnboarding from '../UserOnboarding';

import LoaderComp from '../../components/Loader';
import ButtonIconCircle from '../../components/ButtonIconCircle'

import { getInsightKeypoints, getInsightContentBlocks } from './helpers';
import { isArrayExists } from '../../helpers/validation';
import { getFormattedCredits } from '../../helpers/number';
import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/api';
import { tiptapHTMLToItems, getCustomExtensionsFromDoc, tiptapWordsCount } from '../../helpers/tiptap';
import { useDelayToggle } from '../../helpers/hooks';

// import { toggleGlobalDisabled } from '../../redux/global/actions';

const Insight = ({
    title = '',
    viewport = null,
    document = {},
    insight = {},
    insightsData = {},
    contentItems = [],
    disabledRegeneration = false,
    onRegeneration = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    onClick = () => {},
    onAddToNotes = () => {},
}) => {
    const ref = useRef(null);
    const theme = useMantineTheme();
    // const dispatch = useDispatch();
    const notifications = useNotifications();
    const [ select_insight, doSelectInsight, resetSelectInsight ] = useSelectInsight();
    const [ blocks_selected, doInsightSelected, resetInsightSelected ] = useInsightSelected();
    const [ insight_clicked, doInsightClicked ] = useInsightClicked();
    const clipboard = useClipboard({ timeout: 1000 });
    const [ regenerating, setRegenerating ] = useState(false);
    const [ rating, setRating ] = useState(false);
    const [ rateRemoved, setRateRemoved ] = useState(false);
    const [ showRatingTooltip, triggerRatingTooltip ] = useDelayToggle();
    const [ showRemovedRatingTooltip, triggerRemovedRatingTooltip ] = useDelayToggle();
    const [ addedToNotes, triggerAddToNotes ] = useDelayToggle(1000);

    const selectedBlocks = useMemo(() => {
        return getInsightContentBlocks( contentItems, insight );
    },[ contentItems, insight ]);

    useDidUpdate(() => {
        if ( selectedBlocks && selectedBlocks.includes( select_insight ) ) {
            // get the distance of current element from the top of the viewport
            const distance = ref.current.offsetTop;
            viewport.current.scrollTo({
                top: distance-10,
                behavior: 'smooth',
            });
            setTimeout(() => {
                resetSelectInsight();
            },1000);
        }
    }, [ select_insight, selectedBlocks, viewport, ref ] );

    const processing = useMemo(() => {
        return ( 
            ( insightsData && insightsData.status && insightsData.status === 'process' && !( insight && insight.text && insight.text !== '' ) ) ||
            ( regenerating ) ? true : false )
    }, [ insightsData, insight, regenerating ]);

    const promptText = useMemo(() => {
        return insight.prompt.join(' ')
    }, [ insight ]);

    const handleRating = ( rate ) => (e) => {
        e.stopPropagation();
        setRating(rate);
        callFunctionsAPI({
            url: 'insights',
            action: 'rate',
            formData: {
                document_id: document.id,
                insight_id: insightsData.id,
                log_id: insight.log_id || false,
                rate
            }
        })
        .then(() => {
            setRating(false);
            triggerRatingTooltip();
        })
        .catch(err => {
            setRating(false);
            notifications.showNotification({
                color: 'red',
                title: getAPIErrorMessage(err),
            })
        });
    }

    const handleRatingRemoved = (e) => {
        e.stopPropagation();
        setRateRemoved(true);
        callFunctionsAPI({
            url: 'insights',
            action: 'rate',
            formData: {
                document_id: document.id,
                insight_id: insightsData.id,
                log_id: insight.log_id || false,
                rate: ''
            }
        })
        .then(() => {
            setRateRemoved(false);
            triggerRemovedRatingTooltip();
        })
        .catch(err => {
            setRateRemoved(false);
            notifications.showNotification({
                color: 'red',
                title: getAPIErrorMessage(err),
            })
        });
    }

    const handleRegenerate = (e) => {
        e.stopPropagation();
        setRegenerating(true);
        onRegeneration(true);
        callFunctionsAPI({
            url: 'insights',
            action: 'regenerate',
            formData: {
                document_id: document.id,
                insight_id: insightsData.id,
                log_id: insight.log_id || false
            }
        })
        .then(() => {
            setRegenerating(false);
            onRegeneration(false);
            notifications.showNotification({
                color: 'teal',
                icon: <CheckIcon />,
                title: `Insight${( insight && insight.id ? ' #'+insight.id : '' )} Regenerated`,
            })
        })
        .catch(err => {
            setRegenerating(false);
            onRegeneration(false);
            notifications.showNotification({
                color: 'red',
                title: getAPIErrorMessage(err),
            })
        });
    }

    const handleInsightSelected = () => {
        doInsightSelected(selectedBlocks);
    }

    const handleInsightClicked = () => {
        doInsightClicked(selectedBlocks && selectedBlocks[0] ? selectedBlocks[0] : null);
    }

    const renderInsights = (text) => {
        let list = [],
            multiArray = ( text ? text.split('\n') : [] );
        multiArray.forEach((string,index) => {
            list.push({
                id: (index+1),
                // text: (string.replace(/[[0-9]+\.\s|\n|\t|\-\s|\*\s|\#\s]/g, '')).trim(),
                text: (string).trim(),
            });
        });
        return ( list && isArrayExists( list ) ? list.map(item => (
            <Text size="sm" key={item.id}>{item.text || ''}</Text>
        )) : null )
    }

    return (
    <>
        { title && title !== '' && (
        <>
            <Title order={6} pl="sm" pr="sm" pb="xs" pt="sm">{title}</Title>
        </>
        ) }
        <Card
            ref={ref}
            id={"insight-"+insight.id}
            p="sm"
            withBorder
            radius={"sm"}
            sx={(theme) => ({
                // padding: theme.spacing.md,
                border: '1px solid ' + ( selectedBlocks && selectedBlocks.includes( select_insight ) ? theme.colors.indigo[5] : ( insight && insight.unsafe_level && insight.unsafe_level === '2' ? theme.colors.orange[5] : theme.colors.gray[2] ) ),
                background: ( insight && insight.unsafe_level && insight.unsafe_level === '2' ? theme.colors.orange[1] : null ),
                color: ( insight && insight.unsafe_level && insight.unsafe_level === '2' ? theme.colors.orange[9] : null ),
                borderWidth: '2px',
                cursor: 'pointer',
                position: 'relative',
                overflow: 'hidden',
                '&:hover': {
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                    borderColor: theme.colors.indigo[5],
                },
            })}
            onMouseEnter={handleInsightSelected}
            onMouseLeave={() => resetInsightSelected()}
            onClick={handleInsightClicked}>
            <Badge 
                size='xs'
                color={"indigo"}
                radius={'sm'}
                sx={(theme) => ({
                    position: 'absolute',
                    top: 1,
                    right: 0,
                    zIndex: 10,
                    borderTopRightRadius: '0px'
                })}>
                {insight.id}
            </Badge>  
            { processing ? (
            <Box>
                <Skeleton width="100%" height={8} />
                <Skeleton width="80%" height={8} mt={6} />
                <Skeleton width="60%" height={8} mt={6} />
            </Box>
            ) : (
            <> 
                {renderInsights(insight.text)}
                <Space h="sm" />
                <Group position='apart'>
                    <Group position='left' spacing={"xs"}>
                        <ButtonIconCircle
                            icon={<PlusCircledIcon />}
                            label={ addedToNotes ? (
                            <Group spacing={"0px"}>
                                <span style={{ width: '25px' }}><CheckIcon size={16} color="lime" /></span>
                                <span style={{ width: '95px', lineHeight: 1.1 }}>Insight Added To Notes</span>
                            </Group>
                            ) : <div style={{ textAlign: 'center' }}>Add To Notes</div> }
                            useClipboard
                            closeDelay={ addedToNotes ? 500 : 0 }
                            tooltipProps={{
                                id: `insight-${insight.id}-addnotes`,
                                wrapLines: true,
                                width: 120
                            }}
                            color={addedToNotes ? theme.colors.indigo[9] : theme.colors.indigo[5] }
                            btnProps={{
                                variant: "light",
                                color: 'gray',
                                radius: 'md',
                                size: 'xs',
                                disabled: addedToNotes ? true : false
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onAddToNotes(insight);
                                triggerAddToNotes();
                                // notifications.showNotification({
                                //     color: 'teal',
                                //     icon: <CheckIcon />,
                                //     title: 'Insight added to Notes',
                                // })
                            }} />
                        <ButtonIconCircle
                            icon={<CopyIcon />}
                            label={ clipboard.copied ? "Insight Copied" : "Copy Insight" }
                            useClipboard
                            color={clipboard.copied ? theme.colors.indigo[9] : theme.colors.indigo[5] }
                            tooltipProps={{
                                id: `insight-${insight.id}-copy`,
                            }}
                            btnProps={{
                                variant: "light",
                                color: 'gray',
                                radius: 'md',
                                size: 'xs'
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                clipboard.copy(insight.text);
                            }} />
                    </Group>
                    <Group position='right' spacing={"xs"}>
                        { insight && insight.rate && ( insight.rate === 'good' || insight.rate === 'bad' ) ? (
                        <Tooltip
                            id={`insight-${insight.id}-rate`}
                            opened={showRatingTooltip}
                            label={<><PlusCircledIcon style={{ marginRight: '5px', verticalAlign: 'middle' }} />Rating added</>}
                            position="right"
                            transition="scale-x"
                            // color="indigo"
                            withArrow>
                            <Badge 
                                color={ insight.rate === 'good' ? "green" : "red" }
                                onClick={handleRatingRemoved}>
                                { rateRemoved ? <Loader size={12} color={ insight.rate === 'good' ? "green" : "red" } /> : <i className={`ri-thumb-${insight.rate === 'good' ? "up" : "down"}-line`}></i> }
                            </Badge>
                        </Tooltip>
                        ) : (
                        <Tooltip
                            id={`insight-${insight.id}-rate`}
                            opened={showRemovedRatingTooltip}
                            label={'Rating removed'}
                            transition="scale-x"
                            position="right"
                            // color="indigo"
                            withArrow>
                            <ButtonIconCircle
                                icon={ rating && rating === 'good' ? null : <i className="ri-thumb-up-line"></i>}
                                label="Rate this as good insight"
                                color={theme.colors.green[5]}
                                btnProps={{
                                    variant: "light",
                                    color: 'gray',
                                    radius: 'md',
                                    size: 'xs',
                                    disabled: rating && rating === 'bad' ? true : false,
                                    loading: rating && rating === 'good' ? true : false
                                }}
                                onClick={handleRating('good')} />
                            <ButtonIconCircle
                                icon={rating && rating === 'bad' ? null : <i className="ri-thumb-down-line"></i>}
                                label="Rate this as bad insight"
                                color={theme.colors.red[5]}
                                btnProps={{
                                    variant: "light",
                                    color: 'gray',
                                    radius: 'md',
                                    size: 'xs',
                                    disabled: rating && rating === 'good' ? true : false,
                                    loading: rating && rating === 'bad' ? true : false
                                }}
                                onClick={handleRating('bad')} />
                        </Tooltip>
                        ) }
                        <ButtonIconCircle
                            icon={<ReloadIcon />}
                            label={(
                            <>
                                <div>Re-generate Insight</div>
                                <em style={{ fontSize: '0.75em', color: '#ddd' }}>Total tokens needed: {getFormattedCredits(
                                    promptText.split(" ").length
                                )}</em>
                            </>
                            )}
                            color={theme.colors.indigo[5]}
                            tooltipProps={{
                                id: `insight-${insight.id}-regenerate`
                            }}
                            btnProps={{
                                variant: "light",
                                color: 'gray',
                                radius: 'md',
                                size: 'xs',
                                disabled: disabledRegeneration ? true : false
                            }}
                            onClick={handleRegenerate} />
                    </Group>
                </Group>
            </>
            ) }
        </Card>
    
    </>
    )
}

const Insights = ({
    editorRef = null,
    viewport = null,
    authData,
    navbarStatus,
    dispatch,
    document,
    insightsList = [],
    InsightsRand = null,
    onUpdate = () => {}
}) => {
    const notifications = useNotifications();
    const [ loaded, setLoaded ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ disabledRegeneration, setDisabledRegeneration ] = useState(false);

    useEffect(() => {
        if ( InsightsRand ) {
            setLoaded(true);
        }
    }, [ InsightsRand ] );

    const insightsData = useMemo(() => {
        let data = {};
        if ( insightsList && isArrayExists( insightsList ) ) {
            insightsList.forEach( insight => {
                data = { ...insight };
            }); 
        } // end - insightsList
        return data;
    }, [ insightsList ] );

    const processing = useMemo(() => {
        return insightsData && insightsData.status && insightsData.status === 'process' ? true : false;
    },[ insightsData ]);

    const contentItems = useMemo(() => {
        return tiptapHTMLToItems( document.content || '', getCustomExtensionsFromDoc( document ) );
    }, [ document ]);

    const insights = useMemo(() => {
        // console.log( 'insights', getInsightKeypoints(insightsList) );
        return getInsightKeypoints(insightsList, contentItems);
    }, [ insightsList, contentItems ] );

    const handleGenerate = () => {
        setLoading(true);
        callFunctionsAPI({
            url: 'insights',
            action: 'generate',
            formData: { id: document.id }
        })
        .then(results => {
            setLoading(false);
            notifications.showNotification({
                color: 'teal',
                title: 'Insights gathering process started',
            })
        })
        .catch(error => {
            setLoading(false);
            notifications.showNotification({
                color: 'red',
                title: getAPIErrorMessage(error),
            })
        });
    }

    const handleAddToNotes = (insight) => {
        const newNotes = ( document && document.notes || '' );
        onUpdate({ ...document, notes: newNotes + '\n\nInsight #' + insight.id + ':\n' + insight.text });
    }

    return loaded ? (
    <Box
        sx={(theme) => ({
        position: 'relative',
        paddingTop: '0px'
    })}>
        <LoadingOverlay 
            visible={loading}
            sx={() => ({
                alignItems: 'flex-start',
                paddingTop: '60px'
            })} />

        <Box
            id="document-generate-insights"
            sx={(theme) => ({
                paddingTop: '20px'
            })}>
            <Button 
                fullWidth
                color="indigo"
                size="md"
                radius="xl"
                leftIcon={ insights && isArrayExists( insights ) ? <ReloadIcon /> : <LightningBoltIcon />}
                disabled={ processing ? true : false }
                loading={processing}
                onClick={handleGenerate}>
                { processing ? 'Processing' : insights && isArrayExists( insights ) ? "Re-generate All" : "Generate" }
            </Button>

            <Text mt="xs" size="xs" color="dimmed" align='center'>Total tokens needed: { getFormattedCredits(
                tiptapWordsCount( document.content || '', getCustomExtensionsFromDoc( document ) )
            ) }</Text>
        </Box>

        { insights && isArrayExists( insights ) ? (
        <Box sx={(theme) => ({
            marginTop: '20px',
            '& > .mantine-Card-root': {
                marginBottom: '10px',
                '& .mantine-Text-root': {
                    marginBottom: '5px',
                    '&:last-child': {
                        marginBottom: '0px'
                    }
                },
                '&:last-child': {
                    marginBottom: '0px'
                }
            }
        })}>
            {insights.map(insight => {
                return (
                <Insight 
                    key={insight.id}
                    title={insight.title || ''}
                    viewport={viewport}
                    insight={insight}
                    document={document}
                    insightsData={insightsData}
                    contentItems={contentItems}
                    disabledRegeneration={disabledRegeneration}
                    onRegeneration={(val) => setDisabledRegeneration(val)}
                    // selectedBlocks={getSelectedBlocks(insight)}
                    // onMouseEnter={handleInsightSelected(insight)}
                    // onMouseLeave={() => resetInsightSelected()}
                    // onClick={handleInsightClicked(insight)}
                    onAddToNotes={handleAddToNotes}
                    />
                );
            })}
        </Box>
        ) : <Text size="md" align='center' p="md">No insights generated yet.</Text> }

        <UserOnboarding show={['document']} />

        { insights && isArrayExists( insights ) && insights[0].id && insights[0].id === '1' && insights[0].text && insights[0].text !== '' && (
            <UserOnboarding show={['insights']} />
        ) }

    </Box>
    ) : <LoaderComp />;
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        navbarStatus: ( state.global && state.global.navbar ) ? state.global.navbar : false,
        insightsList: ( state.insights && state.insights.insights ) ? state.insights.insights : [],
        InsightsRand: ( state.insights && state.insights.rand ) ? state.insights.rand : null,
    };
};

export default compose(
    connect(mapStateToProps)
)(Insights);