
export const GET_INSIGHTS = 'get_insights';
export const RESET_INSIGHTS = 'reset_insights';
// select insight through content
export const SELECT_INSIGHT = 'select_insight';
export const RESET_SELECT_INSIGHT = 'reset_select_insight';
// when insight is selected
export const INSIGHT_SELECTED = 'insight_selected';
export const RESET_INSIGHT_SELECTED = 'reset_insight_selected';
// when insight is clicked
export const INSIGHT_CLICKED = 'insight_clicked';
export const RESET_INSIGHT_CLICKED = 'reset_insight_clicked';