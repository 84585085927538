import React from 'react';
import { connect } from "react-redux";
import { Box, Grid, Text, Group, Button } from '@mantine/core';

import { useScreenSize } from '../../redux/global/hooks';

const getThisYear = () => {
    const date = new Date();
    return date.getFullYear();
}

const footerLinks = [
    {
        id: 'terms',
        label: 'Terms',
        link: 'https://www.sagelyai.com/terms/',
    },
    {
        id: 'privacy',
        label: 'Privacy Policy',
        link: 'https://www.sagelyai.com/privacy-policy/',
    },
];

const Footer = ({
    authData
}) => {
    const [ screenSize ] = useScreenSize();
    return (
    <Box sx={(theme) => ({
        paddingTop: theme.spacing.lg,
        color: theme.colors.gray[5],
        fontSize: theme.fontSizes.sm,
    })}>
        <Grid>
            <Grid.Col sm={12} lg={4}>
                <Group position={ screenSize === 'sm' || screenSize === 'xs' ? 'center' : 'left' }>
                    {footerLinks.map(item => (
                        <Button
                            key={item.id}
                            href={item.link}
                            p="xs"
                            variant="subtile"
                            formTarget='_blank'
                            sx={(theme) => ({
                                border: 'none',
                                backgroundColor: 'transparent',
                                color: theme.colors.gray[5],
                                fontSize: theme.fontSizes.sm,
                                '&:hover': {
                                    color: theme.colors.gray[6],
                                },
                            })}
                        >
                            {item.label}
                        </Button>
                    ))}
                </Group>
            </Grid.Col>
            <Grid.Col sm={12} lg={8}>
                <Text 
                    align={ screenSize === 'sm' || screenSize === 'xs' ? 'center' : 'right' }
                    sx={(theme) => ({
                        fontSize: theme.fontSizes.sm,
                    })}>
                    Copyright © {getThisYear()} Smashing Advantage. All Rights Reserved
                </Text>
            </Grid.Col>
        </Grid>
    </Box>
    )
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
    };
};

export default connect(mapStateToProps)(Footer);