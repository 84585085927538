import { useState } from 'react';
// import { useDebouncedValue, useDidUpdate } from '@mantine/hooks';

export const useDelayToggle = (delay = 1500) => {
    // const [ on, setOn ] = useState(false);
    // const [ off, setOff ] = useState(false);
    const [ status, setStatus ] = useState(false);
    // const [ debouncedVal ] = useDebouncedValue(off, 1500);

    // useEffect(() => {
    //     if ( on && !debouncedVal ) {
    //         setStatus(true);
    //     } else if ( debouncedVal ) {
    //         setStatus(false);
    //         setOff(false);
    //         setOn(false);
    //     }
    // },[ debouncedVal, on ]);

    // useDidUpdate(() => {
    //     if ( on ) {
    //         setStatus(true);
    //     }
    // },[ on ]);

    // useDidUpdate(() => {
    //     if ( debouncedVal ) {
    //         setStatus(false);
    //         setOff(false);
    //         setOn(false);
    //         console.log('delay toggle reset');
    //     }
    // },[ debouncedVal ]);

    const onToggle = () => {
        setStatus(true);
        setTimeout(() => {
            setStatus(false);
        },delay);
    }

    return [ status, onToggle ];
}