
import { 
    MODAL_ACTIVE,
    MODAL_ACTIVE_ADD,
    MODAL_ACTIVE_REMOVE,
    MODAL_ACTIVE_RESET
} from './types';

import { isArrayExists } from '../../helpers/validation';

const initialState = {
    active: null
};

export const modals = (state = initialState, action) => {
    const { type, payload = null } = action;
    switch (type) {
        case MODAL_ACTIVE:
            return {
                ...state,
                active: payload.active && isArrayExists( payload.active ) ? payload.active : null
            };
        case MODAL_ACTIVE_ADD:
            return {
                ...state,
                active: ( state.active && isArrayExists( state.active ) ? ( state.active.includes( payload.id ) ? state.active : [ ...state.active, payload.id ] ) : [ payload.id ] )
            };
        case MODAL_ACTIVE_REMOVE:
            return {
                ...state,
                active: ( state.active && isArrayExists( state.active ) ? ( state.active.includes( payload.id ) ? state.active.filter((i) => i !== payload.id) : state.active ) : null )
            };
        case MODAL_ACTIVE_RESET:
            return {
                ...state,
                active: null
            };
        default:
            return state;
    }
}