

// replace all keywords on a given string
export const replaceAll = function( string, search, replacement ) {
	return string.replace(new RegExp(search, 'g'), replacement);
};

// get selected value
export const getSelectedValue = (options,selected,key,returnKey,defaultVal) => {
    let selectedVal = ( options && options.length > 0 && selected && selected.length > 0 && key && key.length > 0 ? options.find(o => selected === o[key]) : false );
    return ( selectedVal && returnKey && returnKey.length > 0 && selectedVal[returnKey] ? selectedVal[returnKey] : ( defaultVal || null ) ); 
}

// hex to rgb
export const hexToRgb = (hex) =>{
    let c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgb('+[(c>>16)&255, (c>>8)&255, c&255].join(', ')+')';
    }
    return '';
}