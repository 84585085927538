import { combineReducers } from "redux";
import { auth } from './redux/auth/reducers';
// import { teams } from './pages/Teams/redux/reducers';
import { folders } from './pages/Folders/redux/reducers';
import { documents } from './pages/Documents/redux/reducers';
import { credits } from './pages/Credits/redux/reducers';

import { onboarding } from './modules/UserOnboarding/redux/reducers';
import { insights, select_insight, insight_selected, insight_clicked } from './modules/Insights/redux/reducers';
import { executive_summary } from "./modules/ExecutiveSummary/redux/reducers";

import { modals } from './redux/modals/reducers';
import { global } from "./redux/global/reducers";

export default combineReducers({
    auth,
    folders,
    documents,
    insights,
    select_insight, 
    insight_selected, 
    insight_clicked,
    executive_summary,
    credits,
    onboarding,
    modals,    
    global
});