import React, { useState, useEffect, useMemo, forwardRef, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useMantineTheme, Button, Box, InputWrapper, Input, Modal, Group, LoadingOverlay, Select, Text, Space, Grid, SegmentedControl, Center } from '@mantine/core';
import { PlusCircledIcon, Pencil2Icon, Link2Icon, UploadIcon, VideoIcon } from '@radix-ui/react-icons';

import AddNewEditor from './addnew_editor';
import AddNewUploadEpub from './addnew_upload_epub';
import AddNewWebLink from './addnew_weblink';
import AddNewWebLinkYouTube from './addnew_weblink_youtube';

import { isArrayExists } from '../../helpers/validation';
import { isDev } from '../../helpers/auth';
import { useScreenSize } from '../../redux/global/hooks';
// import { tiptapWordsCount } from '../../helpers/tiptap';

import { DEV_MODE } from '../../constants';

const FormModal = ({
    opened = false,
    title = 'Add New Document',
    actionLabel = 'Add New',
    predefinedFormData = false,
    formData = {},
    folders = [],
    loading = false,
    mode = 'button',
    onAddNewFolder = () => {},
    onUpdate = () => {},
    onClose = () => {},
    doAction = () => {}
}) => {
    const editorRef = useRef(null);
    const theme = useMantineTheme();
    const [ screenSize ] = useScreenSize();
    const authData = useSelector(state => state.auth.user);
    const globalDisabled = useSelector(state => state.global.disabled);

    const disabled = useMemo(() => {
        return loading || globalDisabled ? true : false;
    },[loading]);

    const folderOptions = useMemo(() => {
        return folders && isArrayExists( folders ) ? folders.map(item => {
            return {
                value: item.id,
                label: item.name,
            };
        }) : [];
    }, [ folders ]);

    const isNavBarMode = useMemo(() => {
        return ( mode && mode === 'navbar' ? true : false );
    },[ mode ]);

    const disableAction = useMemo(() => {
        let disable = false;
        if ( !formData.content_source || ( formData.content_source &&  formData.content_source === 'editor' ) ) {
            // check if name is empty
            if ( !( formData.content && formData.content !== '<p><br /></p>' && formData.content !== '<p></p>' ) ) {
                disable = true;
            }
        }
        if (  formData.content_source &&  formData.content_source === 'epub' ) {
            // check if name is empty
            if ( !( formData.epub_id && formData.epub_id !== '' ) ) {
                disable = true;
            }
        }

        if (  formData.content_source &&  formData.content_source === 'web_link' ) {
            if ( !( formData.content && formData.content !== '<p><br /></p>' && formData.content !== '<p></p>' ) ) {
                disable = true;
            }

            if ( !( formData.web_link && formData.web_link !== '' ) ) {
                disable = true;
            }
        }
        return disable
    },[ formData ]);

    const handleFormUpdate = (event) => {
        const { name, value } = event.target;
        onUpdate({
            ...formData,
            [name]: value
        });
    }

    return (
    <Modal
        opened={opened}
        title={title}
        size={ screenSize === 'lg' || screenSize === 'xl' ? '80%' : '100%' }
        centered
        overlayColor={theme.colors.gray[2]}
        overlayOpacity={0.8}
        overlayBlur={3}
        zIndex={700}
        shadow={"xl"}
        closeOnClickOutside={false}
        onClose={() => onClose()}
        >
        <>
            <LoadingOverlay
                visible={loading} />
            <Box sx={(theme) => ({
                paddingTop: theme.spacing.xs,
                paddingBottom: theme.spacing.lg,
                borderTop: `1px solid ${theme.colors.gray[1]}`,
                // borderBottom: `1px solid ${theme.colors.gray[1]}`,
            })}>
                <Grid gutter={40}>
                    <Grid.Col sm={12} md={6}>
                        <Box id="addnewdoc-name">
                            <InputWrapper
                                required
                                label="Name"
                                // description="Please enter your credit card information, we need some money"
                                size="sm"
                                >
                                <Input disabled={disabled} placeholder="Ex: How to become a superhero" value={( formData.name || '' )} name="name" onChange={handleFormUpdate} />
                            </InputWrapper>
                        </Box>
                        <Space h="sm" />
                        <Box id="addnewdoc-folder">
                            <Select
                                label="Folder"
                                required
                                disabled={ disabled ? true : false }
                                placeholder="Pick one folder"
                                value={formData && formData.folder_id ? formData.folder_id : ''}
                                itemComponent={forwardRef(({ label, ...others }, ref) => (
                                <div ref={ref} {...others}>
                                    <Group noWrap>
                                        <div>
                                            <Text size="sm">{label}</Text>
                                        </div>
                                    </Group>
                                </div>
                                ))}
                                data={folderOptions}
                                searchable
                                zIndex={701}
                                clearable={ disabled ? false : true }
                                // creatable
                                // getCreateLabel={(query) => (
                                // <Group>
                                //     <PlusCircledIcon size={16} />
                                //     <Text color="indigo" size="sm">{`Create ${query}`}</Text>
                                // </Group>
                                // )}
                                // onCreate={handleAddNewFolder}
                                name="folder_id"
                                maxDropdownHeight={400}
                                nothingFound="No folder found."
                                filter={(value, item) =>
                                    ( item && item.label && item.label.toLowerCase().includes(value.toLowerCase().trim()) ) || null
                                }
                                onChange={(newValue) => {
                                    onUpdate({ ...formData, folder_id: newValue });
                                }}
                                />
                        </Box>
                        <Space h="xs" />
                        <Group position="right">
                            <Button
                                id="addnewdoc-folder-add"
                                variant="subtle"
                                size="xs"
                                radius="sm"
                                compact
                                disabled={ disabled ? true : false }
                                leftIcon={<PlusCircledIcon />}
                                onClick={onAddNewFolder}
                                >
                                Add New Folder
                            </Button>
                        </Group>
                    </Grid.Col>
                    <Grid.Col sm={12} md={6}>
                        <SegmentedControl
                            value={( formData.content_source || 'editor' )}
                            onChange={(newValue) => {
                                onUpdate({ ...formData, content_source: newValue });
                            }}
                            data={[
                                { label: (
                                    <Center>
                                        <Pencil2Icon size={16} />
                                        <Box ml={10}>Editor</Box>
                                    </Center>
                                    ), value: 'editor' },
                                { label: (
                                    <Center>
                                        <Link2Icon size={16} />
                                        <Box ml={10}>Web Link</Box>
                                    </Center>
                                    ), value: 'web_link' },
                                { label: (
                                    <Center>
                                        <VideoIcon size={16} />
                                        <Box ml={10}>YouTube</Box>
                                    </Center>
                                    ), value: 'web_link_youtube' },
                                // { label: (
                                //     <Center>
                                //         <UploadIcon size={16} />
                                //         <Box ml={10}>Upload EPUB</Box>
                                //     </Center>
                                //     ), value: 'epub' },
                            ]}
                            />
                        <Space h="md" />
                        <Box
                            id="addnewdoc-content"
                            sx={(theme) => ({ 
                                display: !formData.content_source || ( formData.content_source && formData.content_source === 'editor' ) ? 'block' : 'none',
                                paddingLeft: theme.spacing.sm 
                            })}>
                            <AddNewEditor
                                editorRef={editorRef}
                                opened={opened}
                                predefinedFormData={predefinedFormData}
                                formData={formData}
                                loading={loading}
                                onUpdate={(newValue) => onUpdate(newValue)}
                                />
                        </Box>
                        <Box
                            sx={(theme) => ({ 
                                display: ( formData.content_source && formData.content_source === 'epub' ) ? 'block' : 'none',
                                minHeight: 'calc( 50vh + 70px )'
                                // paddingLeft: theme.spacing.sm 
                            })}>
                            { DEV_MODE ? <AddNewUploadEpub
                                formData={formData}
                                loading={loading}
                                onUpdate={(newValue) => onUpdate(newValue)}
                                /> : (
                                <Box
                                    sx={(theme) =>({ padding: theme.spacing.lg, background: theme.colors.yellow[0] })}>
                                    <Text size='lg' color={"dark"} weight={700}>Coming soon...</Text>
                                    <Text size='md' color={"dark"}>This feature is currently under active development.</Text>
                                </Box>
                                ) }
                        </Box>
                        <Box
                            sx={(theme) => ({ 
                                display: ( formData.content_source && formData.content_source === 'web_link' ) ? 'block' : 'none',
                                paddingLeft: theme.spacing.sm,
                                minHeight: 'calc( 50vh + 70px )'
                            })}>
                            <AddNewWebLink
                                editorRef={editorRef}
                                opened={opened}
                                formData={formData}
                                loading={loading}
                                onUpdate={(newValue) => onUpdate(newValue)}
                                />
                        </Box>
                        <Box
                            sx={(theme) => ({ 
                                display: ( formData.content_source && formData.content_source === 'web_link_youtube' ) ? 'block' : 'none',
                                paddingLeft: theme.spacing.sm,
                                minHeight: 'calc( 50vh + 70px )'
                            })}>
                            <AddNewWebLinkYouTube
                                editorRef={editorRef}
                                opened={opened}
                                formData={formData}
                                loading={loading}
                                onUpdate={(newValue) => onUpdate(newValue)}
                                />
                        </Box>
                    </Grid.Col>
                </Grid>
            </Box>
            <Group position='right'>
                <Button
                    size='sm'
                    color="dark"
                    variant='outline'
                    disabled={disabled}
                    onClick={() => onClose()}
                    >
                    Cancel
                </Button>
                <Button
                    size='sm'
                    color="indigo"
                    onClick={() => doAction(formData)}
                    disabled={disabled || disableAction ? true : false}
                    >
                    {actionLabel}
                </Button>
            </Group>
        </>
    </Modal>
    )
}

export default FormModal;