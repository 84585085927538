
import { 
    GET_FOLDERS,
    GET_FOLDER,
    DELETING_FOLDER,
    RESET_FOLDERS,
    RESET_FOLDER,
} from './types';

const initialState = {
    folders: null,
    folder: null,
    updated: false,
    rand_single: false,
    rand: false
};

export const folders = (state = initialState, action) => {
    const { type, payload = null } = action;
    switch (type) {
        case GET_FOLDERS:
            return {
                ...state,
                folders: payload && payload.list ? payload.list : [],
                rand: Math.floor(Math.random() * 1000000)
            };
        case GET_FOLDER:
            return {
                ...state,
                folder: payload && payload.folder ? payload.folder : {},
                rand_single: Math.floor(Math.random() * 1000000)
            };
        case DELETING_FOLDER:
            return {
                ...state,
                deleting: payload && payload.id ? payload.id : null,
            };
        case RESET_FOLDERS:
            return {
                ...state,
                folders: [],
                rand: false
            };
        case RESET_FOLDER:
            return {
                ...state,
                folder: {},
                rand_single: false
            };
        default:
            return state;
    }
}