
import { 
    MODAL_ACTIVE,
    MODAL_ACTIVE_ADD,
    MODAL_ACTIVE_REMOVE,
    MODAL_ACTIVE_RESET
} from './types';

export const addActiveModal = ( id ) => {
    return dispatch => {
        setTimeout(() => {
            dispatch({ 
                type: MODAL_ACTIVE_ADD,
                payload: {
                    id 
                }
            });
        },150);
    }
}

export const removeActiveModal = ( id ) => {
    return dispatch => {
        setTimeout(() => {
            dispatch({ 
                type: MODAL_ACTIVE_REMOVE,
                payload: {
                    id 
                }
            });
        },150);
    }
}

export const setActiveModal = ( active = [] ) => {
    return dispatch => {
        setTimeout(() => {
            dispatch({ 
                type: MODAL_ACTIVE,
                payload: {
                    active 
                }
            });
        },150);
    }
}

export const resetActiveModal = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch({ type: MODAL_ACTIVE_RESET });
        },150);
    }
}