
import { getAuth, onAuthStateChanged } from "firebase/auth";

import {
	IS_LOGGED_IN,
	// AUTH_LOGIN,
	AUTH_LOGOUT,
    AUTH_GET_PROFILE_IMAGE,
	// AUTH_GET_PROFILE,
	// AUTH_EDIT_PROFILE,
} from './types';

import { onAuth } from "./api";

import { getAPIErrorMessage } from "../../helpers/api";

export const isUserLoggedIn = () => {
	return dispatch => {

		const auth = getAuth();
        onAuthStateChanged(auth,(userObj) => {
            if (userObj) {
				onAuth(userObj)
				.then(data => {
					dispatch({
						type: IS_LOGGED_IN,
						payload: data
					});
                    dispatch({
                        type: AUTH_GET_PROFILE_IMAGE,
                        payload: userObj && userObj.photoURL || null
                    });
					// setTimeout(() => {
					// 	dispatch({
					// 		type: IS_LOGGED_IN,
					// 		payload: data
					// 	});
					// 	dispatch({
					// 		type: AUTH_GET_PROFILE_IMAGE,
					// 		payload: userObj && userObj.photoURL || null
					// 	});
					// }, 300000);
				})
				.catch(error => {
					dispatch({ type: AUTH_LOGOUT });
					alert( getAPIErrorMessage(error) );
				});
            } else {
                dispatch({ type: AUTH_LOGOUT });
            }
        }); 

    }
};