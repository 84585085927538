import {
    GET_EXECUTIVE_SUMMARY,
    RESET_EXECUTIVE_SUMMARY
} from './types';

const initialState = {
    executive_summary: null,
    updated: false,
    rand: false
};

export const executive_summary = ( state = initialState, action ) => {
    const { type, payload = null } = action;
    switch (type) {
        case GET_EXECUTIVE_SUMMARY:
            return {
                ...state,
                executive_summary: payload.data || {},
                rand: Math.floor(Math.random() * 1000000)
            };
        case RESET_EXECUTIVE_SUMMARY:
            return {
                ...state,
                executive_summary: null,
                rand: false
            };
        default:
            return state;
    }
}