import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Text, Group, Space, Divider, Badge, Select, Button, LoadingOverlay, Box } from '@mantine/core';
import { useNotifications } from '@mantine/notifications';
import { InfoCircledIcon, ExclamationTriangleIcon, CodeIcon, CheckIcon } from '@radix-ui/react-icons';

import { getAPIErrorMessage, callFunctionsAPI } from '../../../helpers/api';

const DocumentSummaryEmbedding = () => {
    const notifications = useNotifications();
    const { document = {} } = useSelector((state) => state.documents);
    const [ loading, setLoading ] = useState(false);
    const [ embedType, setEmbedType ] = useState('');

    useEffect(() => {
        setEmbedType( document && document.embed_type ? document.embed_type : '' );
    },[ document ]);

    const hasEmbedded = useMemo(() => {
        return document && document.nods_page_section_id && document.nods_page_section_id !== 9999999999 ? true : false;
    },[ document ]);

    const hasNotion = useMemo(() => {
        return document && document.notion_page_id && document.notion_page_id !== '' ? true : false;
    },[ document ])

    const handleEmbedding = () => {
        if ( embedType === '' ) {
            notifications.showNotification({
                title: 'Select an option',
                message: 'Please select an option to embed',
                color: 'red',
                icon: <ExclamationTriangleIcon />,
            });
        } else {
            setLoading(true);
            callFunctionsAPI({
                url: 'executiveSummary',
                action: 'embedding',
                formData: {
                    id: document.id,
                    embed_type: embedType
                }
            })
            .then(() => {
                setLoading(false);
                notifications.showNotification({
                    title: 'Embedding Successful',
                    message: 'Summary has been embedded successfully.',
                    color: 'teal',
                    icon: <CheckIcon />
                });
            })
            .catch((error) => {
                setLoading(false);
                notifications.showNotification({
                    title: 'Error',
                    message: getAPIErrorMessage(error),
                    color: 'red',
                    icon: <ExclamationTriangleIcon />
                });
            });
        }
    }

    const handlePushToNotion = () => {
        setLoading(true);
        callFunctionsAPI({
            url: 'executiveSummary',
            action: 'push_to_notion',
            formData: {
                id: document.id
            }
        })
        .then(() => {
            setLoading(false);
            notifications.showNotification({
                title: 'Push to Notion Successful',
                message: 'Summary has been push to notion successfully.',
                color: 'teal',
                icon: <CheckIcon />
            });
        })
        .catch((error) => {
            setLoading(false);
            notifications.showNotification({
                title: 'Error',
                message: getAPIErrorMessage(error),
                color: 'red',
                icon: <ExclamationTriangleIcon />
            });
        });
    }

    return (
    <Box>

        <LoadingOverlay visible={loading} />

        <Group
            position='apart'
            >
            <Text weight={700} size="sm">Save to Second Brain</Text>
            <Badge
                color={hasEmbedded ? ( hasNotion ? 'grape' : 'green' ) : 'gray'}
                variant={hasEmbedded ? 'filled' : 'outline'}
                >
                { hasEmbedded ? ( hasNotion ? "Embedded + Notion" : 'Embedded' ) : 'Not Embedded'}
            </Badge>
        </Group>
        <Space h="xs" />
        <Group
            position='apart'
            spacing={"5px"}>
            <Select
                value={embedType}
                data={[
                    { value: 'executive_summary', label: 'Executive Summary' },
                    { value: 'notes', label: 'Notes' },
                ]}
                placeholder='Select an option'
                onChange={(newValue) => {
                    setEmbedType( newValue );
                }} />
            <Button
                size="sm"
                compact
                color="dark"
                variant="filled"
                leftIcon={<CodeIcon />}
                onClick={handleEmbedding}
                >
                Embed
            </Button>
        </Group>

        { hasNotion || !hasEmbedded ? (
        <>
            <Space h="md" />
            <Divider />
            <Space h="xs" />
        </>
        ) : (
        <>

            <Space h="sm" />

            <Button
                size="sm"
                compact
                color="orange"
                variant="filled"
                leftIcon={<CodeIcon />}
                onClick={handlePushToNotion}
                >
                Push to Notion
            </Button>

            <Space h="md" />
            <Divider />
            <Space h="xs" />

        </>    
        ) }

    </Box>
    )
}

export default DocumentSummaryEmbedding;

