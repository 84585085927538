import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
// import { useNavigate } from "react-router-dom";
import { Container, Card, Skeleton, Title, Space, Box, Text, Group, Button, Tooltip } from '@mantine/core';
import { PlusCircledIcon } from '@radix-ui/react-icons';



const Credits = () => {
    // const authData = useSelector( state => state.auth.user );
    const credits = useSelector(state => state.credits.credits);
    const loaded = useSelector(state => state.credits.loaded);

    const formattedCredits = useMemo(() => {
        // format credits number
        return ((credits).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')).toString().replace('.00','');
    },[ credits ]);

    return (
    <Container size="xs">
        {/* <Title order={3}>Tokens</Title>
        <Space h="md" />
        <Divider /> */}
        <Space h="xl" />
        <Card
            p="md"
            withBorder
            shadow={"lg"}
            sx={(theme) => ({
                background: theme.colors.indigo[5]
            })}
            >
            <Title align='center' order={2} sx={(theme) => ({
                color: theme.colors.gray[0]
            })}>My Token Balance:</Title>
            {/* <Space h="md" /> */}
            {/* <Divider size={"sm"} />
            <Space h="xl" /> */}
            { loaded ? (
            <>
                <Group position='center'>
                    <i className="ri-copper-coin-line" style={{ color: '#fff', fontSize: '60px' }}></i>
                    <Text weight={700} sx={(theme) => ({
                        color: '#fff',
                        fontSize: '60px'
                    })}>{formattedCredits}</Text>
                </Group>
                <Space h="xl" />
                <Space h="xl" />
                <Space h="xl" />
                <Group position='center'>
                    <Tooltip label='Coming Soon - Not Available Yet'>
                        <Button 
                            size="lg" 
                            disabled
                            leftIcon={<PlusCircledIcon />}>TOP UP</Button>
                    </Tooltip>
                </Group>
            </>
            ) : (
            <Box>
                <Skeleton width="100%" height={8} />
                <Skeleton width="80%" height={8} mt={6} />
                <Skeleton width="60%" height={8} mt={6} />
            </Box>
            ) }
        </Card>
        <Space h="xl" />
        <Space h="xl" />
    </Container>
    )
}

export default Credits;